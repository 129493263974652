import { API } from '../../common/libs/api';
import { Cache } from '../../common/libs/cache';
import { Worker, WorkerWatchStatus } from '../../common/models/worker';
import { Environment } from '../environment/environment';

export class WorkerAPI extends API {
  private cache: Cache;

  constructor(env: Environment) {
    super(env, 'Worker');

    this.cache = new Cache('worker_cache', (worker: Worker) => worker.id);
  }

  /**
   * Get all the workers
   * @param props
   */
  public async getAll() {
    if (this.cache.isCacheActive() && this.cache.hasData()) return this.cache.get() as Worker[];

    try {
      const response = await this.get<Worker[]>('/workers', {});

      this.cache.clear();
      this.cache.push(response.message);
      this.cache.updateTime();

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  /**
   * Get a worker by id
   * @param props
   */
  public async getById(id: string) {
    if (this.cache.isCacheActive() && this.cache.exists(id)) return this.cache.getItem(id) as Worker;

    try {
      const response = await this.get<Worker>(`/worker?id=${id}`);

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  /**
   * Edits a worker by id
   * @param props
   */
  public async edit(id: string, watchName: string, watchEUI: string, hasWatch: WorkerWatchStatus) {
    try {
      const response = await this.put<Worker>(`/worker`, {
        id,
        watchName,
        watchEUI,
        hasWatch
      });

      this.cache.clear();

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  // Cleans zones cache
  public clearCache() {
    this.cache.clear();
  }
}
