import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { UserRoles } from '../../../common/models/user';
import { setLoading } from '../../../infra/store/reducers/loading';
import { UserService } from '../services/userService';

const UserForm = () => {
  const [role, setRole] = useState<UserRoles>(UserRoles.COMMON_USER);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [job, setJob] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const service = new UserService();

  const handleEditUserSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const editedUser: any = {
      name: name,
      role: role,
      email: email,
      job: job
    };

    dispatch(setLoading(true));
    service.addUser(editedUser).finally(() => {
      dispatch(setLoading(false));
      navigate('/administrador/usuarios');
    });
  };

  return (
    <Paper elevation={0} sx={{ mt: 3, p: 3, boxSizing: 'border-box' }}>
      <Typography variant="h6" component="p" sx={{ mb: 2 }}>
        <strong>Detalles del Nuevo Usuario</strong>
      </Typography>

      <Box component="form" noValidate>
        <TextField
          sx={{ my: 2 }}
          margin="normal"
          required
          fullWidth
          id="name"
          label="Nombre"
          name="name"
          autoComplete="name"
          autoFocus
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          sx={{ my: 2 }}
          margin="normal"
          required
          type="email"
          fullWidth
          id="email"
          label="Correo"
          name="email"
          autoComplete="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <TextField
          sx={{ my: 2 }}
          margin="normal"
          required
          fullWidth
          id="job"
          label="Cargo"
          name="job"
          autoComplete="job"
          onChange={(e) => setJob(e.target.value)}
          value={job}
        />
        <FormControl fullWidth sx={{ my: 2 }}>
          <InputLabel id="role">Tipo de Usuario</InputLabel>
          <Select
            labelId="role"
            id="role"
            fullWidth
            label="Rol de Usuario"
            color="primary"
            value={role}
            onChange={(e) => setRole(e.target.value as UserRoles)}
          >
            <MenuItem value={'ADMIN'}>
              <Chip label="Administrador" color="primary" />
            </MenuItem>
            <MenuItem value={'COMMON_USER'}>
              <Chip label="Normal" color="info" />
            </MenuItem>
          </Select>
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
          disableElevation={true}
          onClick={handleEditUserSubmit}
        >
          Crear Usuario
        </Button>
      </Box>
    </Paper>
  );
};

export default UserForm;
