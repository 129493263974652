import React, { useState } from 'react';

import NoticesAlert from '../../common/components/noticesAlert';
import MobileHeader from './components/header';
import NotificationsSidebar from './components/notifications';
import MobileSidebar from './components/sidebar';

export interface IMobileDashboard {
  children?: React.ReactNode;
}

const MobileDashboard = ({ children }: IMobileDashboard) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [areNotificationsOpen, setAreNotificationsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleNotifications = () => {
    setAreNotificationsOpen(!areNotificationsOpen);
  };

  return (
    <>
      <NoticesAlert />
      <MobileHeader onMenuClick={toggleSidebar} onNotificationsClick={toggleNotifications} />
      <MobileSidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
      <NotificationsSidebar isOpen={areNotificationsOpen} onClose={toggleNotifications} />
      <div style={{ marginTop: 64, boxSizing: 'border-box', padding: '1rem', backgroundColor: 'var(--bg-color)' }}>
        {children}
      </div>
    </>
  );
};

export default MobileDashboard;
