export class Cache {
  items: any[] = [];

  mapped: any = {};

  lastUpdated: number = new Date(0).getTime();

  updateRate: number = 1000 * 60 * 15; // 15 minutos de cache activa por default

  key: string = '';

  public onKey: (item: any) => string = () => '';

  public constructor(key: string, onKey: any, updateRate: number = 1000 * 60 * 15) {
    this.updateRate = updateRate;
    this.key = key;
    this.onKey = onKey;

    this.loadData();
  }

  /**
   * Load the local data
   */
  public loadData() {
    if (localStorage.getItem(this.key)) {
      let result = JSON.parse(localStorage.getItem(this.key));

      this.items = result.items;
      this.lastUpdated = result.lastUpdated;

      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i];

        const key = this.onKey(item) as keyof typeof this.mapped;

        this.mapped[key] = item;
      }
    }
  }

  /**
   * Clears the cache
   */
  public clear() {
    this.items = [];
    this.mapped = {};

    this.updateData();
  }

  /**
   * Updates cache data
   */
  public updateData() {
    localStorage.setItem(
      this.key,
      JSON.stringify({
        items: this.items,
        mapped: this.mapped,
        lastUpdated: this.lastUpdated,
        updateRate: this.updateRate
      })
    );
  }

  /**
   * Update the cache update time.
   */
  public updateTime() {
    this.lastUpdated = new Date().getTime();
  }

  /**
   * Returns true if the cache is active.
   * @returns {Boolean}
   */

  public isCacheActive() {
    return this.items.length != 0 && this.lastUpdated + this.updateRate >= new Date().getTime();
  }

  /**
   * Returns all data from the cache.
   * @returns {Array}
   */
  public get() {
    return this.items;
  }

  /**
   * Returns a data mapped by ID.
   * @param {string} id
   * @returns {*}
   */
  public getItem(id: string) {
    return this.mapped[id];
  }

  /**
   * Allows checking if a data exists in the cache.
   * @param {string} id
   * @returns {boolean}
   */
  public exists(id: string) {
    return typeof this.mapped[id] !== 'undefined';
  }

  /**
   * Allows checking if the cache has data.
   * @returns {boolean}
   */
  public hasData() {
    return this.items.length > 0;
  }

  /**
   * Allows assigning data to the cache.
   * @param {string} id
   * @param {*} data
   */
  public setById(id: string, data: any) {
    this.mapped[id] = data;

    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      const key = this.onKey(item) as keyof typeof this.mapped;

      if (key === id) {
        this.items[i] = data;

        break;
      }
    }

    this.updateData();
  }

  /**
   * Allows adding data to the cache.
   * @param {*} data
   */
  public push(data: any) {
    if (Array.isArray(data)) this.pushList(data);
    else this.pushObject(data);
  }

  /**
   * Allows adding an object to the cache.
   * @param {*} data
   */
  public pushObject(data: any) {
    const key = this.onKey(data) as keyof typeof this.mapped;

    this.items.push(data);
    this.mapped[key] = data;

    this.updateData();
  }

  /**
   * Allows adding a list of objects to the cache.
   * @param {Array} data
   */
  public pushList(data: any[]) {
    data.forEach((item) => {
      this.pushObject(item);
    });
  }
}
