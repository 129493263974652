import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EM500XRecord } from '../../../../common/models/em500co2Record';
import { Notification } from '../../../../common/models/notification';
import { Record } from '../../../../common/models/record';

export interface WebsocketState {
  isConnected: boolean;
  notifications: Notification[];
  records: { [id: string]: Record };
  em500co2Record: EM500XRecord | undefined;
  em500udlRecord: EM500XRecord | undefined;
}

const initialState: WebsocketState = {
  isConnected: false,
  notifications: [],
  records: {},
  em500co2Record: undefined,
  em500udlRecord: undefined
};

export const websocketSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setConnectionStatus: (state, action: PayloadAction<boolean>) => {
      const newState = {
        ...state,
        isConnected: action.payload
      };

      return newState;
    },
    pushNotification: (state, action: PayloadAction<Notification>) => {
      const alreadyExists =
        state.notifications.filter((notification) => notification.id === action.payload.id).length > 0;

      if (alreadyExists) {
        return state;
      }

      action.payload.timestamp = new Date().toISOString();

      // Create the new state
      const newState = {
        ...state,
        notifications: [action.payload, ...state.notifications].splice(0, 100) // Having too many notifications give performance issues
      };

      return newState;
    },
    setRecord: (state, action: PayloadAction<Record>) => {
      const newRecords = {
        ...state.records
      };

      newRecords[action.payload.workerId] = action.payload;

      return {
        ...state,
        records: newRecords
      };
    },
    setCO2Record: (state, action: PayloadAction<EM500XRecord>) => {
      return {
        ...state,
        em500co2Record: action.payload
      };
    },
    setUDLRecord: (state, action: PayloadAction<EM500XRecord>) => {
      return {
        ...state,
        em500udlRecord: action.payload
      };
    }
  }
});

// Action creators are generated for each case reducer function
export const { setConnectionStatus, pushNotification, setRecord, setCO2Record, setUDLRecord } = websocketSlice.actions;

export default websocketSlice.reducer;
