import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import loadingReducer from './reducers/loading';
import loginReducer from './reducers/login';
import routerReducer from './reducers/router';
import themeReducer from './reducers/theme';
import websocketReducer from './reducers/websocket';

const rootReducer = combineReducers({
  loginReducer,
  loadingReducer,
  themeReducer,
  websocketReducer,
  routerReducer
});

export const store = configureStore({
  reducer: rootReducer
});

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept(() => {
    store.replaceReducer(rootReducer);
  });
}

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
