import { ScatterplotLayer } from '@deck.gl/layers';

import { Record, RecordTypes } from '../../../common/models/record';
import { Zone } from '../../../common/models/zone';

/**
 * Map the zones to polygons to be used by deck.gl
 * @returns
 */
export function useZonesAsPolygons(zones: Zone[]) {
  return zones.map((zone) => ({
    contour: zone.polygon.map((coordinate) => [coordinate.long, coordinate.lat]),
    riskLevel: zone.riskLevel,
    tooltip: zone.name
  }));
}

/**
 * Map the record to mapRecordsToHeatpoints
 * @param workers
 * @returns
 */
export function useRecordsAsHeatpoints(records: Record[]) {
  return records.map((record) => ({
    coordinates: [record.long, record.lat, 0],
    weight: 10000
  }));
}

/**
 * Map the record to waypoints
 * @param workers
 * @returns
 */
export function useRecordsAsWaypoints(records: Record[]) {
  return [
    {
      waypoints: records.map((record, index) => ({
        coordinates: [record.long, record.lat, 10],
        timestamp: index * 2
      }))
    }
  ];
}

/**
 * Map the records to circles zones (Used to reinforce the Danger Information)
 * @param workers
 * @returns
 */
export function useRecordsAsScatters(records: Record[]) {
  return records.map((record) => ({
    tooltip: record.type,
    coordinates: [record.long, record.lat, 1],
    type: record.type
  }));
}

/**
 * Map the zones to its respective colors
 * @param riskLevel
 * @returns
 */
export function useZonesAsPolygonColors(riskLevel: string) {
  if (riskLevel === 'HIGH_RISK') return [252, 211, 77, 50];
  if (riskLevel === 'LOW_RISK') return [5, 180, 138, 50];
  if (riskLevel === 'INTOLERABLE_RISK') return [227, 0, 0, 50];
  if (riskLevel === 'SERIOUS_RISK') return [255, 158, 44, 50];
  if (riskLevel === 'POSSIBLE_RISK') return [138, 232, 81, 50];

  return [196, 196, 196, 150];
}

/**
 * Map the markers icons to its respective color
 * @param type
 * @returns
 */
export function mapRecordToMarkerColor(type: string) {
  const RED_COLOR = [169, 50, 38];
  const YELLOW_COLOR = [255, 212, 73];
  const GREEN_COLOR = [23, 165, 137];

  if (type === RecordTypes.SOS) return RED_COLOR;
  if (type === RecordTypes.ANORMAL_HEART_RATE) return YELLOW_COLOR;
  if (type === RecordTypes.ANORMAL_TEMPERATURE) return YELLOW_COLOR;
  if (type === RecordTypes.INSUFFICIENT_PERMISSIONS) return YELLOW_COLOR;
  if (type === RecordTypes.LOW_HEART_RATE) return YELLOW_COLOR;
  if (type === RecordTypes.MAN_DOWN) return RED_COLOR;

  return GREEN_COLOR;
}

export function useScatterplotLayer(records: Record[]) {
  return new ScatterplotLayer({
    id: 'scatter-layer',
    data: useRecordsAsScatters(records),
    pickable: true,
    opacity: 0.4,
    stroked: true,
    filled: true,
    radiusScale: 4,
    radiusMinPixels: 1,
    radiusMaxPixels: 100,
    lineWidthMinPixels: 1,
    getPosition: (d) => d.coordinates,
    getRadius: () => 7,
    getFillColor: () => [227, 0, 0, 80],
    getLineColor: () => [227, 0, 0, 80]
  });
}
