import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { environment } from '../../../infra/environment/environment';
import { AppState } from '../../../infra/store';
import { WebsocketService } from '../../../infra/websocket/service';
import Show from '../show';

const Websocket = () => {
  // eslint-disable-next-line
  const [websocketService, setWebsocketService] = useState<WebsocketService>(undefined);
  const dispatch = useDispatch();
  const isOnline = useSelector((state: AppState) => state.websocketReducer.isConnected);

  useEffect(() => {
    if (dispatch === undefined) return;

    const service = WebsocketService.getInstance(environment.recordWebsocketEndpoint, dispatch);

    setWebsocketService(service);
  }, [dispatch]);

  return (
    <Tooltip title="Indica si el sistema esta conectado">
      <Box sx={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
        <IconButton>
          <Show when={isOnline}>
            <GpsFixedIcon color={'primary'} />
          </Show>
          <Show when={!isOnline}>
            <GpsNotFixedIcon color={'action'} />
          </Show>
        </IconButton>
        <Typography color={isOnline ? 'primary' : 'text'}>
          <strong>{isOnline ? 'Conectado' : 'Desconectado'}</strong>
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default Websocket;
