import { AdminAPI } from '../../../../infra/api/admin-api';
import { environment } from '../../../../infra/environment/environment';

export class NoticesService {
  private api: AdminAPI;

  constructor() {
    this.api = new AdminAPI(environment);
  }

  getActiveNotices = () => {
    return this.api.getActiveNotices();
  };
}
