import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ListItemButton, SvgIconTypeMap } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export interface IQAListItem {
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string };
  title: string;
  path: string;
  elements: IQAListItems[];
  component: JSX.Element;
  getProtection?: () => boolean;
  isSelected?: boolean;
  onClick?: () => void;
}

export interface IQAListItems {
  title: string;
  path: string;
  ElementIcon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string };
  component: JSX.Element;
  hide?: boolean;
  isSelected?: boolean;
}

const QAListItem = ({ Icon, title, elements, isSelected, onClick, getProtection }: IQAListItem) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [subSelected, setSubSelected] = useState(-1);
  const { pathname = '' } = useLocation();

  const handleClick = () => {
    setOpen(!open);
    onClick();
  };

  useEffect(() => {
    let finded = false;
    elements.forEach((element, id) => {
      if (`/${element.path}` === pathname) {
        setSubSelected(id);
        finded = true;
      }
    });

    if (!finded) {
      setSubSelected(-1);
    }
  }, [pathname]);

  if (getProtection && !getProtection()) {
    return <></>;
  }

  return (
    <>
      <ListItemButton onClick={handleClick} selected={isSelected}>
        <ListItemIcon>
          <Icon color={!!isSelected ? 'primary' : 'action'} />
        </ListItemIcon>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {elements.map((element, id) => {
            if (element.hide) return <div key={id}></div>;

            return (
              <ListItemButton
                key={id}
                sx={{ paddingLeft: '3rem', boxSizing: 'border-box' }}
                onClick={() => {
                  if (element.path) navigate(element.path, { replace: true });
                  onClick();
                }}
              >
                {element.ElementIcon && (
                  <ListItemIcon>
                    <element.ElementIcon
                      color={!!isSelected ? (subSelected === id ? 'primary' : 'secondary') : 'action'}
                    />
                  </ListItemIcon>
                )}
                <ListItemText primary={element.title} />
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

export default QAListItem;
