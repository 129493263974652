import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import HomeIcon from '@mui/icons-material/Home';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, Paper } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';
import DataTable from '../../common/components/dataTable';
import { Machine } from '../../common/models/machine';
import { setLoading, setLoadingText } from '../../infra/store/reducers/loading';
import { setMachineId } from '../../infra/store/reducers/router';
import { MachinesService } from './services/machineService';

const breadcrumbItems: IBreadcrumbItem[] = [
  {
    Icon: <HomeIcon color="secondary" />,
    text: 'Home',
    path: '/'
  },
  {
    Icon: <GpsFixedIcon color="secondary" />,
    text: 'Monitoreo',
    path: '/monitoreo'
  },
  {
    Icon: <PrecisionManufacturingIcon color="primary" />,
    text: 'Máquinas',
    path: '/monitoreo/maquinas'
  }
];

const Machines = () => {
  const [machines, setMachines] = useState<Machine[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const service = new MachinesService();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Máquina',
      flex: 2
    },
    {
      field: 'type',
      headerName: 'Modelo',
      flex: 1
    },
    { field: 'euid', headerName: 'Euid', flex: 1, sortable: true },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const onClick = () => {
          dispatch(setMachineId(params.row.id));
          navigate(`/monitoreo/maquina`);
        };

        return (
          <IconButton onClick={onClick}>
            <VisibilityIcon color="primary" />
          </IconButton>
        );
      }
    }
  ];

  const handleApiResponse = (response: Machine[] | Error) => {
    if (response instanceof Error) throw response;
    setMachines(response);
  };

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(setLoadingText('Cargando Máquinas'));

    service
      .getMachines()
      .then(handleApiResponse)
      .catch(console.error)
      .finally(() => {
        dispatch(setLoading(false));
      });
  }, []);

  return (
    <div className="grid w-full h-full px-2">
      <Breadcrumb items={breadcrumbItems} title="Maquinas Instaladas en Planta de Limón" />

      <Paper elevation={0} sx={{ p: 2, mt: 3 }}>
        <DataTable title="Lista de Máquinas" columns={columns} data={machines} />
      </Paper>
    </div>
  );
};
export default Machines;
