import { HealthAPI } from '../../../infra/api/health-api';
import { environment } from '../../../infra/environment/environment';

export class ReportService {
  private api: HealthAPI;

  constructor() {
    this.api = new HealthAPI(environment);
  }

  getReportsByDate = (date: string) => {
    return this.api.getReportFiltered('MONTHLY', date);
  };
}
