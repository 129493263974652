import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import HomeIcon from '@mui/icons-material/Home';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import SensorsIcon from '@mui/icons-material/Sensors';
import { Alert, AlertTitle, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import NetSVG from '../../assets/network-flatline.svg';
import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';
import { Machine } from '../../common/models/machine';
import { AppState } from '../../infra/store';
import { setLoading } from '../../infra/store/reducers/loading';
import MachineHistogram from './components/machineHistogram';
import MachineInfo from './components/machineInfo';
import MachineRealtime from './components/machineRealtime';
import { MachineDetailService } from './services/machineService';

const breadcrumbItems: IBreadcrumbItem[] = [
  {
    Icon: <HomeIcon color="secondary" />,
    text: 'Home',
    path: '/'
  },
  {
    Icon: <GpsFixedIcon color="secondary" />,
    text: 'Monitoreo',
    path: '/monitoreo'
  },
  {
    Icon: <PrecisionManufacturingIcon color="secondary" />,
    text: 'Máquinas',
    path: '/monitoreo/maquinas'
  },
  {
    Icon: <SensorsIcon color="primary" />,
    text: 'Máquina',
    path: '/monitoreo/maquina'
  }
];

const MachineDetail = () => {
  const [machine, setMachine] = useState<Machine>(undefined);

  const id = useSelector((state: AppState) => state.routerReducer.machineId);
  const CO2_ID = '93ca13cb-97d2-4ae8-b43d-a5a0f1c86283';
  const service = new MachineDetailService();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const em500co2Record = useSelector((state: AppState) => state.websocketReducer.em500co2Record);
  const em500udlRecord = useSelector((state: AppState) => state.websocketReducer.em500udlRecord);

  const handleApiResponse = (response: Machine | Error) => {
    if (response instanceof Error) throw response;

    setMachine(response);
  };

  useEffect(() => {
    if (!id) {
      navigate('/monitoreo/maquinas');
    }

    dispatch(setLoading(true));
    service
      .getMachineById(id)
      .then(handleApiResponse)
      .catch(() => navigate('/monitoreo/maquinas'))
      .finally(() => dispatch(setLoading(false)));
  }, []);

  if (!machine) return <></>;

  breadcrumbItems[3].text = machine.name;

  const mode = id === CO2_ID ? 'co2' : 'udl';
  const histogramMode = id === CO2_ID ? 'machine-co2' : 'machine-udl';

  const exists = (mode === 'co2' && !em500co2Record) || (mode === 'udl' && !em500udlRecord);

  return (
    <div className="grid w-full h-full px-2">
      <Breadcrumb items={breadcrumbItems} title="Detalles de la máquina" />
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={{ xs: 1, md: 1 }} sx={{ mt: 1 }}>
        <Grid md={4} xs={8}>
          <MachineInfo machine={machine} />
        </Grid>
        <Grid xs={8}>
          {exists && (
            <Paper
              elevation={0}
              sx={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                flexDirection: 'column',
                height: '100%',
                p: 2
              }}
            >
              <Typography textAlign="start" component="h1" variant="h6" sx={{ textAlign: 'start' }}>
                <strong>Información de los dispositivos</strong>
              </Typography>
              <img src={NetSVG} width={400} />
              <Alert severity="warning">
                <AlertTitle>Aún no hay información. </AlertTitle>
                Espera unos minutos para recibir la información, en caso de no recibir información comunicate con el
                departamento de IT.
              </Alert>
            </Paper>
          )}
          {!exists && <MachineRealtime co2Record={em500co2Record} udlRecord={em500udlRecord} mode={mode} />}
        </Grid>
        <Grid xs={12}>
          <Paper
            elevation={0}
            sx={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              flexDirection: 'column',
              height: '100%',
              p: 2
            }}
          >
            <Typography textAlign="start" component="h1" variant="h6" sx={{ textAlign: 'start' }}>
              <strong>Estado de la Máquina durante los últimos 7 días</strong>
            </Typography>
          </Paper>
        </Grid>
        <Grid xs={12}>
          <MachineHistogram mode={histogramMode} />
        </Grid>
      </Grid>
    </div>
  );
};
export default MachineDetail;
