import AccountBoxIcon from '@mui/icons-material/AccountBox';
import HomeIcon from '@mui/icons-material/Home';
import React from 'react';

import LogoutSVG from '../../assets/road-trip-flatline-829ea.svg';
import ProfileSVG from '../../assets/social-media-flatline.svg';
import AccesibilitySVG from '../../assets/team-building-flatline.svg';
import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';
import NavigationMenu, { INavigationMenuItem } from '../../common/components/navigationMenu';

const menuItems: INavigationMenuItem[] = [
  {
    name: 'Mi Perfil',
    to: '/perfil/usuario',
    types: ['COMMON_USER', 'ADMIN'],
    svg: <img src={ProfileSVG} alt="Mi Perfil" width={250} />
  },
  {
    name: 'Accesibilidad',
    to: '/perfil/accesibilidad',
    types: ['COMMON_USER', 'ADMIN'],
    svg: <img src={AccesibilitySVG} alt="Accesibilidad" width={250} />
  },
  {
    name: 'Cerrar Sesión',
    to: '/logout',
    types: ['COMMON_USER', 'ADMIN'],
    svg: <img src={LogoutSVG} alt="Cerrar Sesión" width={250} />
  }
];
const MyUserMenu = () => {
  const breadcrumbItems: IBreadcrumbItem[] = [
    {
      Icon: <HomeIcon color="secondary" />,
      text: 'Home',
      path: '/'
    },
    {
      Icon: <AccountBoxIcon color="primary" />,
      text: 'Mi Usuario',
      path: '/perfil'
    }
  ];

  return (
    <div className="grid w-full h-full px-2">
      <Breadcrumb items={breadcrumbItems} title="Menú de Monitoreo" />
      <NavigationMenu items={menuItems} />
    </div>
  );
};

export default MyUserMenu;
