import AccountBoxIcon from '@mui/icons-material/AccountBox';
import HomeIcon from '@mui/icons-material/Home';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Alert, Avatar, Box, Button, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';
import { UserAPI } from '../../infra/api/user-api';
import { setLoading } from '../../infra/store/reducers/loading';
import { ProfileService } from './services/profileService';

const breadcrumbItems: IBreadcrumbItem[] = [
  {
    Icon: <HomeIcon color="secondary" />,
    text: 'Home',
    path: '/'
  },
  {
    Icon: <AccountBoxIcon color="secondary" />,
    text: 'Mi Usuario',
    path: '/perfil'
  },
  {
    Icon: <PermContactCalendarIcon color="primary" />,
    text: UserAPI.getCurrentUser()?.name ?? '',
    path: '/perfil/usuario'
  }
];

const Profile = () => {
  const currentUser = UserAPI.getCurrentUser();
  const service = new ProfileService();

  const dispatch = useDispatch();

  const [name, setName] = useState(currentUser.name);
  const [email, setEmail] = useState(currentUser.email);

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const handleEditUserButton = () => {
    if (!email || email === '') return;
    if (!name || name === '') return;

    dispatch(setLoading(true));
    service
      .editUser({
        ...currentUser,
        email: email,
        name: name
      })
      .then(() => {
        alert('Usuario Actualizado');
      })
      .catch(() => alert('Ha ocurrido un error'))
      .finally(() => dispatch(setLoading(false)));
  };

  const handleEditPasswordButton = () => {
    if (!oldPassword || oldPassword === '') return;
    if (!newPassword || newPassword === '') return;

    dispatch(setLoading(true));
    service
      .changePassword(oldPassword, newPassword)
      .then((res) => {
        if (res instanceof Error) throw res;
        setNewPassword('');
        setOldPassword('');
        alert('Contraseña Actualizada');
      })
      .catch(() => alert('Contraseña incorrecta'))
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <Box>
      <Breadcrumb items={breadcrumbItems} title={`Bienvenido ${currentUser.name}`} />

      <Grid2 container columns={{ xs: 4, sm: 8, md: 12 }} spacing={{ xs: 1, md: 2 }} sx={{ mt: 2 }}>
        <Grid2 xs={8} md={4}>
          <Paper
            sx={{
              p: 2,
              mt: 1,
              boxSizing: 'border-box',
              display: 'flex',
              justifyItems: 'start',
              justifyContent: 'start',
              width: '100%',
              flexDirection: 'column'
            }}
            elevation={0}
          >
            <Typography variant="h6" component="p">
              <strong>Detalles de tu usuario</strong>
            </Typography>
            <Avatar
              src={UserAPI.getCurrentUser().profilePicture}
              alt="Imagen de Perfil"
              sx={{ width: 150, height: 150, alignSelf: 'center', mt: 2 }}
            />
            <Typography variant="h6" component="p" sx={{ textAlign: 'center', mt: 2 }}>
              <strong>Tu ID: </strong> {currentUser.id}
            </Typography>
            <TextField
              sx={{ mt: 3 }}
              margin="normal"
              required
              fullWidth
              id="fullname"
              label="Nombre"
              name="fullname"
              autoComplete="fullname"
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              sx={{ mt: 3 }}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Correo"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disableElevation={true}
              onClick={handleEditUserButton}
            >
              Guardar Cambios
            </Button>
          </Paper>
        </Grid2>
        <Grid2 xs={8}>
          <Paper
            sx={{
              p: 2,
              mt: 1,
              boxSizing: 'border-box',
              display: 'flex',
              justifyItems: 'start',
              justifyContent: 'start',
              width: '100%',
              flexDirection: 'column'
            }}
            elevation={0}
          >
            <Typography variant="h6" component="p">
              <strong>Detalles de seguridad</strong>
            </Typography>
            <Typography variant="subtitle1" component="p" sx={{ mt: 2 }}>
              <strong>Cambiar Contraseña</strong>
            </Typography>
            <Alert severity="info" sx={{ mt: 1, mb: 4 }}>
              Cada vez que cambies la contraseña se te enviará un correo notificándote del cambio.
            </Alert>
            <TextField
              margin="normal"
              required
              fullWidth
              name="oldPassword"
              label="Antigua Contraseña"
              type={showOldPassword ? 'text' : 'password'}
              id="oldPassword"
              autoComplete="current-password"
              sx={{ mt: 3 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowOldPassword(!showOldPassword)} edge="end">
                      {showOldPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="Nueva Contraseña"
              type={showNewPassword ? 'text' : 'password'}
              id="newPassword"
              autoComplete="current-password"
              sx={{ mt: 3 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disableElevation={true}
              disabled={!UserAPI.isCurrentUserAdmin()}
              onClick={handleEditPasswordButton}
            >
              Cambiar Contraseña
            </Button>
          </Paper>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default Profile;
