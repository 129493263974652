import DescriptionIcon from '@mui/icons-material/Description';
import HomeIcon from '@mui/icons-material/Home';
import InsightsIcon from '@mui/icons-material/Insights';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';
import ReportGrid from '../../common/components/reportGrid';
import Show from '../../common/components/show';
import { Report } from '../../common/models/report';
import { formatDate } from '../../common/utils/dates';
import { AppState } from '../../infra/store';
import { setLoading } from '../../infra/store/reducers/loading';
import { ReportDetailService } from './services/reportService';

const breadcrumbItems: IBreadcrumbItem[] = [
  {
    Icon: <HomeIcon color="secondary" />,
    text: 'Home',
    path: '/'
  },
  {
    Icon: <InsightsIcon color="secondary" />,
    text: 'Estadísticas',
    path: '/estadisticas'
  },
  {
    Icon: <SummarizeIcon color="secondary" />,
    text: 'Reportes',
    path: '/estadisticas/reportes'
  },
  {
    Icon: <DescriptionIcon color="primary" />,
    text: 'Reporte',
    path: '/estadisticas/reporte'
  }
];

const ReportDetail = () => {
  const [report, setReport] = useState<Report>(undefined);

  const id = useSelector((state: AppState) => state.routerReducer.reportId);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const service = new ReportDetailService();

  const handleApiResponse = (response: Report | Error) => {
    if (response instanceof Error) throw response;

    setReport(response);
  };

  useEffect(() => {
    if (!id) {
      navigate('/estadisticas/reportes');
    }

    dispatch(setLoading(true));
    service
      .getReportsById(id)
      .then(handleApiResponse)
      .catch(() => navigate('/estadisticas/reportes'))
      .finally(() => dispatch(setLoading(false)));
  }, []);

  return (
    <div className="grid w-full h-full px-2">
      <Breadcrumb items={breadcrumbItems} title="Detalles del Reporte" />
      <Show when={!!report}>
        <Paper elevation={0} sx={{ my: 3, pt: 2 }}>
          <Typography sx={{ px: 2, mb: 2 }} variant="h4">
            <strong>Reporte</strong>
          </Typography>
          <Typography sx={{ px: 2, pb: 1 }}>{`Zona: ${report?.zone}`}</Typography>
          <Typography sx={{ px: 2, pb: 1 }}>{formatDate(report?.creationDate, `Periodo: _month_ del YYYY`)}</Typography>
          <ReportGrid report={report} />
        </Paper>
      </Show>
    </div>
  );
};
export default ReportDetail;
