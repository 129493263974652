import Co2Icon from '@mui/icons-material/Co2';
import CompressIcon from '@mui/icons-material/Compress';
import ThermostatAutoIcon from '@mui/icons-material/ThermostatAuto';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import { Alert, AlertTitle, Box, Paper, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';

import { EM500XRecord } from '../../../common/models/em500co2Record';
export type MachineInfoProps = {
  co2Record?: EM500XRecord;
  udlRecord?: EM500XRecord;
  mode: 'co2' | 'udl';
};

const MachineRealtime = ({ co2Record, udlRecord, mode }: MachineInfoProps) => {
  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        boxSizing: 'border-box',
        display: 'flex',
        justifyItems: 'start',
        justifyContent: 'start',
        width: '100%',
        height: '100%',
        flexDirection: 'column'
      }}
    >
      <Typography variant="h6" component="p">
        <strong>Información en Tiempo Real</strong>
      </Typography>
      <Box
        sx={{
          boxSizing: 'border-box',
          display: 'flex',
          justifyItems: 'center',
          justifyContent: 'center',
          width: '100%',
          flexDirection: 'column'
        }}
      >
        <Typography variant="body1" component="p" sx={{ mt: 2 }}>
          <strong>Estado Actual</strong>
        </Typography>
        <Grid2 container sx={{ mt: 1 }} columns={{ xs: 4, sm: 8, md: 12 }} spacing={{ xs: 1, md: 1 }}>
          {mode === 'co2' && !!co2Record?.temperature && (
            <Grid2 xs={4}>
              <Alert
                severity="info"
                sx={{ boxSizing: 'border-box', p: 1 }}
                icon={<ThermostatAutoIcon />}
                variant="standard"
              >
                <AlertTitle>Temperatura</AlertTitle>
                <Typography component="p" variant="body1" sx={{ mt: 1 }}>
                  <strong>{co2Record.temperature}&deg;C</strong>
                </Typography>
              </Alert>
            </Grid2>
          )}
          {mode === 'co2' && !!co2Record?.humidity && (
            <Grid2 xs={4}>
              <Alert severity="info" sx={{ boxSizing: 'border-box', p: 1 }} icon={<WaterDropIcon />} variant="standard">
                <AlertTitle>Humedad</AlertTitle>
                <Typography component="p" variant="body1" sx={{ mt: 1 }}>
                  <strong>{co2Record.humidity}%RH</strong>
                </Typography>
              </Alert>
            </Grid2>
          )}
          {mode === 'co2' && !!co2Record?.co2 && (
            <Grid2 xs={4}>
              <Alert severity="info" sx={{ boxSizing: 'border-box', p: 1 }} icon={<Co2Icon />} variant="standard">
                <AlertTitle>Nivel de CO2</AlertTitle>
                <Typography component="p" variant="body1" sx={{ mt: 1 }}>
                  <strong>{co2Record.co2}ppm</strong>
                </Typography>
              </Alert>
            </Grid2>
          )}
          {mode === 'co2' && !!co2Record?.pressure && (
            <Grid2 xs={4}>
              <Alert severity="info" sx={{ boxSizing: 'border-box', p: 1 }} icon={<CompressIcon />} variant="standard">
                <AlertTitle>Nivel de Presión</AlertTitle>
                <Typography component="p" variant="body1" sx={{ mt: 1 }}>
                  <strong>{co2Record.pressure}hPa</strong>
                </Typography>
              </Alert>
            </Grid2>
          )}
          {mode === 'udl' && !!udlRecord?.distance && (
            <Grid2 xs={4}>
              <Alert severity="info" sx={{ boxSizing: 'border-box', p: 1 }} icon={<CompressIcon />} variant="standard">
                <AlertTitle>Distancia desde el sensor</AlertTitle>
                <Typography component="p" variant="body1" sx={{ mt: 1 }}>
                  <strong>{udlRecord.distance}m</strong>
                </Typography>
              </Alert>
            </Grid2>
          )}
        </Grid2>
      </Box>
    </Paper>
  );
};
export default MachineRealtime;
