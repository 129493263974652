/**
 * Maps the worker situation to a color
 * @param type the current worker situation
 * @returns the color according to the situation
 */
export function useSituationColor(type: string): 'success' | 'error' | 'warning' | 'info' {
  if (type === 'NONE') return 'success';
  if (type === 'LOW_HEART_RATE') return 'error';
  if (type === 'MAN_DOWN') return 'error';
  if (type === 'SOS') return 'error';
  if (type === 'INSUFFICIENT_PERMISSIONS') return 'warning';
  if (type === 'ANORMAL_TEMPERATURE') return 'warning';
  if (type === 'ANORMAL_HEART_RATE') return 'warning';

  return 'info';
}
