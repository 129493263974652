import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppState } from '../../../infra/store';
import QAListItems from '../../dashboard/components/listItems';

export interface IDesktopSidebar {}

const DesktopSidebar = ({}: IDesktopSidebar) => {
  const navigate = useNavigate();

  const logo = useSelector((state: AppState) => state.themeReducer.logo);

  return (
    <div className="desktop-sidebar-container">
      <div className="desktop-sidebar">
        <div className="desktop-sidebar-logo">
          <img src={logo} alt="Arcelor Mittal Logo" onClick={() => navigate('/')} />
        </div>
        <QAListItems />
      </div>
    </div>
  );
};

export default DesktopSidebar;
