import { createTheme, Theme as MuiTheme, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Loading from '../../common/components/loading';
import { AppState } from '../../infra/store';
import { assignChristmassVariables } from '../../infra/store/reducers/theme/christmass';
import { assignDarkVariables } from '../../infra/store/reducers/theme/dark';
import { assignHighContrastVariables } from '../../infra/store/reducers/theme/highContrast';
import { assignLightVariables } from '../../infra/store/reducers/theme/light';
import MainRouter from '../router';

const Theme = () => {
  const selectedTheme = useSelector((state: AppState) => state.themeReducer.currentTheme);
  const [theme, setTheme] = useState<MuiTheme | undefined>();

  const getLightTheme = () => {
    assignLightVariables();
    return createTheme({
      palette: {
        primary: {
          main: '#ff3700'
        },
        secondary: {
          main: '#221E20'
        },
        background: {
          default: '#ffffff',
          paper: '#ffffff'
        },
        text: {
          primary: '#000000'
        }
      }
    });
  };

  const getDarkTheme = () => {
    assignDarkVariables();
    return createTheme({
      palette: {
        primary: {
          main: '#ff3700'
        },
        secondary: {
          main: '#ffffff'
        },
        background: {
          default: '#181818',
          paper: '#282828'
        },
        text: {
          primary: '#ffffff'
        },
        action: {
          active: '#696a6d'
        }
      }
    });
  };

  const getHighContrastTheme = () => {
    assignHighContrastVariables();
    return createTheme({
      palette: {
        primary: {
          main: '#F57600'
        },
        secondary: {
          main: '#ffffff'
        },
        background: {
          default: '#000000',
          paper: '#000000'
        },
        text: {
          primary: '#ffffff'
        },
        action: {
          active: '#ffffff'
        }
      }
    });
  };

  const getChristmassTheme = () => {
    assignChristmassVariables();
    return createTheme({
      palette: {
        primary: {
          main: '#1C4219'
        },
        secondary: {
          main: '#AF271D'
        },
        background: {
          default: '#FFFBF5',
          paper: '#FFFBF5'
        },
        text: {
          primary: '#000000'
        }
      }
    });
  };

  useEffect(() => {
    let defaultTheme = getLightTheme();

    if (selectedTheme === 'DARK') defaultTheme = getDarkTheme();
    if (selectedTheme === 'HIGH_CONTRAST') defaultTheme = getHighContrastTheme();
    if (selectedTheme === 'CHRISTMASS') defaultTheme = getChristmassTheme();

    setTheme(defaultTheme);
  }, [selectedTheme]);

  if (!theme) return <></>;

  return (
    <ThemeProvider theme={theme}>
      <Loading />
      <MainRouter />
    </ThemeProvider>
  );
};

export default Theme;
