import './styles/notificationStyles.css';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import EmptyArt from '../../../assets/empty_art.svg';
import { AppState } from '../../../infra/store';
import Show from '../show';
import NotificationItem from './components/notificationItem';
export interface INotifications {
  onClose?: () => void;
}

const Notifications = ({ onClose }: INotifications) => {
  const notifications = useSelector((state: AppState) => state.websocketReducer.notifications);

  return (
    <Box className="notifications-container">
      <Box sx={{ display: 'flex', alignItems: 'center', alignContent: 'center', p: 1, mt: 2 }}>
        {!!onClose && (
          <IconButton onClick={onClose} color="primary">
            <HighlightOffIcon />
          </IconButton>
        )}
        <Typography component="h1" variant="h5" sx={{ color: 'var(--text-color)' }}>
          <strong>Alertas</strong>
        </Typography>
      </Box>
      <Show when={notifications.length === 0}>
        <div className="notification-art-container">
          <div>
            <img src={EmptyArt} alt="Imagen que representa que no hay contenido" />
            <Typography component="p" variant="body1">
              No hay notificaciones
            </Typography>
          </div>
        </div>
      </Show>
      <Show when={notifications.length > 0}>
        <ul className="notification-item-container">
          {notifications.map((notification, key) => (
            <Box sx={{ my: 2, px: 1 }} key={key}>
              <li style={{ boxSizing: 'border-box', width: '100%' }}>
                <NotificationItem notification={notification} />
              </li>
            </Box>
          ))}
        </ul>
      </Show>
    </Box>
  );
};

export default Notifications;
