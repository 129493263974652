export type Worker = {
  id: string;
  governmentId: string;
  email: string;
  secondaryEmail?: string;
  active: WorkerStatus;
  birthdate: string;
  age: number;
  fullname: string;
  watchName: string;
  watchEUI: string;
  hasWatch: WorkerWatchStatus;
  location: string;
  profilePicture: string;
};

export type RealtimeWorker = {
  id: string;
  governmentId: string;
  email: string;
  secondaryEmail?: string;
  active: WorkerStatus;
  birthdate: string;
  age: number;
  fullname: string;
  watchName: string;
  watchEUI: string;
  hasWatch: WorkerWatchStatus;
  location: string;
  profilePicture: string;
  batteryCapacity?: number;
  beaconId?: number;
  beaconType?: number;
  burnedCalories?: number;
  commandId?: number;
  distanceTraveled?: number;
  creationDate?: string;
  gpsStatusAndReport?: number;
  workerId?: string;
  watchId?: string;
  lat?: number;
  long?: number;
  dailySteps?: number;
  protocolVersion?: number;
  rawPayload?: string;
  reserved?: number;
  heartRate?: number;
  rssi?: number;
  bodyTemperature?: number;
  txPower?: number;
  type?: string;
  watchTimestamp?: string;
  zone?: string;
  zoneId?: string;
};

export enum WorkerWatchStatus {
  'ACTIVE' = 1,
  'UNACTIVE' = 0
}
export enum WorkerStatus {
  'ACTIVE' = 1,
  'ARCHIVED' = 0
}
export enum RecordType {
  'NONE' = 'NONE',
  'LOW_HEART_RATE' = 'LOW_HEART_RATE',
  'MAN_DOWN' = 'MAN_DOWN',
  'SOS' = 'SOS',
  'INSUFFICIENT_PERMISSIONS' = 'INSUFFICIENT_PERMISSIONS'
}
