import { API } from '../../common/libs/api';
import { Cache } from '../../common/libs/cache';
import { Zone } from '../../common/models/zone';
import { ZonePermissions } from '../../common/models/zonePermission';
import { Environment } from '../environment/environment';

export class ZoneAPI extends API {
  private cache: Cache;

  constructor(env: Environment) {
    super(env, 'Zone');

    this.cache = new Cache('zones_cache', (zone: Zone) => zone.id);
  }

  /**
   * Get all the zones
   * @param props
   */
  public async edit(zone: Zone) {
    try {
      const body: any = {
        id: zone.id,
        riskDescription: zone.riskDescription,
        lat: zone.lat,
        long: zone.long,
        riskLevel: zone.riskLevel,
        name: zone.name,
        plant: zone.plant,
        polygon: zone.polygon,
        hasRestrictedAccess: zone.hasRestrictedAccess
      };
      const response = await this.put<void>('/zone', body);

      this.cache.clear();

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  /**
   * Get all the zones
   * @param props
   */
  public async getAll() {
    if (this.cache.isCacheActive() && this.cache.hasData()) return this.cache.get() as Zone[];

    try {
      const response = await this.get<Zone[]>('/zones', {});

      this.cache.clear();
      this.cache.push(response.message);
      this.cache.updateTime();

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  /**
   * Get a zone by its id
   * @param props
   */
  public async getById(id: string) {
    if (this.cache.isCacheActive() && this.cache.exists(id)) return this.cache.getItem(id) as Zone;

    try {
      const response = await this.get<Zone>(`/zone?id=${id}`);

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  /**
   * Get a zone permission by its zone id
   * @param props
   */
  public async getZonePermissionsByZoneId(id: string) {
    try {
      const response = await this.get<ZonePermissions[]>(`/zone-permission?id=${id}`);

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  /**
   * Adds a new zone permissions
   * @param props
   */
  public async addZonePermission(workerId: string, ZoneId: string) {
    try {
      const response = await this.post<void>('/zone-permission', { workerId, ZoneId });

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  /**
   * Adds a new zone permissions
   * @param props
   */
  public async archiveZonePermission(id: string) {
    try {
      const response = await this.delete<void>(`/zone-permission?id=${id}`);

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  // Cleans zones cache
  public clearCache() {
    this.cache.clear();
  }
}
