import { Record } from '../../../common/models/record';
import { RealtimeWorker, Worker, WorkerWatchStatus } from '../../../common/models/worker';
import { CreateRealtimeWorker } from '../../../common/utils/worker';
import { HealthAPI } from '../../../infra/api/health-api';
import { WorkerAPI } from '../../../infra/api/worker-api';
import { environment } from '../../../infra/environment/environment';

export class WorkerService {
  private api: WorkerAPI;

  private healthApi: HealthAPI;

  constructor() {
    this.api = new WorkerAPI(environment);
    this.healthApi = new HealthAPI(environment);
  }

  getWorkerById = (id: string) => {
    return this.api.getById(id);
  };

  getUnusedSmartwatches = () => {
    return this.healthApi.getUnusedSmartwatches();
  };

  useRealtimeWorker = (worker: Worker, records: { [id: string]: Record }): RealtimeWorker => {
    return CreateRealtimeWorker(worker, records[worker.id]);
  };

  editWorker = (workerId: string, watchName: string, watchEUI: string, hasWatch: WorkerWatchStatus) => {
    return this.api.edit(workerId, watchName, watchEUI, hasWatch);
  };
}
