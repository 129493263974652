import { ZoneAPI } from '../../../infra/api/zone-api';
import { environment } from '../../../infra/environment/environment';

export class ZoneService {
  private api: ZoneAPI;

  constructor() {
    this.api = new ZoneAPI(environment);
  }

  getZones = () => {
    return this.api.getAll();
  };

  clearCache = () => {
    this.api.clearCache();

    return this.getZones();
  };

  mapRiskLevel = (riskLevel: string) => {
    if (riskLevel === 'HIGH_RISK') return 'Riesgo Importante';
    if (riskLevel === 'NO_RISK') return 'Sin Riesgo';
    if (riskLevel === 'LOW_RISK') return 'Riesgo Mínimo';
    if (riskLevel === 'INTOLERABLE_RISK') return 'Riesgo Intolerable';
    if (riskLevel === 'SERIOUS_RISK') return 'Riesgo Grave';
    if (riskLevel === 'POSSIBLE_RISK') return 'Riesgo Posible';

    return 'Riesgo Desconocido';
  };

  mapRiskLevelToChipColor = (
    riskLevel: string
  ): 'secondary' | 'default' | 'primary' | 'error' | 'info' | 'success' | 'warning' => {
    if (riskLevel === 'HIGH_RISK') return 'warning';
    if (riskLevel === 'NO_RISK') return 'default';
    if (riskLevel === 'LOW_RISK') return 'success';
    if (riskLevel === 'INTOLERABLE_RISK') return 'error';
    if (riskLevel === 'SERIOUS_RISK') return 'warning';
    if (riskLevel === 'POSSIBLE_RISK') return 'success';

    return 'default';
  };
}
