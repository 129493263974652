import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { LoginService } from '../services/service';

const Logout = () => {
  const loginService = new LoginService();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    loginService.logout(dispatch).finally(() => {
      navigate('/');
    });
  }, []);

  return (
    <div id="loader-container">
      <svg width="140" height="140" viewBox="0 0 280 280" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <line x1="59.9833" y1="140.333" x2="219.978" y2="139" stroke="white" strokeWidth="4" />
          <circle cx="60" cy="140" r="5" fill="white" />
          <circle cx="220" cy="139" r="5" fill="white" />
        </g>
        <path
          className="circle"
          d="M109.957 122.655L140 105.309L170.043 122.655V157.345L140 174.691L109.957 157.345V122.655Z"
          stroke="white"
          strokeWidth="4"
        />
        <circle className="circle" cx="140" cy="140" r="13" stroke="white" strokeWidth="4" />
        <circle className="circle" cx="110" cy="192" r="13" stroke="white" strokeWidth="4" />
        <circle className="circle circle_s" cx="85" cy="232" r="8" stroke="white" strokeWidth="4" />
        <circle className="circle" cx="170" cy="88" r="13" stroke="white" strokeWidth="4" />
        <circle className="circle circle_s" cx="110" cy="192" r="5" fill="white" />
        <circle className="circle circle_s" cx="185" cy="61" r="5" fill="white" />
      </svg>
      <p style={{ textAlign: 'center' }}>Saliendo de Salud 4.0</p>
    </div>
  );
};

export default Logout;
