import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import DarkLogo from '../../../../assets/arcelor_mitall.svg';
import LightLogo from '../../../../assets/arcelor_mitall_alt.svg';
import ChristmassLogo from '../../../../assets/arcelor_mitall_christmass.svg';

export type AvailableThemes = 'LIGHT' | 'DARK' | 'HIGH_CONTRAST' | 'CHRISTMASS';

export interface ThemeState {
  currentTheme: AvailableThemes;
  logo: string;
}

export const LOCAL_STORAGE_THEME_NAME = 'system-theme';

const getLogo = (currentTheme: string) => {
  if (currentTheme === 'DARK' || currentTheme === 'HIGH_CONTRAST') return DarkLogo;
  if (currentTheme === 'CHRISTMASS') return ChristmassLogo;
  return LightLogo;
};

const isChristmass = new Date().getMonth() === 11;
let initialTheme: AvailableThemes = (localStorage.getItem(LOCAL_STORAGE_THEME_NAME) as AvailableThemes) ?? 'LIGHT';

if (!isChristmass && initialTheme === 'CHRISTMASS') {
  localStorage.setItem(LOCAL_STORAGE_THEME_NAME, 'LIGHT');

  initialTheme = 'LIGHT';
}

const initialState: ThemeState = {
  currentTheme: initialTheme,
  logo: getLogo(initialTheme)
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<AvailableThemes>) => {
      const newState = {
        ...state,
        currentTheme: action.payload,
        logo: getLogo(action.payload)
      };

      localStorage.setItem(LOCAL_STORAGE_THEME_NAME, action.payload);

      return newState;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setTheme } = themeSlice.actions;

export default themeSlice.reducer;
