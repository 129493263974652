import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CampaignIcon from '@mui/icons-material/Campaign';
import DeleteIcon from '@mui/icons-material/Delete';
import HomeIcon from '@mui/icons-material/Home';
import { Button, IconButton, Paper, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';
import DataTable from '../../common/components/dataTable';
import { Notice } from '../../common/models/notice';
import { formatDate } from '../../common/utils/dates';
import { setLoading } from '../../infra/store/reducers/loading';
import { NoticeService } from './services/noticeService';
const breadcrumbItems: IBreadcrumbItem[] = [
  {
    Icon: <HomeIcon color="secondary" />,
    text: 'Home',
    path: '/'
  },
  {
    Icon: <AdminPanelSettingsIcon color="secondary" />,
    text: 'Administrador',
    path: '/administrador'
  },
  {
    Icon: <CampaignIcon color="primary" />,
    text: 'Avisos',
    path: '/administrador/avisos'
  }
];

const Notices = () => {
  const [notices, setNotices] = useState<Notice[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const service = new NoticeService();

  const handleApiResponse = (response: Notice[] | Error) => {
    if (response instanceof Error) throw response;
    setNotices(response);
  };

  const updateNotices = () => {
    dispatch(setLoading(true));
    service
      .getNotices()
      .then(handleApiResponse)
      .catch(console.error)
      .finally(() => dispatch(setLoading(false)));
  };

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Título',
      flex: 2
    },
    {
      field: 'message',
      headerName: 'Mensaje',
      flex: 4
    },
    {
      field: 'startDate',
      headerName: 'Fecha de inicio',
      flex: 3,
      renderCell: (params: GridRenderCellParams) => {
        return <Typography>{formatDate(params.row.startDate, 'Desde DD de _month_ del YYYY')}</Typography>;
      }
    },
    {
      field: 'endDate',
      headerName: 'Fecha Final',
      flex: 3,
      renderCell: (params: GridRenderCellParams) => {
        return <Typography>{formatDate(params.row.endDate, 'Hasta DD de _month_ del YYYY')}</Typography>;
      }
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const onClick = () => {
          dispatch(setLoading(true));
          service
            .archiveNoticeById(params.row.id ?? '')
            .then(() => {
              updateNotices();
            })
            .finally(() => {
              dispatch(setLoading(false));
            });
        };

        return (
          <IconButton onClick={onClick}>
            <DeleteIcon color="primary" />
          </IconButton>
        );
      }
    }
  ];

  useEffect(() => {
    updateNotices();
  }, []);

  return (
    <div className="grid w-full h-full px-2">
      <Breadcrumb items={breadcrumbItems} title="Administración de Avisos" />
      <Paper elevation={0} sx={{ mt: 3, p: 2 }}>
        <Button variant="contained" disableElevation onClick={() => navigate('/administrador/avisos/crear')}>
          Agregar Nuevo Aviso
        </Button>
      </Paper>
      <Paper elevation={0} sx={{ mt: 3, p: 2 }}>
        <DataTable title="Lista de Avisos" columns={columns} data={notices} />
      </Paper>
    </div>
  );
};
export default Notices;
