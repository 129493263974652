import React from 'react';

export interface IShowProps {
  children: JSX.Element;
  when: boolean;
}

const Show = ({ children, when }: IShowProps) => {
  return <>{when && children}</>;
};

export default Show;
