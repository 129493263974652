import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import WatchIcon from '@mui/icons-material/Watch';
import { Alert, AlertTitle, Box, Button, Paper, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import WorkerSVG from '../../../assets/factory_worker.svg';
import Show from '../../../common/components/show';
import { RealtimeWorker } from '../../../common/models/worker';
import { formatDate } from '../../../common/utils/dates';
import { setMapWorker } from '../../../infra/store/reducers/router';
import { useBatteryColor } from '../hooks/useBatteryColor';
import { useBodyTemperatureColor } from '../hooks/useBodyTemperatureColor';
import { useHeartRateColor } from '../hooks/useHeartRateColor';
import { useSituationColor } from '../hooks/useSituationColor';
import { useTypeSituation } from '../hooks/useTypeSituation';
export interface IWorkerFormProps {
  worker: RealtimeWorker;
}

const WorkerRealtime = ({ worker }: IWorkerFormProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!worker) return;
  }, [worker]);

  const handleEditWorkerSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const handleGoToWorkerMapPositionEvent = () => {
    dispatch(setMapWorker(worker));
    navigate('/monitoreo/mapa');
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        mt: 2,
        boxSizing: 'border-box',
        display: 'flex',
        justifyItems: 'start',
        justifyContent: 'start',
        width: '100%',
        flexDirection: 'column'
      }}
    >
      <Typography variant="h6" component="p">
        <strong>Información en Tiempo Real</strong>
      </Typography>
      <Show when={!worker.type}>
        <Box
          sx={{
            p: 2,
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'start',
            alignContent: 'start',
            flexDirection: 'column'
          }}
        >
          <img src={WorkerSVG} alt="Ilustración de trabajador" width="50%" style={{ alignSelf: 'center' }} />
          <Typography variant="h6" component="h2">
            Parece que aún no hay información de este trabajador en tiempo real.
          </Typography>
          <Typography variant="body1" component="h2" sx={{ mt: 2 }}>
            Esto puede ser porque el colaborador no está trabajando en este momento, aún no ha llegado su información
            del sistema o su reloj se dañó.
          </Typography>
          <Typography variant="body1" component="h2" sx={{ mt: 2 }}>
            Si el colaborador está trabajando y <strong>en unos 2 minutos</strong> máximo la información no se ha
            actualizado, te recomendados <strong>contactar al colaborador</strong>.
          </Typography>
        </Box>
      </Show>
      <Show when={!!worker.type}>
        <Box
          component="form"
          onSubmit={handleEditWorkerSubmit}
          noValidate
          sx={{
            boxSizing: 'border-box',
            display: 'flex',
            justifyItems: 'center',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column'
          }}
        >
          <Typography variant="body1" component="p" sx={{ mt: 2 }}>
            <strong>Estado Actual</strong>
          </Typography>
          <Grid2 container sx={{ mt: 1 }} columns={{ xs: 4, sm: 8, md: 12 }} spacing={{ xs: 1, md: 1 }}>
            <Grid2 xs={4}>
              <Alert
                severity="success"
                sx={{ boxSizing: 'border-box', p: 1 }}
                icon={<MyLocationIcon />}
                variant="outlined"
              >
                <AlertTitle>Ubicación</AlertTitle>
                <Typography component="p" variant="body1" sx={{ mt: 1 }}>
                  <strong>{worker.zone}</strong>
                </Typography>
              </Alert>
            </Grid2>
            <Grid2 xs={4}>
              <Alert
                severity={useSituationColor(worker.type)}
                sx={{ boxSizing: 'border-box', p: 1 }}
                variant="outlined"
              >
                <AlertTitle>Situación de Riesgo</AlertTitle>
                <Typography component="p" variant="body1" sx={{ mt: 1 }}>
                  <strong>{useTypeSituation(worker.type)}</strong>
                </Typography>
              </Alert>
            </Grid2>
            <Grid2 xs={4}>
              <Alert severity="success" sx={{ boxSizing: 'border-box', p: 1 }} variant="outlined" icon={<WatchIcon />}>
                <AlertTitle>Última Señal Recibida</AlertTitle>
                <Typography component="p" variant="body1" sx={{ mt: 1 }}>
                  {formatDate(worker.watchTimestamp, 'El día DD/MM/YYYY a las HH:mm:ss')}
                </Typography>
              </Alert>
            </Grid2>
          </Grid2>
          <Typography variant="body1" component="p" sx={{ mt: 1 }}>
            <strong>Datos del día de hoy</strong>
          </Typography>
          <Grid2 container sx={{ mt: 1 }} columns={{ xs: 4, sm: 8, md: 12 }} spacing={{ xs: 1, md: 1 }}>
            <Grid2 xs={4}>
              <Alert
                severity={useHeartRateColor(worker.heartRate)}
                sx={{ boxSizing: 'border-box', p: 1 }}
                variant="outlined"
                icon={<FavoriteBorderIcon />}
              >
                <AlertTitle>Ritmo Cardíaco</AlertTitle>
                <Typography component="p" variant="body1" sx={{ mt: 1 }}>
                  <strong>{worker.heartRate} lpm</strong>
                </Typography>
              </Alert>
            </Grid2>
            <Grid2 xs={4}>
              <Alert
                severity={useBodyTemperatureColor(worker.bodyTemperature)}
                sx={{ boxSizing: 'border-box', p: 1 }}
                variant="outlined"
                icon={<DeviceThermostatIcon />}
              >
                <AlertTitle>Temperatura corporal</AlertTitle>
                <Typography component="p" variant="body1" sx={{ mt: 1 }}>
                  <strong>{worker.bodyTemperature} &deg;C</strong>
                </Typography>
              </Alert>
            </Grid2>
            <Grid2 xs={4}>
              <Alert
                severity={useBatteryColor(worker.batteryCapacity)}
                sx={{ boxSizing: 'border-box', p: 1 }}
                variant="outlined"
                icon={<BatteryCharging60Icon />}
              >
                <AlertTitle>Batería del Reloj</AlertTitle>
                <Typography component="p" variant="body1" sx={{ mt: 1 }}>
                  <strong>{worker.batteryCapacity}%</strong>
                </Typography>
              </Alert>
            </Grid2>
            <Grid2 xs={4}>
              <Alert
                severity="info"
                sx={{ boxSizing: 'border-box', p: 1 }}
                variant="outlined"
                icon={<ElectricBoltIcon />}
              >
                <AlertTitle>Consumo Calórico</AlertTitle>
                <Typography component="p" variant="body1" sx={{ mt: 1 }}>
                  <strong>{worker.burnedCalories} kCal</strong>
                </Typography>
              </Alert>
            </Grid2>
            <Grid2 xs={4}>
              <Alert
                severity="info"
                sx={{ boxSizing: 'border-box', p: 1 }}
                variant="outlined"
                icon={<DirectionsRunIcon />}
              >
                <AlertTitle>Cantidad de pasos</AlertTitle>
                <Typography component="p" variant="body1" sx={{ mt: 1 }}>
                  <strong>{worker.dailySteps} pasos</strong>
                </Typography>
              </Alert>
            </Grid2>
            <Grid2 xs={4}>
              <Alert severity="info" sx={{ boxSizing: 'border-box', p: 1 }} variant="outlined">
                <AlertTitle>Distancia Recorrida</AlertTitle>
                <Typography component="p" variant="body1" sx={{ mt: 1 }}>
                  <strong>{worker.distanceTraveled} metros</strong>
                </Typography>
              </Alert>
            </Grid2>
          </Grid2>
          <Button
            variant="contained"
            disabled={worker.lat === 0 || worker.long === 0}
            disableElevation
            sx={{ my: 2 }}
            startIcon={<GpsFixedIcon />}
            color="info"
            onClick={(e) => {
              e.preventDefault();

              handleGoToWorkerMapPositionEvent();
            }}
          >
            Ubicar Colaborador
          </Button>
          {(worker.lat === 0 || worker.long === 0) && (
            <Typography variant="caption" component="p">
              No hay datos de coordenadas de este colaborador. <strong>Sin Señal.</strong>
            </Typography>
          )}
        </Box>
      </Show>
    </Paper>
  );
};
export default WorkerRealtime;
