export function assignChristmassVariables() {
  document.documentElement.style.setProperty('--bg-color', '#F9F4EC');
  document.documentElement.style.setProperty('--sub-bg-color', '#FFFBF5');
  document.documentElement.style.setProperty('--alter-bg-color', '#f5f5f5');
  document.documentElement.style.setProperty('--alter-grad-bg-color', '#f5f5f5');
  document.documentElement.style.setProperty('--alert-bg', '#FFE8E8');
  document.documentElement.style.setProperty('--alert-bg-green', '#EDF7ED');
  document.documentElement.style.setProperty('--alert-bg-yellow', '#FFF4E5');
  document.documentElement.style.setProperty('--alert-bg-red', '#FBECED');
  document.documentElement.style.setProperty('--text-color', 'black');
  document.documentElement.style.setProperty('--low-text-color', 'rgb(156 163 175)');
  document.documentElement.style.setProperty('--main-color', '#ff3700');
  document.documentElement.style.setProperty('--logo-color', 'rgb(255, 255, 255)');
  document.documentElement.style.setProperty('--alt-color', '#696a6d');
  document.documentElement.style.setProperty('--rs-steps-state-process', 'rgb(255, 55, 0)');
  document.documentElement.style.setProperty('--rs-steps-state-finish', 'rgb(255, 55, 0)');
  document.documentElement.style.setProperty('--login-bg', 'rgb(136, 30, 0)');
  // Set --icon-invert to an empty string or a default value if needed
  document.documentElement.style.setProperty('--icon-invert', '');
  document.documentElement.style.setProperty(
    '--icon-invert-active',
    'invert(100%) sepia(0%) saturate(2%) hue-rotate(4deg) brightness(111%) contrast(101%) !important'
  );
  document.documentElement.style.setProperty('--main-color-hover', 'rgb(240, 233, 222)');
  document.documentElement.style.setProperty('--navbar-width', '100px');
}
