/**
 * maps the role to be readable
 * @param role user role to be mapped
 * @returns user role readable in spanish
 */
export function useReadableRole(role: string): string {
  if (role === 'ADMIN') return 'Administrador';
  if (role === 'COMMON_USER') return 'Normal';

  return 'Riesgo Desconocido';
}
