import './styles/faqStyles.css';

import HomeIcon from '@mui/icons-material/Home';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import React from 'react';

import AstronautSVG from '../../assets/astronaut.svg';
import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';

const FAQ = () => {
  const breadcrumbItems: IBreadcrumbItem[] = [
    {
      Icon: <HomeIcon color="secondary" />,
      text: 'Home',
      path: '/'
    },
    {
      Icon: <LiveHelpIcon color="secondary" />,
      text: 'Ayuda',
      path: '/ayuda'
    },
    {
      Icon: <QuestionAnswerIcon color="primary" />,
      text: 'Preguntas Frecuentes',
      path: '/ayuda/faq'
    }
  ];

  const questions = [
    {
      id: 'no_signal',
      question: 'Hay una alerta que indica a un colaborador sin señal, ¿cómo lo ayudo?',
      answer: `Esto significa que su reloj se ha desconfigurado y no está
            enviando los datos de su ubicación. Para ayudarlo, deberás
            localizar al colaborador e indicarle que vaya a una zona
            exterior o de techo abierto, donde se vea el cielo donde
            pueda recibir señal de GPS. <br/><br/>
            <strong>Después de unos minutos su reloj debería volver a funcionar.</strong>`
    },
    {
      id: 'cache',
      question: `Hay un botón que dice "Borrar caché", ¿qué hace y por qué lo necesito?`,
      answer: `Este sistema usa un sistema de caché para almacenar los datos temporalmente de los colaboradores y sus datos en tiempo real.
            Esto se hace con el fin que las consultas al sistema sean los más rápidas posibles y no se pierda tiempo en la carga de datos.
            <br/><br/>
            Usa ese botón solo cuando algún dato personal del colaborador no se haya actualizado en tu sistema, de lo contrario 
            estarás recibiendo pantallas de carga innecesarias y harás el sistema más lento.`
    },
    {
      id: 'no_data',
      question: `No estoy recibiendo datos de los colaboradores, ¿qué hago?`,
      answer: `Si no estás recibiendo datos de los colaboradores, es posible
            que los relojes aún no hayan enviado los datos o un colaborador no lo 
            esté usando, espera unos minutos para recibirlos. <br/>
            Si sigues teniendo problemas puedes abrir un Ticket de Soporte para que tu problema sea resuelto
            lo antes posible.`
    }
  ];

  return (
    <div className="grid w-full h-full px-6 pt-6 pb-5">
      <Breadcrumb items={breadcrumbItems} title="Preguntas Frecuentes" />
      <div className="faq-banner">
        <img src={AstronautSVG} />
        <div className="flex flex-col justify-items-center items-center content-center justify-content-center h-full">
          <h1 className="font-bold text-color text-2xl mb-4">Te ayudaremos con tus preguntas más frecuentes</h1>
          <p className="text-color text-base">
            En esta sección podrás encontrar la respuesta a los problemas o preguntas preguntas más comunes para el uso
            de esta plataforma 😁
          </p>
          <p className="text-color text-base">
            Para usar esta sección desliza hacia abajo y verás una lista con las preguntas y sus respuestas. ✨
          </p>
          <p className="text-base italic mt-6">✨ La seguridad “Empieza conmigo y continúa con nosotros” ✨</p>
        </div>
      </div>
      {questions.map((question, index) => (
        <div id={question.id} key={index} className="faq-item">
          <h1 className="text-color font-bold italic text-lg">
            {index + 1}. {question.question}
          </h1>
          <p className="text-color text-base mt-2" dangerouslySetInnerHTML={{ __html: question.answer }}></p>
        </div>
      ))}
    </div>
  );
};

export default FAQ;
