export enum NoticeSeverity {
  'error' = 'error',
  'warning' = 'warning',
  'info' = 'info',
  'success' = 'success'
}
export enum NoticeDeleted {
  ACTIVE,
  ARCHIVED
}
export type Notice = {
  id?: string;
  deleted?: NoticeDeleted;
  title: string;
  message: string;
  severity: NoticeSeverity;
  startDate: string;
  endDate: string;
};
