import { HeatmapLayer } from '@deck.gl/aggregation-layers';
import { Layer, LayersList } from '@deck.gl/core';
import { BitmapBoundingBox, BitmapLayer, PolygonLayer } from '@deck.gl/layers';
import React, { useState } from 'react';

import MapaImg from '../../../assets/mapa.png';
import ArcelorMap from '../../../common/components/companyMap';
import { Record } from '../../../common/models/record';
import { Zone } from '../../../common/models/zone';
import { useRecordsAsHeatpoints, useZonesAsPolygonColors, useZonesAsPolygons } from '../hooks/useDeckGL';

export interface IMapProps {
  records: Record[];
  zones: Zone[];
}

const defaultArcelorMapBounds: number[] & BitmapBoundingBox = [
  -83.75336377460397, 10.24693461107274, -83.74043911751508, 10.256144991138214
];

const Map = ({ zones, records }: IMapProps) => {
  const sortedRecords = records.filter((record) => record.lat != 0 && record.long != 0);

  const bitmapLayer: Layer = new BitmapLayer({
    id: 'bitmap-layer',
    bounds: defaultArcelorMapBounds,
    image: MapaImg
  });

  const polygonLayer = new PolygonLayer({
    id: 'polygon-layer',
    data: useZonesAsPolygons(zones),
    getFillColor: (d: any) => useZonesAsPolygonColors(d.riskLevel) as any,
    pickable: true,
    stroked: true,
    filled: true,
    wireframe: true,
    extruded: true,
    lineWidthMinPixels: 1,
    getPolygon: (d) => d.contour,
    getElevation: () => 10,
    getLineColor: [80, 80, 80, 0],
    getLineWidth: 1,
    blending: true
  });

  const heatpoints = useRecordsAsHeatpoints(sortedRecords);

  const heatmapLayer: Layer = new HeatmapLayer({
    id: 'heat-map-layer',
    data: heatpoints,
    getPosition: (d) => d.coordinates,
    getWeight: (d) => d.weight,
    aggregation: 'SUM'
  });

  const [layers] = useState<LayersList>([bitmapLayer, heatmapLayer, polygonLayer]);

  return <ArcelorMap layers={layers} />;
};

export default Map;
