import { Environment } from './environment';

export const productionEnvironment: Environment = {
  authEndpoint: 'https://2ifnz9rp67.execute-api.us-west-2.amazonaws.com/dev',
  zoneEndpoint: 'https://fl504j4nu5.execute-api.us-west-2.amazonaws.com/dev',
  userEndpoint: 'https://2ifnz9rp67.execute-api.us-west-2.amazonaws.com/dev',
  workerEndpoint: 'https://92ehvob2x9.execute-api.us-west-2.amazonaws.com/dev',
  healthEndpoint: 'https://6x3mn0dab3.execute-api.us-west-2.amazonaws.com/dev',
  adminEndpoint: 'https://7w4dhx9rwe.execute-api.us-west-2.amazonaws.com/dev',
  recordWebsocketEndpoint:
    'wss://aml47east5.execute-api.us-west-2.amazonaws.com/prod-am-salud-notification-websocket-stage/'
};
