import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import {
  AuthenticationResult,
  LoginAPI,
  LoginProps,
  RecoverPasswordProps,
  RespondChallengeProps
} from '../../../infra/api/login-api';
import { UserAPI } from '../../../infra/api/user-api';
import { environment } from '../../../infra/environment/environment';
import { ChallengeData, setTokens } from '../../../infra/store/reducers/login';

export class LoginService {
  private api: LoginAPI;

  private userApi: UserAPI;

  constructor() {
    this.api = new LoginAPI(environment, 'Auth');
    this.userApi = new UserAPI(environment);
  }

  /**
   * Use the api to login
   * @param props
   * @returns
   */
  login = (props: LoginProps) => {
    return this.api.login(props);
  };

  /**
   * Request a code to change password
   * @param props
   * @returns
   */
  requestCode = (email: string) => {
    return this.api.requestCode(email);
  };

  /**
   * Request an user
   * @param {string} email
   * @returns
   */
  getUser = (email: string) => {
    return this.userApi.getUser(email);
  };

  /**
   * Change the password using the code
   * @param props
   * @returns
   */
  recoverPassword = (email: string, password: string, code: string) => {
    const props: RecoverPasswordProps = {
      email,
      password,
      code
    };
    return this.api.recoverPassword(props);
  };

  /**
   * Use the api to login
   * @param props
   * @returns
   */
  respondChallenge = (newPassword: string, challengeData: ChallengeData) => {
    console.log(newPassword, challengeData);

    const props: RespondChallengeProps = {
      newPassword: newPassword,
      challengeName: challengeData.challengeName,
      challengeParameters: challengeData.challengeParameters,
      session: challengeData.session,
      username: challengeData.challengeParameters.USER_ID_FOR_SRP
    };
    return this.api.respondChallenge(props);
  };

  /**
   * Asign the tokens based on the result
   * @param dispatch
   * @param param1
   */
  assignTokens = (dispatch: Dispatch<AnyAction>, tokens: AuthenticationResult) => {
    const expiresIn = new Date();
    expiresIn.setSeconds(expiresIn.getSeconds() + tokens.ExpiresIn);
    dispatch(
      setTokens({
        accessToken: tokens.AccessToken,
        idToken: tokens.IdToken,
        refreshToken: tokens.RefreshToken
      })
    );
    Cookies.set('access-token', tokens.AccessToken, { expires: expiresIn });
    Cookies.set('refresh-token', tokens.RefreshToken, { expires: expiresIn });
    Cookies.set('id-token', tokens.IdToken, { expires: expiresIn });
  };

  /**
   * Logout
   * @param dispatch
   * @param param1
   */
  logout = async (dispatch: Dispatch<AnyAction>) => {
    dispatch(
      setTokens({
        accessToken: undefined,
        idToken: undefined,
        refreshToken: undefined
      })
    );

    localStorage.clear();

    Cookies.remove('access-token');
    Cookies.remove('refresh-token');
    Cookies.remove('id-token');
  };
}
