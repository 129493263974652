import '../login.css';

import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';

import ArcelorMittalLogo from '../../../assets/arcelor_mitall.svg';
import Show from '../../../common/components/show';
import ForgotPassword from '../components/forgotPassword';
import Login from '../components/login';
import RespondChallenge from '../components/respondChallenge';

export type LoginPageWindows = 'LOGIN' | 'RESPOND_CHALLENGE' | 'FORGOT_PASSWORD';

const LoginPage = () => {
  const [window, setWindow] = useState<LoginPageWindows>('LOGIN');

  return (
    <div className="flex-container">
      <Box className="left-box">
        <Show when={window == 'LOGIN'}>
          <Login setWindow={setWindow} />
        </Show>
        <Show when={window == 'RESPOND_CHALLENGE'}>
          <RespondChallenge />
        </Show>
        <Show when={window == 'FORGOT_PASSWORD'}>
          <ForgotPassword setWindow={setWindow} />
        </Show>
      </Box>
      <Box className="right-box">
        <Box>
          <img src={ArcelorMittalLogo} alt="Arcelor Mittal" style={{ width: '100%' }} />
          <Typography variant="h5" component="p" style={{ color: 'white', textAlign: 'center', marginTop: '0.5rem' }}>
            <strong>Salud 4.0</strong>
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default LoginPage;
