import { WorkerAPI } from '../../../infra/api/worker-api';
import { environment } from '../../../infra/environment/environment';

export class WorkerService {
  private api: WorkerAPI;

  constructor() {
    this.api = new WorkerAPI(environment);
  }

  getWorkers = () => {
    return this.api.getAll();
  };

  clearCache = () => {
    this.api.clearCache();

    return this.getWorkers();
  };
}
