import AccessibilityIcon from '@mui/icons-material/Accessibility';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew'; // Icono para Alto Contraste
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Brightness4Icon from '@mui/icons-material/Brightness4'; // Icono para Modo Oscuro
import Brightness7Icon from '@mui/icons-material/Brightness7'; // Icono para Modo Claro
import HomeIcon from '@mui/icons-material/Home';
import ParkIcon from '@mui/icons-material/Park';
import { Alert, AlertTitle, Paper, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';
import { AppState } from '../../infra/store';
import { AvailableThemes, setTheme } from '../../infra/store/reducers/theme';

const breadcrumbItems: IBreadcrumbItem[] = [
  {
    Icon: <HomeIcon color="secondary" />,
    text: 'Home',
    path: '/'
  },
  {
    Icon: <AccountBoxIcon color="secondary" />,
    text: 'Mi Usuario',
    path: '/perfil'
  },
  {
    Icon: <AccessibilityIcon color="primary" />,
    text: 'Accesibilidad',
    path: '/perfil/accesibilidad'
  }
];

const Accesibility = () => {
  const [isChristmass] = useState(new Date().getMonth() === 11);
  const currentTheme = useSelector((state: AppState) => state.themeReducer.currentTheme);
  const dispatch = useDispatch();

  const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    dispatch(setTheme(value as AvailableThemes));
  };

  return (
    <div className="grid w-full h-full px-2">
      <Breadcrumb items={breadcrumbItems} title="Opciones de Accesibilidad" />
      <Paper sx={{ mt: 3, p: 2, boxSizing: 'border-box' }} elevation={0}>
        <Typography variant="h5" component="h2">
          <strong>Accesibilidad</strong>
        </Typography>
        <Typography variant="body1" component="p" sx={{ my: 2 }}>
          Un menú de accesibilidad en la interfaz de usuario (UI) proporciona opciones personalizadas para adaptar la
          apariencia y la interacción de una aplicación o sitio web, haciendo que sea más accesible para usuarios con
          diversas capacidades.
        </Typography>

        <Alert severity="info" sx={{ my: 2 }}>
          <Typography variant="body2" component="p">
            <strong>Nota: </strong> Si necesitas otros modos de accesibilidad puedes solicitarlo a IT.
          </Typography>
        </Alert>
        <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
          Colores del Sistema
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 2 }}>
          Personaliza la apariencia del sistema según tus preferencias de accesibilidad.
        </Typography>

        <RadioGroup
          aria-label="mode-color"
          name="mode-color"
          value={currentTheme}
          onChange={handleModeChange}
          sx={{ mt: 2 }}
        >
          <FormControlLabel
            value="LIGHT"
            control={<Radio />}
            label={
              <div className="flex items-center">
                <Brightness7Icon />
                <span className="ml-2">Modo Claro</span>
              </div>
            }
          />
          <FormControlLabel
            value="DARK"
            control={<Radio />}
            label={
              <div className="flex items-center">
                <Brightness4Icon />
                <span className="ml-2">Modo Oscuro</span>
              </div>
            }
          />
          <FormControlLabel
            value="HIGH_CONTRAST"
            control={<Radio />}
            label={
              <div className="flex items-center">
                <AccessibilityNewIcon />
                <span className="ml-2">Alto Contraste</span>
              </div>
            }
          />
          {isChristmass && (
            <FormControlLabel
              value="CHRISTMASS"
              control={<Radio />}
              label={
                <div className="flex items-center">
                  <ParkIcon />
                  <span className="ml-2">Navidad</span>
                </div>
              }
            />
          )}
        </RadioGroup>
        <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 1 }}>
          Descripción de los modos
        </Typography>
        <Grid2 container sx={{ my: 2 }} columns={{ xs: 4, sm: 8, md: 12 }} spacing={{ xs: 2, md: 3 }}>
          <Grid2 xs={4}>
            <Alert color="info">
              <AlertTitle>
                <Typography variant="h6" component="h3">
                  Modo Claro
                </Typography>
              </AlertTitle>
              <Typography variant="body2" component="p">
                Utiliza colores claros y brillantes para mejorar la legibilidad y reducir la fatiga visual.
              </Typography>
            </Alert>
          </Grid2>

          <Grid2 xs={4}>
            <Alert color="info">
              <AlertTitle>
                <Typography variant="h6" component="h3">
                  Modo Oscuro
                </Typography>
              </AlertTitle>
              <Typography variant="body2" component="p">
                Opta por colores oscuros para reducir la intensidad luminosa, especialmente en entornos de poca luz.
              </Typography>
            </Alert>
          </Grid2>
          <Grid2 xs={4}>
            <Alert color="info">
              <AlertTitle>
                <Typography variant="h6" component="h3">
                  Alto Contraste
                </Typography>
              </AlertTitle>
              <Typography variant="body2" component="p">
                Aumenta la diferencia entre el texto y el fondo para facilitar la lectura a personas con discapacidades
                visuales.
              </Typography>
            </Alert>
          </Grid2>
          {isChristmass && (
            <Grid2 xs={4}>
              <Alert color="success" icon={<ParkIcon />}>
                <AlertTitle>
                  <Typography variant="h6" component="h3">
                    Modo Navideño
                  </Typography>
                </AlertTitle>
                <Typography variant="body2" component="p">
                  Solo durante navidad estará disponible este modo navideño. ¡Feliz Navidad!
                </Typography>
              </Alert>
            </Grid2>
          )}
        </Grid2>
      </Paper>
    </div>
  );
};
export default Accesibility;
