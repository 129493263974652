import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HomeIcon from '@mui/icons-material/Home';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import React from 'react';

import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';
import UserForm from './components/userForm';

export const USER_PREVIOUS_SCREEN = '/administrador/usuarios';

const breadcrumbItems: IBreadcrumbItem[] = [
  {
    Icon: <HomeIcon color="secondary" />,
    text: 'Home',
    path: '/'
  },
  {
    Icon: <AdminPanelSettingsIcon color="secondary" />,
    text: 'Administrador',
    path: '/administrador'
  },
  {
    Icon: <PersonAddIcon color="primary" />,
    text: 'Crear Usuario',
    path: '/administrador/usuario/crear'
  }
];

const CreateUser = () => {
  return (
    <div>
      <Breadcrumb items={breadcrumbItems} title="Crear Usuario" />
      <UserForm />
    </div>
  );
};
export default CreateUser;
