import EngineeringIcon from '@mui/icons-material/Engineering';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import HomeIcon from '@mui/icons-material/Home';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';
import { SmartWatch } from '../../common/models/smartWatch';
import { RealtimeWorker, Worker } from '../../common/models/worker';
import { AppState } from '../../infra/store';
import { setLoading } from '../../infra/store/reducers/loading';
import WorkerForm from './components/workerForm';
import WorkerRealtime from './components/workerRealtime';
import { WorkerService } from './services/service';

const breadcrumbItems: IBreadcrumbItem[] = [
  {
    Icon: <HomeIcon color="secondary" />,
    text: 'Home',
    path: '/'
  },
  {
    Icon: <GpsFixedIcon color="secondary" />,
    text: 'Monitoreo',
    path: '/monitoreo'
  },
  {
    Icon: <EngineeringIcon color="secondary" />,
    text: 'Colaboradores',
    path: '/monitoreo/colaboradores'
  }
];

export const WORKERS_PREVIOUS_SCREEN = '/monitoreo/colaboradores';

const WorkerDetail = () => {
  const [isComponentReady, setComponentReady] = useState<boolean>(false);
  const [worker, setWorker] = useState<Worker>(undefined);
  const [title, setTitle] = useState<string>('');
  const [items, setItems] = useState<IBreadcrumbItem[]>(breadcrumbItems);
  const [realtimeWorker, setRealtimeWorker] = useState<RealtimeWorker>(undefined);
  const [unusedSmartwatches, setUnusedSmartwatches] = useState<SmartWatch[]>([]);

  const records = useSelector((state: AppState) => state.websocketReducer.records);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const service = new WorkerService();
  const id = useSelector((state: AppState) => state.routerReducer.workerId);

  useEffect(() => {
    if (!worker) return;

    setRealtimeWorker(service.useRealtimeWorker(worker, records));
  }, [records]);

  const handleApiResponse = (response: Worker | Error) => {
    if (response instanceof Error) throw response;
    const item: IBreadcrumbItem = {
      Icon: <MedicalInformationIcon color="primary" />,
      text: response.fullname,
      path: `/monitoreo/colaborador#id=${response.id}`
    };

    setWorker(response);
    setRealtimeWorker(service.useRealtimeWorker(response, records));
    setItems([...items, item]);
    setTitle(response.fullname);
  };

  const handleSmartwatchesApiResponse = (response: SmartWatch[] | Error) => {
    if (response instanceof Error) throw response;

    setUnusedSmartwatches(response);
    setComponentReady(true);
  };

  useEffect(() => {
    if (!!worker) return;
    if (!id) navigate(WORKERS_PREVIOUS_SCREEN);

    dispatch(setLoading(true));
    service
      .getWorkerById(id)
      .then((result) => {
        handleApiResponse(result);

        service
          .getUnusedSmartwatches()
          .then(handleSmartwatchesApiResponse)
          .catch(() => navigate(WORKERS_PREVIOUS_SCREEN));
      })
      .catch(() => navigate(WORKERS_PREVIOUS_SCREEN))
      .finally(() => dispatch(setLoading(false)));
  }, []);

  if (!isComponentReady) return <></>;

  return (
    <div className="grid w-full h-full px-2">
      <Breadcrumb items={items} title={title} />
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={{ xs: 1, md: 2 }} sx={{ mt: 3 }}>
        <Grid md={4} xs={8}>
          <WorkerForm worker={worker} smartwatches={unusedSmartwatches} />
        </Grid>
        <Grid xs={8}>
          <WorkerRealtime worker={realtimeWorker} />
        </Grid>
      </Grid>
    </div>
  );
};
export default WorkerDetail;
