import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import CircularLoading from '../../common/components/circularLoading';
import LoginPage from '../auth/pages/loginPage';
import Logout from '../auth/pages/logout';
import Dashboard from '../dashboard';
import ProtectedRoutes from './components/protectedRoutes';

const MainRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          caseSensitive
          path="/logout"
          element={
            <Suspense fallback={<CircularLoading text="Cargando" />}>
              <Logout />
            </Suspense>
          }
        />
        <Route
          caseSensitive
          path="/login"
          element={
            <Suspense fallback={<CircularLoading text="Cargando" />}>
              <LoginPage />
            </Suspense>
          }
        />
        <Route path="/*" element={<ProtectedRoutes Component={Dashboard} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MainRouter;
