import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HomeIcon from '@mui/icons-material/Home';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Avatar, Box, Button, Chip, IconButton } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';
import DataTable from '../../common/components/dataTable';
import { User } from '../../common/models/user';
import { setLoading } from '../../infra/store/reducers/loading';
import { setUserId } from '../../infra/store/reducers/router';
import { useColoredRole } from './hooks/useColoredRole';
import { useReadableRole } from './hooks/useReadableRole';
import { UserService } from './services/userService';

const breadcrumbItems: IBreadcrumbItem[] = [
  {
    Icon: <HomeIcon color="secondary" />,
    text: 'Home',
    path: '/'
  },
  {
    Icon: <AdminPanelSettingsIcon color="secondary" />,
    text: 'Administrador',
    path: '/administrador'
  },
  {
    Icon: <PeopleAltIcon color="primary" />,
    text: 'Usuarios',
    path: '/administrador/usuarios'
  }
];

const Users = () => {
  const [users, setUsers] = useState<User[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const service = new UserService();

  const columns: GridColDef[] = [
    {
      field: 'profilePicture',
      headerName: '',
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Avatar src={params.row.profilePicture} />
          </Box>
        );
      }
    },
    {
      field: 'name',
      headerName: 'Nombre Completo',
      flex: 4
    },
    {
      field: 'job',
      headerName: 'Cargo',
      flex: 4
    },
    {
      field: 'role',
      headerName: 'Tipo Usuario',
      sortable: true,
      flex: 4,
      renderCell: (params: GridRenderCellParams) => {
        return <Chip label={useReadableRole(params.row.role)} color={useColoredRole(params.row.role)} />;
      }
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const onClick = () => {
          dispatch(setUserId(params.row.email));
          navigate(`/administrador/usuario`);
        };

        return (
          <IconButton onClick={onClick}>
            <VisibilityIcon color="primary" />
          </IconButton>
        );
      }
    }
  ];

  const handleApiResponse = (response: User[] | Error) => {
    if (response instanceof Error) throw response;
    setUsers(response);
  };

  useEffect(() => {
    dispatch(setLoading(true));
    service
      .getUsers()
      .then(handleApiResponse)
      .catch(console.error)
      .finally(() => dispatch(setLoading(false)));
  }, []);

  return (
    <div className="grid w-full h-full px-2">
      <Breadcrumb items={breadcrumbItems} title="Administración de Usuarios" />
      <Button
        sx={{ mt: 2 }}
        variant="contained"
        disableElevation
        onClick={() => navigate('/administrador/usuario/crear')}
      >
        Agregar Nuevo Usuario
      </Button>
      <DataTable title="Lista de Usuarios" columns={columns} data={users} />
    </div>
  );
};
export default Users;
