import { HealthAPI } from '../../../infra/api/health-api';
import { environment } from '../../../infra/environment/environment';

export class MachinesService {
  private api: HealthAPI;

  constructor() {
    this.api = new HealthAPI(environment);
  }

  getMachines = () => {
    return this.api.getMachines();
  };
}
