import Drawer from '@mui/material/Drawer';

import QAListItems from '../../dashboard/components/listItems';

export interface IMobileSidebar {
  isOpen: boolean;
  onClose: () => void;
}

const MobileSidebar = ({ isOpen, onClose }: IMobileSidebar) => {
  return (
    <Drawer
      variant="temporary"
      anchor="left"
      open={isOpen}
      ModalProps={{
        BackdropProps: {
          onClick: onClose
        }
      }}
    >
      <QAListItems />
    </Drawer>
  );
};

export default MobileSidebar;
