import HomeIcon from '@mui/icons-material/Home';
import InsightsIcon from '@mui/icons-material/Insights';
import SummarizeIcon from '@mui/icons-material/Summarize';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, Chip, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';
import DataTable from '../../common/components/dataTable';
import { Report } from '../../common/models/report';
import { formatDate } from '../../common/utils/dates';
import { setLoading, setLoadingText } from '../../infra/store/reducers/loading';
import { setReportId } from '../../infra/store/reducers/router';
import { ReportService } from './services/reportService';
const breadcrumbItems: IBreadcrumbItem[] = [
  {
    Icon: <HomeIcon color="secondary" />,
    text: 'Home',
    path: '/'
  },
  {
    Icon: <InsightsIcon color="secondary" />,
    text: 'Estadísticas',
    path: '/estadisticas'
  },
  {
    Icon: <SummarizeIcon color="primary" />,
    text: 'Reportes',
    path: '/estadisticas/reportes'
  }
];

const Reports = () => {
  const [reports, setReports] = useState<Report[]>([]);
  const [pickedDate, setPickedDate] = useState<Dayjs | null>(dayjs(formatDate(new Date().toISOString(), 'YYYY-MM-DD')));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const service = new ReportService();

  const columns: GridColDef[] = [
    {
      field: 'creationDate',
      headerName: 'Fecha',
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        return formatDate(params.row.creationDate, '_month_ de YYYY');
      }
    },
    {
      field: 'zone',
      headerName: 'Zona',
      flex: 2
    },
    {
      field: 'tempRange',
      headerName: 'Rango de Temperaturas',
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title="Indica el rango de temperaturas">
            <Chip
              label={`${params.row.temperature.minRange.toFixed(2)}\u{00B0} / ${params.row.temperature.maxRange.toFixed(2)}\u{00B0}`}
              color="info"
            />
          </Tooltip>
        );
      }
    },
    {
      field: 'tempHeart',
      headerName: 'Rango de Latidos por minuto',
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title="Indica el rango de latidos por minuto en la zona">
            <Chip label={`${params.row.heartRate.minRange} lpm / ${params.row.heartRate.maxRange} lpm`} color="error" />
          </Tooltip>
        );
      }
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const onClick = () => {
          dispatch(setReportId(params.row.id));
          navigate(`/estadisticas/reporte`);
        };

        return (
          <IconButton onClick={onClick}>
            <VisibilityIcon color="primary" />
          </IconButton>
        );
      }
    }
  ];

  const handleApiResponse = (response: Report[] | Error) => {
    if (response instanceof Error) throw response;
    setReports(response);
  };

  useEffect(() => {
    const prevMonth = new Date();

    prevMonth.setMonth(prevMonth.getMonth() - 1);

    dispatch(setLoading(true));
    dispatch(setLoadingText('Cargando Reportes'));
    service
      .getReportsByDate(prevMonth.toISOString())
      .then(handleApiResponse)
      .catch(console.error)
      .finally(() => {
        dispatch(setLoading(false));
      });
  }, []);

  const handleRequestButton = () => {
    dispatch(setLoading(true));
    service
      .getReportsByDate(pickedDate.toISOString())
      .then(handleApiResponse)
      .catch(() => navigate('/estadisticas'))
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <div className="grid w-full h-full px-2">
      <Breadcrumb items={breadcrumbItems} title="Buscar Reportes por Mes" />

      <Paper elevation={0} sx={{ mt: 2, p: 2 }}>
        <Typography sx={{ mb: 1 }} variant="h6" component="p">
          <strong>Buscar Registros: </strong>
        </Typography>
        <Box
          sx={{
            mt: 3,
            display: 'flex',
            gap: '1rem'
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Fecha" value={pickedDate} onChange={(e) => setPickedDate(e)} />
          </LocalizationProvider>
          <Button variant="contained" disableElevation={true} onClick={handleRequestButton}>
            Buscar
          </Button>
        </Box>
      </Paper>
      <Paper elevation={0} sx={{ p: 2, mt: 2 }}>
        <DataTable title="Lista de Reportes" columns={columns} data={reports} />
      </Paper>
    </div>
  );
};
export default Reports;
