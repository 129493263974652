import { LineChart } from '@mui/x-charts/LineChart';
import React from 'react';

export type HistogramProps = {
  x: Array<number | Date>;
  y: Array<number>;
  isTimeBased?: boolean;
  xValueFormatter?: (t: any) => string;
  yValueFormatter?: (t: any) => string;
};

const Histogram = ({ x, y, isTimeBased, xValueFormatter, yValueFormatter }: HistogramProps) => {
  return (
    <LineChart
      xAxis={[{ data: x, scaleType: !!isTimeBased ? 'time' : 'linear', valueFormatter: xValueFormatter }]}
      series={[{ data: y, valueFormatter: yValueFormatter }]}
      height={350}
      colors={['rgba(231, 68, 33, 0.8)']}
    />
  );
};
export default Histogram;
