import { IconLayer, ScatterplotLayer } from '@deck.gl/layers';
import { SimpleMeshLayer } from '@deck.gl/mesh-layers';
import { OBJLoader } from '@loaders.gl/obj';
import { Dispatch } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';

import { RecordTypes } from '../../../common/models/record';
import { RealtimeWorker } from '../../../common/models/worker';
import { Zone } from '../../../common/models/zone';
import { setWorkerId } from '../../../infra/store/reducers/router';

export interface DeckGLInfo {
  tooltip: string;
  coordinates: number[];
  type: string;
}

/**
 * Map the zones to polygons to be used by deck.gl
 * @returns
 */
export function useZonesAsPolygons(zones: Zone[]): Array<any> {
  return zones.map((zone) => ({
    contour: zone.polygon.map((coordinate) => [coordinate.long, coordinate.lat]),
    riskLevel: zone.riskLevel,
    tooltip: zone.name
  }));
}

/**
 * Map the workers to Icon Markers
 * @param workers
 * @returns
 */
export function mapWorkersToMarker(workers: RealtimeWorker[]): Array<any> {
  return workers
    .filter((worker) => !!worker.lat && !!worker.long)
    .map((worker) => ({
      tooltip: worker.fullname,
      coordinates: [worker.long, worker.lat, 0],
      type: worker.type
    }));
}

/**
 * Map the workers to Meshes (Person 3D Object)
 * @param workers
 * @returns
 */
export function mapWorkersToMesh(workers: RealtimeWorker[]): Array<any> {
  return workers
    .filter((worker) => !!worker.lat && !!worker.long)
    .map((worker) => ({
      tooltip: worker.fullname,
      coordinates: [worker.long, worker.lat, worker.type === RecordTypes.NONE ? 0 : 2],
      type: worker.type,
      angle: 0,
      id: worker.id
    }));
}

/**
 * Map the workers to DeckGL Information
 * @param workers
 * @returns
 */
export function useWorkersAsDeckGLInfo(workers: RealtimeWorker[]): Array<DeckGLInfo> {
  return workers
    .filter(
      (worker) =>
        !!worker.lat && !!worker.long && (worker?.type === RecordTypes.SOS || worker?.type === RecordTypes.MAN_DOWN)
    )
    .map((worker) => ({
      tooltip: worker.fullname,
      coordinates: [worker.long, worker.lat, 1],
      type: worker.type
    }));
}

/**
 * Map the zones to its respective colors
 * @param riskLevel the current risk level of a zone
 * @returns the color that represents the risk level of a zone
 */
export function useZonesAsPolygonColor(riskLevel: string): Array<number> {
  if (riskLevel === 'HIGH_RISK') return [252, 211, 77, 80];
  if (riskLevel === 'LOW_RISK') return [5, 180, 138, 80];
  if (riskLevel === 'INTOLERABLE_RISK') return [227, 0, 0, 80];
  if (riskLevel === 'SERIOUS_RISK') return [255, 158, 44, 80];
  if (riskLevel === 'POSSIBLE_RISK') return [138, 232, 81, 80];

  return [196, 196, 196, 150];
}

/**
 * Map the worker starte to its respective color
 * @param type the current state of the worker
 * @returns the color that represents the state of the worker
 */
export function mapRecordToMarkerColor(type: string): Array<number> {
  const RED_COLOR = [169, 50, 38];
  const YELLOW_COLOR = [255, 212, 73];
  const GREEN_COLOR = [23, 165, 137];

  if (type === RecordTypes.SOS) return RED_COLOR;
  if (type === RecordTypes.ANORMAL_HEART_RATE) return YELLOW_COLOR;
  if (type === RecordTypes.ANORMAL_TEMPERATURE) return YELLOW_COLOR;
  if (type === RecordTypes.INSUFFICIENT_PERMISSIONS) return YELLOW_COLOR;
  if (type === RecordTypes.LOW_HEART_RATE) return YELLOW_COLOR;
  if (type === RecordTypes.MAN_DOWN) return RED_COLOR;

  return GREEN_COLOR;
}

/**
 * Map the realtime workers as 2D Objects
 * @param workers the realtime workers array
 * @returns workers as a 2D Icon Layer
 */
export function useIconLayer(workers: RealtimeWorker[]): IconLayer {
  return new IconLayer({
    id: 'icon-layer',
    data: mapWorkersToMarker(workers),
    iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
    iconMapping: {
      marker: {
        x: 0,
        y: 0,
        width: 128,
        height: 128,
        anchorY: 128,
        mask: true
      }
    },
    pickable: true,
    sizeScale: 5.5,
    getPosition: (d) => d.coordinates,
    getSize: () => 5.5,
    getIcon: () => 'marker',
    onHover: () => {},
    getColor: (d) => mapRecordToMarkerColor(d.type) as any
  });
}

/**
 * Maps the realtime workers as 3D Objects
 * @param workers the realtime workers array
 * @param navigate the current navigation function
 * @param dispatch the current dispatcher
 * @returns workers as a simple mesh layer (3D Objects)
 */
export function useMeshLayer(
  workers: RealtimeWorker[],
  navigate: NavigateFunction,
  dispatch: Dispatch<any>
): SimpleMeshLayer {
  return new SimpleMeshLayer({
    id: 'mesh-layer',
    data: mapWorkersToMesh(workers),
    getColor: (d) => mapRecordToMarkerColor(d.type) as any,
    getOrientation: (d) => [0, Math.random() * 180, d.angle],
    getPosition: (d) => d.coordinates,
    mesh: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/humanoid_quad.obj',
    sizeScale: 1.05,
    loaders: [OBJLoader],
    pickable: true,
    onClick: (d) => {
      dispatch(setWorkerId(d.object.id));
      navigate('/monitoreo/colaborador');
    }
  });
}

/**
 * map the workers to scatter plot layer
 * @param workers the realtime workers array
 * @returns the workers as scatters layer
 */
export function useScatterplotLayer(workers: RealtimeWorker[]): ScatterplotLayer {
  return new ScatterplotLayer({
    id: 'scatter-layer',
    data: useWorkersAsDeckGLInfo(workers),
    pickable: true,
    opacity: 0.4,
    stroked: true,
    filled: true,
    radiusScale: 4,
    radiusMinPixels: 1,
    radiusMaxPixels: 100,
    lineWidthMinPixels: 1,
    getPosition: (d) => d.coordinates,
    getRadius: () => 7,
    getFillColor: () => [227, 0, 0, 80],
    getLineColor: () => [227, 0, 0, 80]
  });
}
