import { ReportType } from '../../../common/models/report';
import { HealthAPI } from '../../../infra/api/health-api';
import { ZoneAPI } from '../../../infra/api/zone-api';
import { environment } from '../../../infra/environment/environment';

export class ReportService {
  private api: HealthAPI;

  private zonesApi: ZoneAPI;

  constructor() {
    this.api = new HealthAPI(environment);
    this.zonesApi = new ZoneAPI(environment);
  }

  getYesterdayReports = () => {
    const yesterday = new Date();
    const type = ReportType.DAILY;

    yesterday.setDate(yesterday.getDate() - 1);

    return this.api.getReportFiltered(type, yesterday.toISOString());
  };

  getZones = () => {
    return this.zonesApi.getAll();
  };

  getZoneById = (id: string) => {
    return this.zonesApi.getById(id);
  };
}
