import { API } from '../../common/libs/api';
import { User, UserRoles } from '../../common/models/user';
import { Environment } from '../environment/environment';

export const CURRENT_USER_LOCAL_STORAGE = 'current-user';

export class UserAPI extends API {
  private defaultPP: string = 'https://gravatar.com/userimage/243287559/6dde79cf202c8e4b52fc1c53f6423a6b.jpeg?size=256';

  constructor(env: Environment) {
    super(env, 'User');
  }

  /**
   * Get all the users. This required admin permissions in the backend
   * @param props
   */
  public async getAll() {
    try {
      const response = await this.get<User[]>('/users', {});

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  /**
   * Get an specific user. If non admin, user can not request other users.
   * @param props
   */
  public async getUser(email: string) {
    try {
      const currentUser = UserAPI.getCurrentUser();
      if (!!currentUser && email === currentUser.email) {
        return currentUser ?? ({} as User);
      }

      const response = await this.get<User>(`/user?email=${email}`);

      return response.message;
    } catch (error) {
      console.error(error);
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  /**
   * Get an specific user. If non admin, user can not request other users.
   * @param props
   */
  public async editUser(user: User) {
    try {
      const currentUser = UserAPI.getCurrentUser();

      const response = await this.put<User>(`/user`, {
        id: user.id,
        role: user.role,
        name: user.name,
        email: user.email,
        job: user.job,
        profilePicture: user.profilePicture
      });

      if (currentUser.id === user.id) {
        localStorage.setItem(CURRENT_USER_LOCAL_STORAGE, JSON.stringify(user));
      }

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  /**
   * Get an specific user. If non admin, user can not request other users.
   * @param props
   */
  public async createUser(user: User) {
    try {
      const response = await this.post<User>(`/user`, {
        id: user.id,
        role: user.role,
        name: user.name,
        email: user.email,
        phones: [], // Delayed to the next release
        profilePicture: this.defaultPP, // Delayed to the next release
        job: user.job
      });

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  /**
   * Takes out the user from local storage. Returns the logged user
   * @returns
   */
  public static getCurrentUser(): User {
    const userString = localStorage.getItem(CURRENT_USER_LOCAL_STORAGE);

    return JSON.parse(userString);
  }

  /**
   * Determines if the current user logged is admin
   * @returns
   */
  public static isCurrentUserAdmin(): boolean {
    return UserAPI.getCurrentUser().role === UserRoles.ADMIN;
  }
}
