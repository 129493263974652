import { devEnvironment } from './environment.dev';
import { productionEnvironment } from './environment.production';

export type Environment = {
  authEndpoint: string;
  zoneEndpoint: string;
  userEndpoint: string;
  workerEndpoint: string;
  healthEndpoint: string;
  adminEndpoint: string;
  recordWebsocketEndpoint: string;
};

// As we are going to deploy to S3. There are no env variables. We Will use manual setup.
const currentEnvironmentTag = process.env.REACT_APP_ENVIRONMENT ?? 'development';

if (currentEnvironmentTag === 'uat') throw new Error('UAT ENV IS NOT SUPPORTED.');

const environments: any = {
  development: devEnvironment,
  production: productionEnvironment
};

const environment: Environment = environments[currentEnvironmentTag];

if (!environment) {
  console.error(`Current Environment is not set: ${currentEnvironmentTag}`);
} else if (currentEnvironmentTag !== 'production') {
  console.log(`Current Environment is: ${currentEnvironmentTag}`);
}

export { environment };
