import { Step, StepLabel, Stepper } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useState } from 'react';

import Show from '../../../common/components/show';
import { LoginPageWindows } from '../pages/loginPage';
import ChangePassword from './changePassword';
import RequestCode from './requestCode';

export interface IForgotPasswordProps {
  setWindow: React.Dispatch<React.SetStateAction<LoginPageWindows>>;
}

const forgotPasswordSteps = ['Solicitar Código', 'Cambiar Contraseña'];

const ForgotPassword = ({ setWindow }: IForgotPasswordProps) => {
  const [activeStep, setActiveStep] = useState(0);
  //const navigate = useNavigate();
  //const dispatch = useDispatch();
  //const loginService = new LoginService();

  const next = () => {
    setActiveStep(activeStep === forgotPasswordSteps.length - 1 ? activeStep : activeStep + 1);
  };
  const back = () => {
    setActiveStep(activeStep === 0 ? activeStep : activeStep - 1);
  };

  return (
    <Box
      sx={{
        width: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          width: '100%'
        }}
      >
        <Stepper activeStep={activeStep}>
          {forgotPasswordSteps.map((label) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Show when={activeStep === 0}>
          <RequestCode next={next} />
        </Show>
        <Show when={activeStep === 1}>
          <ChangePassword back={back} />
        </Show>
      </Box>
      <Button fullWidth variant="text" sx={{ mt: 2 }} disableElevation={true} onClick={() => setWindow('LOGIN')}>
        Iniciar Sesión
      </Button>
    </Box>
  );
};

export default ForgotPassword;
