import './styles/dashboardStyles.css';

import { Avatar, Box, Chip, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import NoticesAlert from '../../common/components/noticesAlert';
import Notifications from '../../common/components/notifications';
import Websocket from '../../common/components/websocket';
import { UserAPI } from '../../infra/api/user-api';
import DesktopSidebar from './components/sidebar';

export interface IDesktopDashboard {
  children?: React.ReactNode;
}

const DesktopDashboard = ({ children }: IDesktopDashboard) => {
  const currentUser = UserAPI.getCurrentUser();

  return (
    <div className="desktop-layout">
      <NoticesAlert />
      <DesktopSidebar />
      <div className="desktop-body">
        <div className="desktop-header">
          {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
            <Tooltip title="Este mensaje no se mostrará en producción">
              <Chip
                variant="filled"
                label={`Environment: ${process.env.REACT_APP_ENVIRONMENT.toUpperCase()}`}
                sx={{ mx: 2 }}
                color="primary"
              />
            </Tooltip>
          )}
          <Websocket />
          <Typography
            component="h2"
            variant="caption"
            sx={{
              color: 'rgb(75 ,85, 99, 1);',
              fontStyle: 'italic',
              fontSize: '1em',
              fontWeight: 'light',
              ml: 2,
              flexGrow: 6
            }}
          >
            La seguridad “Empieza conmigo y continúa con nosotros”
          </Typography>
          <Link to="/perfil/usuario">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', justifySelf: 'end' }}>
              <Typography component="p" variant="body1">
                <strong>Bienvenido {currentUser?.name ?? ''}</strong>
              </Typography>
              <Avatar
                src={
                  currentUser?.profilePicture ??
                  'https://salud-fotos.s3.amazonaws.com/Faenza-avatar-default-symbolic.svg'
                }
                alt="Foto de perfil"
              />
            </Box>
          </Link>
        </div>
        <div className="desktop-content-container">
          <div className="desktop-content"> {children}</div>
          <Notifications />
        </div>
      </div>
    </div>
  );
};

export default DesktopDashboard;
