/**
 * maps the role to a color available from MUI
 * @param role user role to be mapped
 * @returns the color for MUI standard
 */
export function useColoredRole(
  role: string
): 'secondary' | 'default' | 'primary' | 'error' | 'info' | 'success' | 'warning' {
  if (role === 'ADMIN') return 'success';
  if (role === 'COMMON_USER') return 'primary';

  return 'default';
}
