export type Record = {
  id: string;
  active: number;
  batteryCapacity: number;
  beaconId: number;
  beaconType: number;
  burnedCalories: number;
  commandId: number;
  distanceTraveled: number;
  creationDate: string;
  gpsStatusAndReport: number;
  workerId: string;
  watchId: string;
  lat: number;
  long: number;
  dailySteps: number;
  protocolVersion: number;
  rawPayload: string;
  reserved: number;
  heartRate: number;
  rssi: number;
  bodyTemperature: number;
  txPower: number;
  type: string;
  watchTimestamp: string;
  zone: string;
  zoneId: string;
};

export enum RecordTypes {
  NONE = 'NONE',
  LOW_HEART_RATE = 'LOW_HEART_RATE',
  MAN_DOWN = 'MAN_DOWN',
  SOS = 'SOS',
  INSUFFICIENT_PERMISSIONS = 'INSUFFICIENT_PERMISSIONS',
  ANORMAL_TEMPERATURE = 'ANORMAL_TEMPERATURE',
  ANORMAL_HEART_RATE = 'ANORMAL_HEART_RATE'
}
