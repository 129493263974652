import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Zone, ZoneRiskLevel } from '../../../common/models/zone';
import { setLoading } from '../../../infra/store/reducers/loading';
import { ZoneService } from '../services/zoneService';

export interface IZoneFormProps {
  zone: Zone;
  setZone: React.Dispatch<React.SetStateAction<Zone>>;
}

const ZoneForm = ({ zone, setZone }: IZoneFormProps) => {
  const [name, setName] = useState<string>('');
  const [riskDescription, setRiskDescription] = useState<string>('');
  const [riskLevel, setRiskLevel] = useState<ZoneRiskLevel>('NO_RISK');
  const [hasRestrictedAccess, setHasRestrictedAccess] = useState<boolean>(false);

  const dispatch = useDispatch();
  const service = new ZoneService();

  useEffect(() => {
    if (!zone) return;
    setName(zone.name);
    setRiskDescription(zone.riskDescription);
    setRiskLevel(zone.riskLevel);
    setHasRestrictedAccess(!!zone.hasRestrictedAccess);
  }, [zone]);

  const handleEditZoneSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const editedZone: Zone = {
      ...zone,
      name: name,
      riskDescription: riskDescription,
      riskLevel: riskLevel,
      hasRestrictedAccess: hasRestrictedAccess
    };

    dispatch(setLoading(true));
    service.editZone(editedZone).finally(() => {
      setZone(editedZone);
      dispatch(setLoading(false));
    });
  };

  return (
    <Paper elevation={0} sx={{ mt: 2, p: 2, boxSizing: 'border-box' }}>
      <Typography variant="h6" component="p">
        <strong>Detalles de la zona</strong>
      </Typography>

      <Box component="form" onSubmit={handleEditZoneSubmit} noValidate>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Nombre"
          name="name"
          autoComplete="name"
          autoFocus
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="riskDescription"
          label="Descripción de Riesgo"
          name="riskDescription"
          autoComplete="riskDescription"
          value={riskDescription}
          onChange={(e) => setRiskDescription(e.target.value)}
        />
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="riskLevel">Nivel de Riesgo</InputLabel>
          <Select
            labelId="riskLevel"
            id="riskLevel"
            fullWidth
            label="Nivel de Riesgo"
            color="primary"
            value={riskLevel}
            onChange={(e) => setRiskLevel(e.target.value as ZoneRiskLevel)}
          >
            <MenuItem value={'HIGH_RISK'}>
              <Chip label="Riesgo Importante" color={service.mapRiskLevelToChipColor('HIGH_RISK')} />
            </MenuItem>
            <MenuItem value={'NO_RISK'}>
              <Chip label="Sin Riesgo" color={service.mapRiskLevelToChipColor('NO_RISK')} />
            </MenuItem>
            <MenuItem value={'LOW_RISK'}>
              <Chip label="Riesgo Mínimo" color={service.mapRiskLevelToChipColor('LOW_RISK')} />
            </MenuItem>
            <MenuItem value={'INTOLERABLE_RISK'}>
              <Chip label="Riesgo Intolerable" color={service.mapRiskLevelToChipColor('INTOLERABLE_RISK')} />
            </MenuItem>
            <MenuItem value={'SERIOUS_RISK'}>
              <Chip label="Riesgo Grave" color={service.mapRiskLevelToChipColor('SERIOUS_RISK')} />
            </MenuItem>
            <MenuItem value={'POSSIBLE_RISK'}>
              <Chip label="Riesgo Posible" color={service.mapRiskLevelToChipColor('POSSIBLE_RISK')} />
            </MenuItem>
          </Select>
        </FormControl>
        <FormGroup sx={{ mt: 2 }}>
          <FormControlLabel
            color="primary"
            control={
              <Checkbox checked={hasRestrictedAccess} onChange={(e) => setHasRestrictedAccess(e.target.checked)} />
            }
            label="Tiene Acceso Restringido"
          />
        </FormGroup>
        <Button type="submit" variant="contained" sx={{ mt: 2, mb: 2 }} disableElevation={true}>
          Guardar Cambios
        </Button>
      </Box>
    </Paper>
  );
};
export default ZoneForm;
