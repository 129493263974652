import './styles/loadingStyle.css';

import React from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../../infra/store';
import Show from '../show';

const Loading = () => {
  const isLoading = useSelector((state: AppState) => state.loadingReducer.isLoading);
  const loadingText = useSelector((state: AppState) => state.loadingReducer.loadingText);
  return (
    <Show when={isLoading}>
      <div id="loader-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ margin: 'auto', display: 'block', shapeRendering: 'auto' }}
          width="200px"
          height="200px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle cx="50" cy="50" r="0" fill="none" stroke="#ff3700" strokeWidth="2">
            <animate
              attributeName="r"
              repeatCount="indefinite"
              dur="1s"
              values="0;40"
              keyTimes="0;1"
              keySplines="0 0.2 0.8 1"
              calcMode="spline"
              begin="0s"
            ></animate>
            <animate
              attributeName="opacity"
              repeatCount="indefinite"
              dur="1s"
              values="1;0"
              keyTimes="0;1"
              keySplines="0.2 0 0.8 1"
              calcMode="spline"
              begin="0s"
            ></animate>
          </circle>
          <circle cx="50" cy="50" r="0" fill="none" stroke="white" strokeWidth="2">
            <animate
              attributeName="r"
              repeatCount="indefinite"
              dur="1s"
              values="0;40"
              keyTimes="0;1"
              keySplines="0 0.2 0.8 1"
              calcMode="spline"
              begin="-0.5s"
            ></animate>
            <animate
              attributeName="opacity"
              repeatCount="indefinite"
              dur="1s"
              values="1;0"
              keyTimes="0;1"
              keySplines="0.2 0 0.8 1"
              calcMode="spline"
              begin="-0.5s"
            ></animate>
          </circle>
        </svg>
        <p>{loadingText}</p>
      </div>
    </Show>
  );
};

export default Loading;
