import { Record, RecordTypes } from '../../../common/models/record';
import { RealtimeWorker, Worker } from '../../../common/models/worker';
import { CreateRealtimeWorker } from '../../../common/utils/worker';

/**
 * Hook to map the workers to be realtime information and sorted on priorities
 * @param workers
 * @param records
 * @returns
 */
export function useRealtimeWorkers(
  workers: Worker[],
  records: { [id: string]: Record },
  filter: string
): RealtimeWorker[] {
  const filterFunction = (worker: RealtimeWorker) =>
    worker.id.includes(filter) ||
    worker.fullname
      .replace(/[áéíóúÁÉÍÓÚ]/g, (c) => 'aeiouAEIOU'['áéíóúÁÉÍÓÚ'.indexOf(c)])
      .toLowerCase()
      .includes(filter.toLowerCase());
  return workers
    .map((worker) => CreateRealtimeWorker(worker, records[worker.id]))
    .filter((worker) => (filter !== '' ? filterFunction(worker) : true))
    .sort((a, b) => {
      return (!!b.location ? 1 : 0) - (!!a.location ? 1 : 0);
    })
    .sort((a, b) => {
      return (b.heartRate ?? 0) - (a.heartRate ?? 0);
    })
    .sort((a, b) => {
      const aPriority = !!a.type && a.type !== RecordTypes.NONE ? 1 : 0;
      const bPriority = !!b.type && b.type !== RecordTypes.NONE ? 1 : 0;

      // Compare priorities first
      if (aPriority !== bPriority) {
        return bPriority - aPriority;
      }

      // If priorities are equal, maintain the original order
      return 0;
    });
}
