import AccessibilityIcon from '@mui/icons-material/Accessibility';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CampaignIcon from '@mui/icons-material/Campaign';
import EngineeringIcon from '@mui/icons-material/Engineering';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import InsightsIcon from '@mui/icons-material/Insights';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import LogoutIcon from '@mui/icons-material/Logout';
import MapIcon from '@mui/icons-material/Map';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PlaceIcon from '@mui/icons-material/Place';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SensorsIcon from '@mui/icons-material/Sensors';
import SummarizeIcon from '@mui/icons-material/Summarize';
import TimelineIcon from '@mui/icons-material/Timeline';
import List from '@mui/material/List';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { UserAPI } from '../../../infra/api/user-api';
import Accesibility from '../../accesibility';
import AdminMenu from '../../adminMenu';
import Logout from '../../auth/pages/logout';
import CreateNotice from '../../createNotice';
import CreateUser from '../../createUser';
import FAQ from '../../faq';
import HeatMap from '../../heatMap';
import HelpMenu from '../../helpMenu';
import LiveMap from '../../liveMap';
import Machine from '../../machine';
import Machines from '../../machines';
import Notices from '../../notices';
import Profile from '../../profile';
import ReportDetail from '../../report';
import Reports from '../../reports';
import ReportsMenu from '../../statisticsMenu';
import Tracking from '../../trackingMenu';
import User from '../../user';
import MyUserMenu from '../../userMenu';
import Users from '../../users';
import WorkerDetail from '../../worker';
import Workers from '../../workers';
import ZoneDetail from '../../zone';
import Zones from '../../zones';
import QAListItem, { IQAListItem } from './listItem';
export let items: IQAListItem[] = [
  {
    title: 'Monitoreo',
    Icon: GpsFixedIcon,
    path: 'monitoreo',
    component: <Tracking />,
    elements: [
      {
        component: <Zones />,
        path: 'monitoreo/zonas',
        title: 'Zonas',
        ElementIcon: PlaceIcon
      },
      {
        component: <ZoneDetail />,
        path: 'monitoreo/zona',
        title: 'Zonas',
        ElementIcon: PlaceIcon,
        hide: true
      },
      {
        component: <Workers />,
        path: 'monitoreo/colaboradores',
        title: 'Colaboradores',
        ElementIcon: EngineeringIcon
      },
      {
        component: <WorkerDetail />,
        path: 'monitoreo/colaborador',
        title: 'Colaboradores',
        ElementIcon: EngineeringIcon,
        hide: true
      },
      {
        component: <LiveMap />,
        path: 'monitoreo/mapa',
        title: 'Mapa',
        ElementIcon: MapIcon
      },
      {
        component: <Machines />,
        path: 'monitoreo/maquinas',
        title: 'Máquinas',
        ElementIcon: PrecisionManufacturingIcon
      },
      {
        component: <Machine />,
        path: 'monitoreo/maquina',
        hide: true,
        title: 'Máquina',
        ElementIcon: SensorsIcon
      }
    ]
  },
  {
    title: 'Estadísticas',
    Icon: InsightsIcon,
    path: 'estadisticas',
    component: <ReportsMenu />,
    elements: [
      {
        component: <Reports />,
        path: 'estadisticas/reportes',
        title: 'Reportes',
        ElementIcon: SummarizeIcon
      },
      {
        component: <ReportDetail />,
        path: 'estadisticas/reporte',
        title: 'Reporte',
        hide: true,
        ElementIcon: SummarizeIcon
      },
      {
        component: <HeatMap />,
        path: 'estadisticas/heatmap',
        title: 'Localización Diaria',
        ElementIcon: TimelineIcon
      }
    ]
  },
  {
    title: 'Administrador',
    Icon: AdminPanelSettingsIcon,
    path: 'administrador',
    component: <AdminMenu />,
    getProtection: () => {
      const user = UserAPI.getCurrentUser();

      return user.role === 'ADMIN';
    },
    elements: [
      {
        component: <Users />,
        path: 'administrador/usuarios',
        title: 'Usuarios',
        ElementIcon: PeopleAltIcon
      },
      {
        component: <CreateUser />,
        path: 'administrador/usuario/crear',
        title: 'Crear Usuario',
        ElementIcon: PersonAddIcon
      },
      {
        component: <User />,
        path: 'administrador/usuario',
        title: 'Usuario',
        hide: true,
        ElementIcon: PersonIcon
      },
      {
        component: <Workers />,
        path: 'administrador/trabajadores',
        title: 'Colaboradores',
        ElementIcon: EngineeringIcon
      },
      {
        component: <Notices />,
        path: 'administrador/avisos',
        title: 'Avisos',
        ElementIcon: CampaignIcon
      },
      {
        component: <CreateNotice />,
        path: 'administrador/avisos/crear',
        title: 'Crear Usuario',
        hide: true,
        ElementIcon: PersonAddIcon
      }
    ]
  },
  {
    title: 'Ayuda',
    Icon: LiveHelpIcon,
    path: 'ayuda',
    component: <HelpMenu />,
    elements: [
      {
        component: <FAQ />,
        path: 'ayuda/faq',
        title: 'Preguntas Frecuentes',
        ElementIcon: QuestionAnswerIcon
      }
    ]
  },
  {
    title: 'Mi Usuario',
    Icon: AccountBoxIcon,
    path: 'perfil',
    component: <MyUserMenu />,
    elements: [
      {
        component: <Profile />,
        path: 'perfil/usuario',
        title: 'Perfil',
        ElementIcon: PermContactCalendarIcon
      },
      {
        component: <Accesibility />,
        path: 'perfil/accesibilidad',
        title: 'Accesibilidad',
        ElementIcon: AccessibilityIcon
      },
      {
        component: <Logout />,
        path: 'logout',
        title: 'Cerrar Sesión',
        ElementIcon: LogoutIcon
      }
    ]
  }
];
const QAListItems = () => {
  const [selected, setSelected] = useState(-1);
  const { pathname = '' } = useLocation();

  useEffect(() => {
    let finded = false;
    items.forEach((element, id) => {
      if (pathname.includes(`/${element.path}`)) {
        setSelected(id);
        finded = true;
      }
    });

    if (!finded) {
      setSelected(-1);
    }
  }, [pathname]);

  return (
    <List component="nav">
      {items.map((item, id) => (
        <QAListItem
          elements={item.elements}
          Icon={item.Icon}
          title={item.title}
          key={id}
          component={item.component}
          path={item.path}
          isSelected={selected === id}
          onClick={() => setSelected(id)}
          getProtection={item.getProtection}
        />
      ))}
    </List>
  );
};

export default QAListItems;
