import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import HomeIcon from '@mui/icons-material/Home';
import MapIcon from '@mui/icons-material/Map';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';
import { Worker } from '../../common/models/worker';
import { Zone } from '../../common/models/zone';
import { setLoading, setLoadingText } from '../../infra/store/reducers/loading';
import Map from './components/map';
import MapInformation from './components/mapInformation';
import { LiveMapService } from './services/realtimeService';

const breadcrumbItems: IBreadcrumbItem[] = [
  {
    Icon: <HomeIcon color="secondary" />,
    text: 'Home',
    path: '/'
  },
  {
    Icon: <GpsFixedIcon color="secondary" />,
    text: 'Monitoreo',
    path: '/monitoreo'
  },
  {
    Icon: <MapIcon color="primary" />,
    text: 'Mapa',
    path: '/monitoreo/mapa'
  }
];

const LiveMap = () => {
  const service = new LiveMapService();

  const [workers, setWorkers] = useState<Worker[]>([]);
  const [zones, setZones] = useState<Zone[]>([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleApiZoneResponse = (response: Zone[] | Error) => {
    if (response instanceof Error) throw response;
    setZones(response);
  };

  const handleWorkersResponse = (response: Worker[] | Error) => {
    if (response instanceof Error) throw response;
    setWorkers(response);
  };

  useEffect(() => {
    if (workers.length > 0 || zones.length > 0) return;

    dispatch(setLoading(true));
    dispatch(setLoadingText('Cargando Zonas'));

    service
      .getZones()
      .then(handleApiZoneResponse)
      .catch(() => navigate('/monitoreo'))
      .finally(() => {
        dispatch(setLoadingText('Cargando Colaboradores'));
        service
          .getWorkers()
          .then(handleWorkersResponse)
          .catch(() => navigate('/monitoreo'))
          .finally(() => dispatch(setLoading(false)));
      });
  }, []);

  return (
    <Box>
      <Breadcrumb items={breadcrumbItems} title="Mapa en Tiempo Real" />
      <MapInformation />
      {workers.length > 0 && zones.length > 0 && <Map workers={workers} zones={zones} />}
    </Box>
  );
};

export default LiveMap;
