import { AnyAction, Dispatch } from '@reduxjs/toolkit';

import { EM500XRecord } from '../../common/models/em500co2Record';
import { Notification } from '../../common/models/notification';
import { Record } from '../../common/models/record';
import { WebsocketResponse, WebsocketResponseActions } from '../../common/models/websocket';
import {
  pushNotification,
  setCO2Record,
  setConnectionStatus,
  setRecord,
  setUDLRecord
} from '../store/reducers/websocket';

export class WebsocketService {
  private static connections: WebSocket[] = [];

  private static instance: WebsocketService;

  private static reconnectAttempts: number = 0;

  static getInstance(wsUrl: string, dispatch: Dispatch<AnyAction>) {
    if (!WebsocketService.instance) {
      WebsocketService.instance = new WebsocketService(wsUrl, dispatch);
    }

    return WebsocketService.instance;
  }

  private constructor(
    private wsUrl: string,
    private dispatch: Dispatch<AnyAction>
  ) {
    if (WebsocketService.connections.length > 0)
      WebsocketService.connections.forEach((connection) => connection.close());

    setTimeout(
      () => {
        const connection = new WebSocket(wsUrl);

        connection.addEventListener('open', () => this.onConnect());
        connection.addEventListener('close', () => this.onDisconnect());
        connection.addEventListener('message', (e) => this.onNewMessage(e));
        connection.addEventListener('error', (error) => this.onError(error));

        WebsocketService.connections.push(connection);
      },
      Math.pow(2, WebsocketService.reconnectAttempts) * 1000
    );
  }

  private onConnect() {
    this.dispatch(setConnectionStatus(true));
  }

  private onDisconnect() {
    this.dispatch(setConnectionStatus(false));

    // Implement exponential backoff for reconnection attempts
    setTimeout(
      () => {
        WebsocketService.instance = new WebsocketService(this.wsUrl, this.dispatch);
      },
      Math.pow(2, WebsocketService.reconnectAttempts) * 1000
    );
  }

  private onNewMessage(e: MessageEvent) {
    try {
      const data: WebsocketResponse = JSON.parse(e.data);
      const action: string = data.action;

      if (!action) {
        throw new Error('Received Message without action.');
      }

      if (data.action === WebsocketResponseActions.NOTIFICATION) {
        this.dispatch(pushNotification(data.payload as Notification));
      }

      if (data.action === WebsocketResponseActions.RECORD) {
        this.dispatch(setRecord(data.payload as Record));
      }

      if (data.action === WebsocketResponseActions.EM500CO2) {
        this.dispatch(setCO2Record(data.payload as EM500XRecord));
      }

      if (data.action === WebsocketResponseActions.EM500UDL) {
        this.dispatch(setUDLRecord(data.payload as EM500XRecord));
      }
    } catch (error) {
      console.error(error);
      // Handle errors appropriately
    }
  }

  private onError(error: Event) {
    console.error('WebSocket error:', error);
    // Handle WebSocket errors
  }
}
