import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import Notifications from '../../../common/components/notifications';

export interface IMobileSidebar {
  isOpen: boolean;
  onClose: () => void;
}

const NotificationsSidebar = ({ isOpen, onClose }: IMobileSidebar) => {
  return (
    <SwipeableDrawer
      variant="temporary"
      anchor="right"
      open={isOpen}
      onClose={onClose}
      onOpen={onClose}
      ModalProps={{
        BackdropProps: {
          onClick: onClose
        }
      }}
    >
      <Notifications onClose={onClose} />
    </SwipeableDrawer>
  );
};

export default NotificationsSidebar;
