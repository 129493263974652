import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Show from '../../../common/components/show';
import { SmartWatch } from '../../../common/models/smartWatch';
import { Worker, WorkerWatchStatus } from '../../../common/models/worker';
import { UserAPI } from '../../../infra/api/user-api';
import { setLoading, setLoadingText } from '../../../infra/store/reducers/loading';
import { WorkerService } from '../services/service';

export interface IWorkerFormProps {
  worker: Worker;
  smartwatches: SmartWatch[];
}

const WorkerForm = ({ worker, smartwatches }: IWorkerFormProps) => {
  const [watchName, setWatchName] = useState('');
  const [watchEUI, setWatchEUI] = useState('');
  const [hasWatch, setHasWatch] = useState(false);
  const [selectedWatch, setSelectedWatch] = useState<SmartWatch>({
    appEui: '',
    appKey: '',
    eui: '',
    name: ''
  });

  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const service = new WorkerService();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!worker) return;

    setWatchName(worker.watchName);
    setWatchEUI(worker.watchEUI);
    setHasWatch(worker.hasWatch === WorkerWatchStatus.ACTIVE);
  }, [worker]);

  useEffect(() => {
    if (!selectedWatch || selectedWatch.name === '') return;

    setWatchName(selectedWatch.name);
    setWatchEUI(selectedWatch.eui);
  }, [selectedWatch]);

  const handleEditWorkerSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(setLoading(true));
    dispatch(setLoadingText('Actualizando Colaborador'));
    service
      .editWorker(worker.id, watchName, watchEUI, hasWatch ? WorkerWatchStatus.ACTIVE : WorkerWatchStatus.UNACTIVE)
      .catch(() => {
        setOpen(true);
        setSnackbarMessage('Ha ocurrido un error. Inténtalo de nuevo');
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const handleSmartwatchChange = (event: SelectChangeEvent) => {
    event.preventDefault();

    setSelectedWatch(smartwatches.find((smartwatch) => smartwatch.eui === event.target.value));
  };

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        mt: 2,
        boxSizing: 'border-box',
        display: 'flex',
        justifyItems: 'start',
        justifyContent: 'start',
        width: '100%',
        flexDirection: 'column'
      }}
    >
      <Snackbar open={open} onClose={handleClick} autoHideDuration={6000} message={snackbarMessage} />
      <Typography variant="h6" component="p">
        <strong>Detalles del Trabajador</strong>
      </Typography>

      <Box
        component="form"
        onSubmit={handleEditWorkerSubmit}
        noValidate
        sx={{
          boxSizing: 'border-box',
          display: 'flex',
          justifyItems: 'center',
          justifyContent: 'center',
          width: '100%',
          flexDirection: 'column'
        }}
      >
        <Avatar
          src={worker.profilePicture}
          alt="Imagen de Perfil"
          sx={{ width: 150, height: 150, alignSelf: 'center', mt: 2 }}
        />
        <Typography variant="h6" component="p" sx={{ textAlign: 'center', mt: 2 }}>
          <strong>Código Colaborador: </strong> {worker.id}
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          id="fullname"
          label="Nombre"
          name="fullname"
          autoComplete="fullname"
          autoFocus
          value={worker.fullname}
          disabled
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="age"
          label="Edad"
          name="age"
          autoComplete="age"
          autoFocus
          value={worker.age}
          disabled
        />
        <FormControl fullWidth>
          <InputLabel id="smart-watch-select-label">Seleccionar Reloj</InputLabel>
          <Select
            labelId="smart-watch-select-label"
            label="Seleccionar Reloj"
            onChange={handleSmartwatchChange}
            value={selectedWatch?.eui}
          >
            {smartwatches.map((smartwatch, id) => (
              <MenuItem key={id} value={smartwatch.eui}>
                {smartwatch.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          margin="normal"
          required
          fullWidth
          id="watchName"
          label="Asignar Nombre Reloj"
          name="watchName"
          autoComplete="watchName"
          autoFocus
          value={watchName}
          onChange={(e) => setWatchName(e.target.value)}
          disabled
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="watchEUI"
          label="Asignar Id Reloj"
          name="watchEUI"
          autoComplete="watchEUI"
          autoFocus
          value={watchEUI}
          onChange={(e) => setWatchEUI(e.target.value)}
          disabled
        />
        <Show when={UserAPI.isCurrentUserAdmin()}>
          <>
            <FormGroup sx={{ mt: 2 }}>
              <FormControlLabel
                color="primary"
                control={<Checkbox checked={hasWatch} onChange={(e) => setHasWatch(e.target.checked)} />}
                label="Habilitar Reloj"
              />
            </FormGroup>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
              disableElevation={true}
              disabled={!UserAPI.isCurrentUserAdmin()}
            >
              Guardar Cambios
            </Button>
          </>
        </Show>
      </Box>
    </Paper>
  );
};
export default WorkerForm;
