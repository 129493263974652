/**
 * Maps the heart rate to a color
 * @param type the heart rate in lpm (integer)
 * @returns the color according to the heart rate
 */
export function useHeartRateColor(heartBeat: number): 'success' | 'error' | 'warning' | 'info' {
  if (60 <= heartBeat && heartBeat <= 100) return 'success';
  if (100 <= heartBeat && heartBeat <= 120) return 'warning';
  return 'error';
}
