import { RecordTypes } from '../../../common/models/record';
import { HealthAPI } from '../../../infra/api/health-api';
import { ZoneAPI } from '../../../infra/api/zone-api';
import { environment } from '../../../infra/environment/environment';

export class HeatMapService {
  private healthApi: HealthAPI;

  private zonesApi: ZoneAPI;

  constructor() {
    this.healthApi = new HealthAPI(environment);
    this.zonesApi = new ZoneAPI(environment);
  }

  /**
   * Return all the zones
   * @returns
   */
  getRecords = (workerId: string, startDate: string, endDate: string, type: RecordTypes) => {
    return this.healthApi.getRecords(workerId, startDate, endDate, type);
  };

  /**
   * Return all the zones
   * @returns
   */
  getZones = () => {
    return this.zonesApi.getAll();
  };
}
