import HomeIcon from '@mui/icons-material/Home';
import InsightsIcon from '@mui/icons-material/Insights';
import React from 'react';

import HeatMapSVG from '../../assets/heatmap_svg.svg';
import ReportSVG from '../../assets/report.svg';
import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';
import NavigationMenu, { INavigationMenuItem } from '../../common/components/navigationMenu';

const menuItems: INavigationMenuItem[] = [
  {
    name: 'Reportes',
    to: '/estadisticas/reportes',
    types: ['COMMON_USER', 'ADMIN'],
    svg: <img src={ReportSVG} alt="Reportes" width={250} />
  },
  {
    name: 'Mapa de Calor',
    to: '/estadisticas/heatmap',
    types: ['COMMON_USER', 'ADMIN'],
    svg: <img src={HeatMapSVG} alt="Mapa de Calor" width={250} />
  }
];
const ReportsMenu = () => {
  const breadcrumbItems: IBreadcrumbItem[] = [
    {
      Icon: <HomeIcon color="secondary" />,
      text: 'Home',
      path: '/'
    },
    {
      Icon: <InsightsIcon color="primary" />,
      text: 'Estadísticas',
      path: '/estadisticas'
    }
  ];

  return (
    <div className="grid w-full h-full px-2">
      <Breadcrumb items={breadcrumbItems} title="Menú de Estadísticas" />
      <NavigationMenu items={menuItems} />
    </div>
  );
};

export default ReportsMenu;
