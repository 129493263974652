import { Record } from '../models/record';
import { RealtimeWorker, Worker } from '../models/worker';

export function CreateRealtimeWorker(worker: Worker, record: Record | undefined): RealtimeWorker {
  if (!record) return worker;

  const realtimeWorker: RealtimeWorker = {
    ...worker,
    ...record
  };

  realtimeWorker.id = worker.id;
  realtimeWorker.active = worker.active;

  return realtimeWorker;
}
