import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button, IconButton, InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppState } from '../../../infra/store';
import { setLoading } from '../../../infra/store/reducers/loading';
import { LoginService } from '../services/service';

export interface IChangePasswordProps {
  back: () => void;
}

const ChangePassword = ({ back }: IChangePasswordProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const email = useSelector((state: AppState) => state.loginReducer.forgotUserEmail);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginService = new LoginService();

  const handleChangePasswordButton = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    try {
      dispatch(setLoading(true));
      const response = await loginService.recoverPassword(email, password, code);
      if (response instanceof Error) throw response;
      navigate('/');
    } catch (err) {
      alert((err as Error).message);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleGoBackButton = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    back();
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Typography component="h1" variant="h4" sx={{ mt: 2 }}>
        <strong>Cambiar Contraseña</strong>
      </Typography>
      <Typography component="p" variant="body1" sx={{ mt: 2 }}>
        Se te ha enviado un código de recuperación a {email}.
        <br /> Utiliza ese código en el siguiente formulario para cambiar tu contraseña.
      </Typography>
      <Box component="form" noValidate sx={{ width: '100%' }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="code"
          label="Código de Confirmación"
          name="code"
          autoComplete="code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Nueva Contraseña"
          type={showPassword ? 'text' : 'password'}
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePassword} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Box>
      <Button fullWidth variant="contained" sx={{ mt: 2 }} disableElevation={true} onClick={handleChangePasswordButton}>
        Cambiar Contraseña
      </Button>
      <Button fullWidth variant="text" sx={{ mt: 2 }} disableElevation={true} onClick={handleGoBackButton}>
        Volver a solicitar código
      </Button>
    </>
  );
};

export default ChangePassword;
