import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RealtimeWorker } from '../../../../common/models/worker';

export interface RouterState {
  zoneId: string;
  machineId: string;
  workerId: string;
  reportId: string;
  userId: string;
  mapWorker: RealtimeWorker;
}

const initialState: RouterState = {
  zoneId: '',
  workerId: '',
  machineId: '',
  reportId: '',
  userId: '',
  mapWorker: undefined
};

export const routerSlice = createSlice({
  name: 'router',
  initialState,
  reducers: {
    setZoneId: (state, action: PayloadAction<string>) => {
      state.zoneId = action.payload;

      return state;
    },
    setWorkerId: (state, action: PayloadAction<string>) => {
      state.workerId = action.payload;

      return state;
    },
    setReportId: (state, action: PayloadAction<string>) => {
      state.reportId = action.payload;

      return state;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;

      return state;
    },
    setMachineId: (state, action: PayloadAction<string>) => {
      state.machineId = action.payload;

      return state;
    },
    setMapWorker: (state, action: PayloadAction<RealtimeWorker>) => {
      state.mapWorker = action.payload;

      return state;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setZoneId, setWorkerId, setReportId, setUserId, setMapWorker, setMachineId } = routerSlice.actions;

export default routerSlice.reducer;
