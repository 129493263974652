import './index.css';

import CachedIcon from '@mui/icons-material/Cached';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EngineeringIcon from '@mui/icons-material/Engineering';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import HomeIcon from '@mui/icons-material/Home';
import LockIcon from '@mui/icons-material/Lock';
import SosIcon from '@mui/icons-material/Sos';
import SportsMartialArtsIcon from '@mui/icons-material/SportsMartialArts';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, IconButton, Paper, TextField, Tooltip, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';
import DataTable from '../../common/components/dataTable';
import { RecordTypes } from '../../common/models/record';
import { RealtimeWorker, Worker } from '../../common/models/worker';
import { AppState } from '../../infra/store';
import { setLoading, setLoadingText } from '../../infra/store/reducers/loading';
import { setWorkerId } from '../../infra/store/reducers/router';
import { useRealtimeWorkers } from './hooks/useRealtimeWokers';
import { WorkerService } from './services/workerService';
const breadcrumbItems: IBreadcrumbItem[] = [
  {
    Icon: <HomeIcon color="secondary" />,
    text: 'Home',
    path: '/'
  },
  {
    Icon: <GpsFixedIcon color="secondary" />,
    text: 'Monitoreo',
    path: '/monitoreo'
  },
  {
    Icon: <EngineeringIcon color="primary" />,
    text: 'Colaboradores',
    path: '/monitoreo/colaboradores'
  }
];

const Workers = () => {
  const [workers, setWorkers] = useState<Worker[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [realtimeWorkers, setRealtimeWorkers] = useState<RealtimeWorker[]>([]);
  const records = useSelector((state: AppState) => state.websocketReducer.records);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const service = new WorkerService();

  useEffect(() => {
    setRealtimeWorkers(useRealtimeWorkers(workers, records, filter));
  }, [records, filter]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Código',
      flex: 1
    },
    { field: 'fullname', headerName: 'Colaborador', flex: 2, sortable: true },
    {
      field: 'location',
      headerName: 'Ubicación',
      sortable: true,
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        const exists = !!params.row.zone;

        return (
          <Typography color={exists ? 'var(--text-color)' : 'var(--low-text-color)'}>
            {exists ? params.row.zone : 'Aún no hay datos'}
          </Typography>
        );
      }
    },
    {
      field: 'type',
      headerName: 'Estado Actual',
      sortable: true,
      flex: 3,
      renderCell: (params: GridRenderCellParams) => {
        const exists = !!params.row.heartRate;
        let message = 'Normal';
        let icon = <CheckCircleIcon color="success" />;

        if (params.row.type === RecordTypes.ANORMAL_HEART_RATE) {
          message = 'Ritmo Cardíaco Anormal';
          icon = <HeartBrokenIcon color="warning" />;
        }

        if (params.row.type === RecordTypes.ANORMAL_TEMPERATURE) {
          message = 'Temperatura Anormal';
          icon = <ThermostatIcon color="warning" />;
        }

        if (params.row.type === RecordTypes.LOW_HEART_RATE) {
          message = 'Ritmo Cardíaco Anormal';
          icon = <HeartBrokenIcon color="warning" />;
        }

        if (params.row.type === RecordTypes.INSUFFICIENT_PERMISSIONS) {
          message = 'Entro sin permiso a una zona';
          icon = <LockIcon color="warning" />;
        }

        if (params.row.type === RecordTypes.MAN_DOWN) {
          message = 'Se ha caído';
          icon = <SportsMartialArtsIcon color="error" />;
        }

        if (params.row.type === RecordTypes.SOS) {
          message = 'Está pidiendo auxilio';
          icon = <SosIcon color="error" />;
        }

        return (
          <Tooltip title="Indica el estado actual del colaborador">
            <IconButton>
              {icon}
              <Typography color={exists ? 'var(--text-color)' : 'var(--low-text-color)'} sx={{ ml: 2 }}>
                {message}
              </Typography>
            </IconButton>
          </Tooltip>
        );
      }
    },
    {
      field: 'heartRate',
      headerName: 'Ritmo Cardíaco',
      sortable: true,
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        const exists = !!params.row.heartRate;
        const isAnormalHeartRate =
          params.row.type === RecordTypes.LOW_HEART_RATE || params.row.type === RecordTypes.ANORMAL_HEART_RATE;

        return (
          <Tooltip title="Indica el ritmo cardíaco en latidos por minuto (lpm)">
            <IconButton>
              {exists ? (
                isAnormalHeartRate ? (
                  <HeartBrokenIcon color="error" />
                ) : (
                  <FavoriteIcon color="success" />
                )
              ) : (
                <FavoriteBorderIcon color="action" />
              )}
              <Typography color={exists ? 'var(--text-color)' : 'var(--low-text-color)'} sx={{ ml: 2 }}>
                {exists ? `${params.row.heartRate} lpm` : 'Aún no hay datos'}
              </Typography>
            </IconButton>
          </Tooltip>
        );
      }
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const onClick = () => {
          dispatch(setWorkerId(params.row.id));
          navigate(`/monitoreo/colaborador`);
        };

        return (
          <IconButton onClick={onClick}>
            <VisibilityIcon color="primary" />
          </IconButton>
        );
      }
    }
  ];

  const handleApiResponse = (response: Worker[] | Error) => {
    if (response instanceof Error) throw response;
    setWorkers(response);
    setRealtimeWorkers(useRealtimeWorkers(response, records, filter));
  };

  const handleClearCacheEvent = () => {
    dispatch(setLoading(true));
    dispatch(setLoadingText('Cargando Colaboradores'));
    service
      .clearCache()
      .then(handleApiResponse)
      .catch(console.error)
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    dispatch(setLoading(true));
    service
      .getWorkers()
      .then(handleApiResponse)
      .catch(console.error)
      .finally(() => dispatch(setLoading(false)));
  }, []);

  return (
    <div className="grid w-full h-full px-2">
      <Breadcrumb items={breadcrumbItems} title="Lista de Colaboradores" />
      <Paper elevation={0} sx={{ p: 2, mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="error"
          endIcon={<CachedIcon />}
          disableElevation={true}
          onClick={handleClearCacheEvent}
        >
          Borrar Caché
        </Button>
      </Paper>
      <Paper elevation={0} sx={{ p: 2, mt: 2 }}>
        <TextField
          sx={{ mt: 2, mb: 3, px: 1 }}
          style={{ width: '100%' }}
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Buscar por código o nombre completo"
        />
        <DataTable
          title="Lista de Colaboradores"
          columns={columns}
          data={realtimeWorkers}
          getRowClassName={(params) => `worker-table-status-${params.row.type}`}
        />
      </Paper>
    </div>
  );
};
export default Workers;
