import { User } from '../../../common/models/user';
import { LoginAPI } from '../../../infra/api/login-api';
import { UserAPI } from '../../../infra/api/user-api';
import { environment } from '../../../infra/environment/environment';

export class ProfileService {
  private userApi: UserAPI;

  private authApi: LoginAPI;

  constructor() {
    this.userApi = new UserAPI(environment);
    this.authApi = new LoginAPI(environment, 'Auth');
  }

  changePassword = (oldPassword: string, newPassword: string) => {
    return this.authApi.changePassword(oldPassword, newPassword);
  };

  editUser = (user: User) => {
    return this.userApi.editUser(user);
  };
}
