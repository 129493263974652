import { AdminAPI } from '../../../infra/api/admin-api';
import { environment } from '../../../infra/environment/environment';

export class NoticeService {
  private api: AdminAPI;

  constructor() {
    this.api = new AdminAPI(environment);
  }

  getNotices = () => {
    return this.api.getNotices();
  };

  archiveNoticeById = (id: string) => {
    return this.api.archiveNotice(id);
  };
}
