import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HomeIcon from '@mui/icons-material/Home';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonIcon from '@mui/icons-material/Person';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';
import { User } from '../../common/models/user';
import { AppState } from '../../infra/store';
import { setLoading } from '../../infra/store/reducers/loading';
import UserForm from './components/userForm';
import { UserService } from './services/userService';

export const USER_PREVIOUS_SCREEN = '/administrador/usuarios';

const breadcrumbItems: IBreadcrumbItem[] = [
  {
    Icon: <HomeIcon color="secondary" />,
    text: 'Home',
    path: '/'
  },
  {
    Icon: <AdminPanelSettingsIcon color="secondary" />,
    text: 'Administrador',
    path: '/administrador'
  },
  {
    Icon: <PeopleAltIcon color="secondary" />,
    text: 'Usuarios',
    path: '/administrador/usuarios'
  }
];

const UserDetail = () => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [title, setTitle] = useState<string>('');
  const [items, setItems] = useState<IBreadcrumbItem[]>(breadcrumbItems);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const service = new UserService();
  const email = useSelector((state: AppState) => state.routerReducer.userId);

  const handleApiResponse = (response: User | Error) => {
    if (response instanceof Error) throw response;
    const item: IBreadcrumbItem = {
      Icon: <PersonIcon color="primary" />,
      path: `/administrador/usuario`,
      text: response.name
    };

    setTitle(response.name);
    setUser(response);
    setItems([...items, item]);
  };

  useEffect(() => {
    if (!!user) return;
    if (!email) navigate(USER_PREVIOUS_SCREEN);

    dispatch(setLoading(true));
    service
      .getUserById(email)
      .then(handleApiResponse)
      .catch(() => navigate(USER_PREVIOUS_SCREEN))
      .finally(() => dispatch(setLoading(false)));
  }, []);

  return (
    <div>
      <Breadcrumb items={items} title={title} />
      {!!user && (
        <>
          <UserForm user={user} />
        </>
      )}
    </div>
  );
};
export default UserDetail;
