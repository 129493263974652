/**
 * according to the battery level it will map it to a color
 * @param battery the current battery level in number
 * @returns the color according the battery level
 */
export function useBatteryColor(battery: number): 'success' | 'error' | 'warning' | 'info' {
  if (battery > 50) return 'success';
  if (battery > 30) return 'warning';
  return 'error';
}
