import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RespondChallengeResponse } from '../../../infra/api/login-api';
import { CURRENT_USER_LOCAL_STORAGE } from '../../../infra/api/user-api';
import { AppState } from '../../../infra/store';
import { setLoading } from '../../../infra/store/reducers/loading';
import { LoginService } from '../services/service';

const RespondChallenge = () => {
  const [showPassword, setShowPassword] = useState(false);
  const challengeData = useSelector((state: AppState) => state.loginReducer.challengeData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginService = new LoginService();

  console.log();

  const handleApiResponse = (response: RespondChallengeResponse) => {
    if (response.AuthenticationResult) {
      const email = JSON.parse(challengeData.challengeParameters.userAttributes)?.email ?? '';

      loginService.assignTokens(dispatch, response.AuthenticationResult);
      loginService
        .getUser(email)
        .then((user) => {
          if (user instanceof Error) throw user;
          localStorage.setItem(CURRENT_USER_LOCAL_STORAGE, JSON.stringify(user));
          navigate('/');
        })
        .catch((err) => alert(err.message));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const newPassword: string = data.get('password').toString().trim() ?? '';

    try {
      dispatch(setLoading(true));
      const response = await loginService.respondChallenge(newPassword, challengeData);
      if (response instanceof Error) throw response;
      handleApiResponse(response);
    } catch (err) {
      alert((err as Error).message);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: '100%'
      }}
    >
      <Typography component="h1" variant="h4">
        <strong>Bienvenido a Salud 4.0</strong>
      </Typography>
      <Typography component="p" variant="body1" sx={{ mt: 4 }}>
        Es necesario cambiar la contraseña,<br></br> por favor escribe tu nueva contraseña para ingresar.
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Contraseña"
          type={showPassword ? 'text' : 'password'}
          id="password"
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePassword} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 2 }} disableElevation={true}>
          Cambiar Contraseña
        </Button>
      </Box>
    </Box>
  );
};

export default RespondChallenge;
