import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LoadingState {
  isLoading: boolean;
  loadingText: string;
}

const initialState: LoadingState = {
  isLoading: false,
  loadingText: 'Cargando'
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      const newState = {
        ...state,
        isLoading: action.payload,
        loadingText: action.payload ? state.loadingText : 'Cargando'
      };

      return newState;
    },
    setLoadingText: (state, action: PayloadAction<string>) => {
      const newState = {
        ...state,
        loadingText: action.payload
      };

      return newState;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setLoading, setLoadingText } = loadingSlice.actions;

export default loadingSlice.reducer;
