import { Breadcrumbs, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export interface IBreadcrumbItem {
  Icon: JSX.Element;
  text: string;
  path: string;
}

export interface IBreacrumbProps {
  items: IBreadcrumbItem[];
  title: string;
}

const Breadcrumb = ({ items, title }: IBreacrumbProps) => {
  return (
    <div className="grid w-full h-full px-2">
      <Typography component="h1" variant="h5" style={{ color: 'var(--text-color)' }}>
        <strong>{title}</strong>
      </Typography>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ backgroundColor: 'var(--sub-bg-color)', padding: '1rem', borderRadius: '0.5rem', marginTop: '1rem' }}
      >
        {items.map((item, key) => (
          <Link
            key={key}
            style={{ display: 'flex', alignItems: 'center', gap: '8px', textDecoration: 'none' }}
            color="inherit"
            to={item.path}
          >
            {item.Icon}
            <Typography sx={{ color: 'var(--text-color)' }}>{item.text}</Typography>
          </Link>
        ))}
      </Breadcrumbs>
    </div>
  );
};

export default Breadcrumb;
