import HomeIcon from '@mui/icons-material/Home';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import React from 'react';

import NotesSVG from '../../assets/note.svg';
import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';
import NavigationMenu, { INavigationMenuItem } from '../../common/components/navigationMenu';

const menuItems: INavigationMenuItem[] = [
  {
    name: 'Preguntas Frecuentes',
    to: '/ayuda/faq',
    types: ['COMMON_USER', 'ADMIN'],
    svg: <img src={NotesSVG} alt="Preguntas Frecuentes" width={250} />
  }
];
const HelpMenu = () => {
  const breadcrumbItems: IBreadcrumbItem[] = [
    {
      Icon: <HomeIcon color="secondary" />,
      text: 'Home',
      path: '/'
    },
    {
      Icon: <LiveHelpIcon color="primary" />,
      text: 'Ayuda',
      path: '/ayuda'
    }
  ];

  return (
    <div className="grid w-full h-full px-2">
      <Breadcrumb items={breadcrumbItems} title="Menú de Monitoreo" />
      <NavigationMenu items={menuItems} />
    </div>
  );
};

export default HelpMenu;
