import { HealthAPI } from '../../../infra/api/health-api';
import { environment } from '../../../infra/environment/environment';

export class ReportDetailService {
  private api: HealthAPI;

  constructor() {
    this.api = new HealthAPI(environment);
  }

  getReportsById = (id: string) => {
    return this.api.getReportById(id);
  };
}
