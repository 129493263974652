import WarningIcon from '@mui/icons-material/Warning';
import { Alert, AlertTitle, Box, Chip, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../../infra/store';
import { Notification } from '../../models/notification';
import { calculateTimeElapsed, formatElapsedTime } from '../../utils/dates';

const WarningModal = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [currentNotification, setCurrentNotification] = useState<Notification>(undefined);
  const [humanReadableTime, setHumanReadableTime] = useState('');

  const notifications = useSelector((state: AppState) => state.websocketReducer.notifications);

  useEffect(() => {
    if (notifications.length === 0) return;

    setCurrentNotification(notifications[0]);

    if (notifications[0].severity === 'HIGH') setOpen(true);

    const elapsedTime = calculateTimeElapsed(new Date(Date.parse(notifications[0].timestamp)), new Date());
    setHumanReadableTime(formatElapsedTime(elapsedTime));
  }, [notifications]);

  if (!currentNotification) return <></>;

  if (currentNotification.severity !== 'HIGH') return <></>;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Alert
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 4
        }}
        variant="standard"
        color="error"
        icon={<WarningIcon fontSize="large" />}
      >
        <AlertTitle>
          <Typography variant="h4" component="h1">
            <strong>¡{currentNotification.workerName} está en Peligro!</strong>
          </Typography>
        </AlertTitle>
        <Typography variant="h5" component="p" sx={{ mt: 1 }}>
          <strong>Datos del Colaborador</strong>
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" component="p" sx={{ mt: 1 }}>
            Su ubicación actual es <strong>{currentNotification.zone}</strong>
          </Typography>
          <Typography variant="subtitle1" component="h1">
            {currentNotification.alertTitle}
          </Typography>
          <Typography variant="subtitle2" component="h1">
            {currentNotification.alertMessage}
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" component="p" sx={{ mt: 1 }}>
            <strong>Código de Colaborador: </strong> {currentNotification.workerId}
          </Typography>
          <Typography variant="subtitle1" component="p" sx={{ mt: 1 }}>
            <strong>Nombre del colaborador: </strong> {currentNotification.workerName}
          </Typography>
          <Typography variant="subtitle1" component="p" sx={{ mt: 1 }}>
            <Chip color="error" label={`Sucedió ${humanReadableTime}`}></Chip>
          </Typography>
        </Box>
      </Alert>
    </Modal>
  );
};

export default WarningModal;
