import { Environment } from './environment';

export const devEnvironment: Environment = {
  authEndpoint: 'https://v5w18gfgme.execute-api.us-east-1.amazonaws.com/dev',
  zoneEndpoint: 'https://62ns02zc9j.execute-api.us-east-1.amazonaws.com/dev',
  userEndpoint: 'https://v5w18gfgme.execute-api.us-east-1.amazonaws.com/dev',
  workerEndpoint: 'https://ckdvvlt5p0.execute-api.us-east-1.amazonaws.com/dev',
  healthEndpoint: 'https://45g9g2utgi.execute-api.us-east-1.amazonaws.com/dev',
  adminEndpoint: 'https://z7ukdqnr9i.execute-api.us-east-1.amazonaws.com/dev',
  recordWebsocketEndpoint:
    'wss://cjs2nndb20.execute-api.us-east-1.amazonaws.com/dev-am-salud-notification-websocket-stage/'
};
