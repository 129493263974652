import Cookies from 'js-cookie';

import { Environment } from '../../infra/environment/environment';

export type APIResponse<T> = {
  message: T;
  status: string;
};

export type ENDPOINTS = 'Auth' | 'Zone' | 'User' | 'Worker' | 'Health' | 'Admin';

export class API {
  private authURL: string;

  private zoneURL: string;

  private userURL: string;

  private workerURL: string;

  private healthURL: string;

  private adminURL: string;

  constructor(
    private env: Environment,
    private selectedEndpoint: ENDPOINTS
  ) {
    this.authURL = env.authEndpoint;
    this.zoneURL = env.zoneEndpoint;
    this.userURL = env.userEndpoint;
    this.workerURL = env.workerEndpoint;
    this.healthURL = env.healthEndpoint;
    this.adminURL = env.adminEndpoint;
  }

  private getEndpoint(): string {
    if (this.selectedEndpoint === 'Auth') return this.authURL;
    if (this.selectedEndpoint === 'Zone') return this.zoneURL;
    if (this.selectedEndpoint === 'User') return this.userURL;
    if (this.selectedEndpoint === 'Worker') return this.workerURL;
    if (this.selectedEndpoint === 'Health') return this.healthURL;
    if (this.selectedEndpoint === 'Admin') return this.adminURL;

    throw new Error('Invalid endpoint selected');
  }

  private async request<T>(
    method: string,
    endpoint: string,
    data?: any,
    headers?: HeadersInit
  ): Promise<APIResponse<T>> {
    const url = this.getEndpoint() + endpoint;
    const idToken = Cookies.get('id-token');
    const init: RequestInit = {
      method,
      headers: {
        ...headers,
        authorization: `${idToken}`,
        'Content-Type': `application/json`
      }
    };

    if (data) {
      init.body = JSON.stringify(data);
    }

    const response = await fetch(url, init);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return {
      message: await response.json(),
      status: 'success'
    };
  }

  protected async get<T>(endpoint: string, headers?: HeadersInit): Promise<APIResponse<T>> {
    return this.request<T>('GET', endpoint, undefined, headers);
  }

  protected async post<T>(endpoint: string, data: any, headers?: HeadersInit): Promise<APIResponse<T>> {
    return this.request<T>('POST', endpoint, data, headers);
  }

  protected async put<T>(endpoint: string, data: any, headers?: HeadersInit): Promise<APIResponse<T>> {
    return this.request<T>('PUT', endpoint, data, headers);
  }

  protected async delete<T>(endpoint: string, headers?: HeadersInit): Promise<APIResponse<T>> {
    return this.request<T>('DELETE', endpoint, undefined, headers);
  }
}
