import { Record } from '../../../common/models/record';
import { RealtimeWorker, Worker } from '../../../common/models/worker';
import { CreateRealtimeWorker } from '../../../common/utils/worker';
import { WorkerAPI } from '../../../infra/api/worker-api';
import { ZoneAPI } from '../../../infra/api/zone-api';
import { environment } from '../../../infra/environment/environment';

export class LiveMapService {
  private zonesApi: ZoneAPI;

  private workersApi: WorkerAPI;

  constructor() {
    this.zonesApi = new ZoneAPI(environment);
    this.workersApi = new WorkerAPI(environment);
  }

  /**
   * Return all the zones
   * @returns
   */
  getZones = () => {
    return this.zonesApi.getAll();
  };

  /**
   * Return all the workers
   * @returns
   */
  getWorkers = () => {
    return this.workersApi.getAll();
  };

  /**
   * Map the workers to realtime workers
   * @returns
   */
  useRealtimeWorkers = (workers: Worker[], records: { [id: string]: Record }): RealtimeWorker[] => {
    return workers.map((worker) => CreateRealtimeWorker(worker, records[worker.id]));
  };
}
