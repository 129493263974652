import { Box, Paper, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRowClassNameParams, GridToolbarExport } from '@mui/x-data-grid';
import React from 'react';

export interface IDataTableProps {
  title: string;
  data: any[];
  columns: GridColDef[];
  getRowClassName?: (params: GridRowClassNameParams<any>) => string;
}

const DataTable = ({ title, columns, data, getRowClassName }: IDataTableProps) => {
  function DataGridTitle() {
    return (
      <>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            padding: '1rem',
            boxSizing: 'border-box'
          }}
        >
          <Typography variant="h5" sx={{ width: '100%' }}>
            <strong>{title}</strong>
          </Typography>
          <Box sx={{ ml: 5, alignSelf: 'end', justifySelf: 'end' }}>
            <GridToolbarExport />
          </Box>
        </Box>
      </>
    );
  }

  return (
    <Paper elevation={0} sx={{ p: 1, boxSizing: 'border-box' }}>
      <DataGrid
        slots={{ toolbar: DataGridTitle }}
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 13
            }
          }
        }}
        pageSizeOptions={[5, 8, 13]}
        getRowClassName={getRowClassName}
        disableRowSelectionOnClick
      />
    </Paper>
  );
};
export default DataTable;
