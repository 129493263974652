import { Layer, LayersList } from '@deck.gl/core';
import { BitmapBoundingBox, BitmapLayer, PolygonLayer } from '@deck.gl/layers';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import MapaImg from '../../../assets/mapa.png';
import ArcelorMap, { defaultArcelorLocation } from '../../../common/components/companyMap';
import { RealtimeWorker, Worker } from '../../../common/models/worker';
import { Zone } from '../../../common/models/zone';
import { AppState } from '../../../infra/store';
import { setMapWorker } from '../../../infra/store/reducers/router';
import { useMeshLayer, useScatterplotLayer, useZonesAsPolygonColor, useZonesAsPolygons } from '../hooks/deckGLHooks';
import { LiveMapService } from '../services/realtimeService';

export interface IMapProps {
  zones: Zone[];
  workers: Worker[];
}

const defaultArcelorMapBounds: number[] & BitmapBoundingBox = [
  -83.75336377460397, 10.24693461107274, -83.74043911751508, 10.256144991138214
];

const Map = ({ zones, workers }: IMapProps) => {
  const service = new LiveMapService();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [goToViewState, setGoToViewState] = useState<any>(defaultArcelorLocation);

  const records = useSelector((state: AppState) => state.websocketReducer.records);
  const goToWorker = useSelector((state: AppState) => state.routerReducer.mapWorker);

  useEffect(() => {
    if (!goToWorker) {
      return;
    }
    setGoToViewState({
      latitude: goToWorker.lat,
      longitude: goToWorker.long,
      zoom: 19.5,
      pitch: 70
    });

    dispatch(setMapWorker(undefined));
  }, [goToWorker]);

  const [realtimeWorkers, setRealtimeWorkers] = useState<RealtimeWorker[]>(
    service.useRealtimeWorkers(workers, records)
  );

  const meshLayer = useMeshLayer(realtimeWorkers, navigate, dispatch);

  const bitmapLayer: Layer = new BitmapLayer({
    id: 'bitmap-layer',
    bounds: defaultArcelorMapBounds,
    image: MapaImg
  });

  const polygonLayer: Layer = new PolygonLayer({
    id: 'polygon-layer',
    data: useZonesAsPolygons(zones),
    getFillColor: (d: any) => useZonesAsPolygonColor(d.riskLevel) as any,
    pickable: true,
    stroked: true,
    filled: true,
    wireframe: true,
    extruded: true,
    lineWidthMinPixels: 1,
    getPolygon: (d) => d.contour,
    getElevation: () => 10,
    getLineColor: [80, 80, 80, 0],
    getLineWidth: 1,
    blending: true
  });

  const [layers, setLayers] = useState<LayersList>([meshLayer, bitmapLayer, polygonLayer]);

  useEffect(() => {
    setRealtimeWorkers(service.useRealtimeWorkers(workers, records));
  }, [records]);

  useEffect(() => {
    setLayers([
      useScatterplotLayer(realtimeWorkers),
      useMeshLayer(realtimeWorkers, navigate, dispatch),
      ...layers.filter((layer) => (layer as Layer).id !== 'mesh-layer' && (layer as Layer).id !== 'scatter-layer')
    ]);
  }, [realtimeWorkers]);

  if (!goToViewState) return <></>;

  return <ArcelorMap layers={layers} overrideInitialViewState={goToViewState} />;
};

export default Map;
