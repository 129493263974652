import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { enableMapSet } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import Theme from './features/theme';
import store from './infra/store';

const root = ReactDOM.createRoot(document.getElementById('principal-container') as HTMLElement);

enableMapSet();

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Theme />
    </Provider>
  </React.StrictMode>
);
