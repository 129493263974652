import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import HomeIcon from '@mui/icons-material/Home';
import MapIcon from '@mui/icons-material/Map';
import { Box, Button, Paper, Snackbar, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import EmptySVG from '../../assets/statistics_empty.svg';
import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';
import Show from '../../common/components/show';
import { Record, RecordTypes } from '../../common/models/record';
import { Zone } from '../../common/models/zone';
import { formatDate } from '../../common/utils/dates';
import { setLoading, setLoadingText } from '../../infra/store/reducers/loading';
import Map from './components/map';
import { HeatMapService } from './services/heatMapService';

const breadcrumbItems: IBreadcrumbItem[] = [
  {
    Icon: <HomeIcon color="secondary" />,
    text: 'Home',
    path: '/'
  },
  {
    Icon: <GpsFixedIcon color="secondary" />,
    text: 'Estadísticas',
    path: '/estadisticas'
  },
  {
    Icon: <MapIcon color="primary" />,
    text: 'Mapa',
    path: '/estadisticas/heatmap'
  }
];

const HeatMap = () => {
  const service = new HeatMapService();

  const [records, setRecords] = useState<Record[]>([]);
  const [zones, setZones] = useState<Zone[]>([]);
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [workerId, setWorkerId] = useState<string>('');
  const [pickedDate, setPickedDate] = useState<Dayjs | null>(dayjs(formatDate(new Date().toISOString(), 'YYYY-MM-DD')));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRecordsResponse = (response: Record[] | Error) => {
    if (response instanceof Error) throw response;
    setRecords(response);
  };

  const handleApiZoneResponse = (response: Zone[] | Error) => {
    if (response instanceof Error) throw response;
    setZones(response);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleRequestButton = () => {
    if (!workerId || workerId === '') {
      setSnackbarMessage('Escribe el código de colaborador');
      setOpen(true);
      return;
    }

    dispatch(setLoading(true));
    service
      .getRecords(workerId, pickedDate.toISOString(), pickedDate.toISOString(), RecordTypes.NONE)
      .then(handleRecordsResponse)
      .catch(() => navigate('/estadisticas'))
      .finally(() => dispatch(setLoading(false)));
  };

  useEffect(() => {
    if (!workerId || workerId === '') {
      return;
    }

    dispatch(setLoading(true));
    dispatch(setLoadingText('Cargando Registros'));

    const date = new Date();
    const prevDay = new Date();

    date.setDate(10);
    prevDay.setDate(10);
    prevDay.setHours(0, 0, 0, 0);
    date.setHours(23, 59, 59, 59);

    service
      .getZones()
      .then(handleApiZoneResponse)
      .catch(() => navigate('/estadisticas'))
      .finally(() => {
        dispatch(setLoading(false));
      });
  }, []);

  return (
    <Box>
      <Snackbar open={open} onClose={handleClick} autoHideDuration={6000} message={snackbarMessage} />
      <Breadcrumb items={breadcrumbItems} title="Estadísticas de Localización Diaria" />
      <Paper elevation={0} sx={{ mt: 2, p: 2 }}>
        <Typography sx={{ mb: 1 }} variant="h6" component="p">
          <strong>Buscar Registros (Día y Colaborador): </strong>
        </Typography>
        <Box
          sx={{
            mt: 3,
            display: 'flex',
            gap: '1rem'
          }}
        >
          <TextField
            sx={{ mt: 0, mb: 0 }}
            margin="normal"
            required
            fullWidth
            id="workerId"
            label="Código Colaborador"
            name="workerId"
            autoComplete="workerId"
            autoFocus
            value={workerId}
            onChange={(e) => setWorkerId(e.target.value)}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Fecha" value={pickedDate} onChange={(e) => setPickedDate(e)} />
          </LocalizationProvider>
          <Button color="primary" variant="contained" disableElevation onClick={handleRequestButton}>
            Buscar
          </Button>
        </Box>
      </Paper>
      <Show when={records.length > 0 && zones.length > 0}>
        <Map records={records} zones={zones} />
      </Show>
      <Show when={records.length === 0 || zones.length === 0}>
        <Paper
          elevation={0}
          sx={{
            mt: 2,
            p: 5,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center ',
            justifyItems: 'center',
            alignContent: 'center',
            alignItems: 'center'
          }}
        >
          <img src={EmptySVG} alt="Ilustración que indica que no hay datos" width={300} />
          <Typography>No hay datos disponibles. Intenta con otro colaborador o fecha</Typography>
        </Paper>
      </Show>
    </Box>
  );
};

export default HeatMap;
