import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React from 'react';

export interface ICircularLoading {
  text: string;
}

const CircularLoading = ({ text }: ICircularLoading) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
      <CircularProgress color="primary" size={80} />
      {text && (
        <Typography variant="h6" color="primary" mt={2}>
          {text}
        </Typography>
      )}
    </Box>
  );
};

export default CircularLoading;
