import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { RequestCodeResponse } from '../../../infra/api/login-api';
import { setLoading } from '../../../infra/store/reducers/loading';
import { setForgotEmail } from '../../../infra/store/reducers/login';
import { LoginService } from '../services/service';

export interface IRequestCodeProps {
  next: () => void;
}

const RequestCode = ({ next }: IRequestCodeProps) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const loginService = new LoginService();

  const handleApiResponse = (response: RequestCodeResponse) => {
    if (response.CodeDeliveryDetails) {
      next();
    }
  };

  const handleRequestCodeButton = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    try {
      dispatch(setLoading(true));
      dispatch(setForgotEmail(email));
      const response = await loginService.requestCode(email);
      if (response instanceof Error) throw response;
      handleApiResponse(response);
    } catch (err) {
      alert((err as Error).message);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <>
      <Typography component="h1" variant="h4" sx={{ mt: 4 }}>
        <strong>Recuperar Contraseña</strong>
      </Typography>
      <Typography component="p" variant="body1" sx={{ mt: 2 }}>
        Para recuperar la contraseña es necesario tu correo para verificar tu identidad.
        <br /> Te enviaremos un código de confirmación
      </Typography>
      <Box component="form" noValidate sx={{ width: '100%' }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Correo"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Box>
      <Button
        fullWidth
        variant="contained"
        sx={{ mt: 2 }}
        disableElevation={true}
        onClick={(e) => handleRequestCodeButton(e)}
      >
        Solicitar Código
      </Button>
    </>
  );
};

export default RequestCode;
