import { EM500XRecord } from './em500co2Record';
import { Notification } from './notification';
import { Record } from './record';

export type WebsocketResponse = {
  action: WebsocketResponseActions;
  payload: Notification | Record | EM500XRecord;
};

export enum WebsocketResponseActions {
  RECORD = 'record',
  NOTIFICATION = 'notification',
  EM500CO2 = 'em500co2-record',
  EM500UDL = 'em500udl-record'
}
