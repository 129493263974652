import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import HomeIcon from '@mui/icons-material/Home';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import PlaceIcon from '@mui/icons-material/Place';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';
import { Zone } from '../../common/models/zone';
import { AppState } from '../../infra/store';
import { setLoading } from '../../infra/store/reducers/loading';
import ZoneForm from './components/zoneForm';
import ZoneWorkers from './components/zoneWorkers';
import { ZoneService } from './services/zoneService';

const breadcrumbItems: IBreadcrumbItem[] = [
  {
    Icon: <HomeIcon color="secondary" />,
    text: 'Home',
    path: '/'
  },
  {
    Icon: <GpsFixedIcon color="secondary" />,
    text: 'Monitoreo',
    path: '/monitoreo'
  },
  {
    Icon: <PlaceIcon color="secondary" />,
    text: 'Zonas',
    path: '/monitoreo/zonas'
  }
];

export const ZONE_PREVIOUS_SCREEN = '/monitoreo/zonas';

const ZoneDetail = () => {
  const [zone, setZone] = useState<Zone | undefined>(undefined);
  const [title, setTitle] = useState<string>('');
  const [items, setItems] = useState<IBreadcrumbItem[]>(breadcrumbItems);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const service = new ZoneService();
  const id = useSelector((state: AppState) => state.routerReducer.zoneId);

  const handleApiResponse = (response: Zone | Error) => {
    if (response instanceof Error) throw response;
    const item: IBreadcrumbItem = {
      Icon: <MyLocationIcon color="primary" />,
      path: `/monitoreo/zona`,
      text: response.name
    };

    setTitle(response.name);
    setZone(response);
    setItems([...items, item]);
  };

  useEffect(() => {
    setZone(undefined);
  }, [id]);

  useEffect(() => {
    if (!!zone) return;
    if (!id) navigate(ZONE_PREVIOUS_SCREEN);

    dispatch(setLoading(true));
    service
      .getZoneById(id)
      .then(handleApiResponse)
      .catch(() => navigate(ZONE_PREVIOUS_SCREEN))
      .finally(() => dispatch(setLoading(false)));
  }, []);

  return (
    <div className="grid w-full h-full px-2">
      <Breadcrumb items={items} title={title} />
      {!!zone && (
        <Box>
          <ZoneForm zone={zone} setZone={setZone} />
          <ZoneWorkers zone={zone} />
        </Box>
      )}
    </div>
  );
};
export default ZoneDetail;
