import { API } from '../../common/libs/api';
import { Notice } from '../../common/models/notice';
import { Environment } from '../environment/environment';

export class AdminAPI extends API {
  constructor(env: Environment) {
    super(env, 'Admin');
  }

  /**
   * Get the active notices
   * @param props
   */
  public async getActiveNotices() {
    try {
      const response = await this.get<Notice[]>(`/notice`, {});

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  /**
   * Get the active notices
   * @param props
   */
  public async getNotices() {
    try {
      const response = await this.get<Notice[]>(`/notices`, {});

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  /**
   * Archive a notice by its id
   * @param props
   */
  public async archiveNotice(id: string) {
    try {
      const response = await this.delete<Notice[]>(`/notice?id=${id}`, {});

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  /**
   * Edit a notice
   * @param props
   */
  public async editNotice(notice: Notice) {
    try {
      const response = await this.put<Notice[]>(`/notice`, notice);

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  /**
   * Add a notice
   * @param props
   */
  public async addNotice(notice: Notice) {
    try {
      const response = await this.post<Notice[]>(`/notice`, notice);

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }
}
