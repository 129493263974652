export type Report = {
  id: string;
  active: number;
  creationDate: string;
  totalAmounts: number;
  amountRecords: number;
  amountLowHeartWarnings: number;
  amountManDownWarnings: number;
  amountSOSWarnings: number;
  amountInsufficientPermissions: number;
  temperature: ReportStatistic;
  heartRate: ReportStatistic;
  zoneId: string;
  zone: string;
  type: ReportType;
};
export type ReportStatistic = {
  minRange: number;
  maxRange: number;
  average: number;
};
export enum ReportType {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY'
}
