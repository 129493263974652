import './styles/navigationStyles.css';

import { Box, Paper } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export interface INavigationMenuItem {
  name: string;
  to: string;
  types: string[];
  svg: JSX.Element;
  hidden?: boolean;
}

export interface INavigationMenuProps {
  items: INavigationMenuItem[];
}

function NavigationMenu({ items }: INavigationMenuProps): JSX.Element {
  return (
    <Box sx={{ mt: 2 }}>
      <ul className="item-container">
        {items.map((item, key) => {
          if (item.hidden) return <></>;

          return (
            <Link to={item.to} key={key}>
              <li>
                <Paper elevation={0}>
                  {item.svg}
                  <p>{item.name}</p>
                </Paper>
              </li>
            </Link>
          );
        })}
      </ul>
    </Box>
  );
}

export default NavigationMenu;
