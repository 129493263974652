import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Notice, NoticeSeverity } from '../../../common/models/notice';
import { formatDate } from '../../../common/utils/dates';
import { setLoading } from '../../../infra/store/reducers/loading';
import { NoticeService } from '../services/noticeService';

const NoticeForm = () => {
  const [severity, setSeverity] = useState<NoticeSeverity>(NoticeSeverity.error);
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [startPickedDay, setStartPickedDay] = useState<Dayjs | null>(
    dayjs(formatDate(new Date().toISOString(), 'YYYY-MM-DD'))
  );
  const [endPickedDay, setEndPickedDay] = useState<Dayjs | null>(
    dayjs(formatDate(new Date().toISOString(), 'YYYY-MM-DD'))
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const service = new NoticeService();

  const handleEditUserSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (startPickedDay >= endPickedDay) {
      alert('Las fecha de inicio no puede ser despues de la de finalización.');
      return;
    }

    const newNotice: Notice = {
      severity,
      message,
      title,
      startDate: startPickedDay.toISOString(),
      endDate: endPickedDay.toISOString()
    };

    dispatch(setLoading(true));
    service.addNotice(newNotice).finally(() => {
      dispatch(setLoading(false));
      navigate('/administrador/avisos');
    });
  };

  return (
    <Paper elevation={0} sx={{ mt: 2, p: 2, boxSizing: 'border-box' }}>
      <Typography variant="h6" component="p">
        <strong>Detalles del Aviso</strong>
      </Typography>

      <Box component="form" noValidate>
        <TextField
          margin="normal"
          required
          fullWidth
          id="title"
          label="Título del Aviso"
          name="title"
          autoComplete="title"
          autoFocus
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="message"
          label="Mensaje del Aviso"
          name="message"
          autoComplete="message"
          onChange={(e) => setMessage(e.target.value)}
          value={message}
        />
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="severity">Importancia del Mensaje</InputLabel>
          <Select
            labelId="severity"
            id="severity"
            fullWidth
            label="Importancia del Mensaje"
            color="primary"
            value={severity}
            onChange={(e) => setSeverity(e.target.value as NoticeSeverity)}
          >
            <MenuItem value={'error'}>
              <Chip label="Alta Importancia" color="error" />
            </MenuItem>
            <MenuItem value={'warning'}>
              <Chip label="Media Importancia" color="warning" />
            </MenuItem>
            <MenuItem value={'success'}>
              <Chip label="Baja Importancia" color="success" />
            </MenuItem>
            <MenuItem value={'info'}>
              <Chip label="Informativo" color="info" />
            </MenuItem>
          </Select>
        </FormControl>

        <Box sx={{ mt: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Fecha de Inicio" value={startPickedDay} onChange={(e) => setStartPickedDay(e)} />
          </LocalizationProvider>
        </Box>

        <Box sx={{ mt: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Fecha de Finalización" value={endPickedDay} onChange={(e) => setEndPickedDay(e)} />
          </LocalizationProvider>
        </Box>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
          disableElevation={true}
          onClick={handleEditUserSubmit}
        >
          Crear Aviso
        </Button>
      </Box>
    </Paper>
  );
};

export default NoticeForm;
