export type User = {
  id?: string;
  role: UserRoles;
  deleted?: number;
  createdAt?: string;
  updatedAt?: string;
  name: string;
  email: string;
  job: string;
  phones: number[];
  profilePicture?: string;
};
export enum UserRoles {
  'ADMIN' = 'ADMIN',
  'COMMON_USER' = 'COMMON_USER'
}
