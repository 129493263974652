import Cookies from 'js-cookie';

import { API } from '../../common/libs/api';
import { ACCESS_TOKEN_NAME } from '../store/reducers/login';

export type LoginProps = {
  email: string;
  password: string;
};

export type RecoverPasswordProps = {
  email: string;
  password: string;
  code: string;
};

export type RespondChallengeProps = {
  challengeName: string;
  session: string;
  newPassword: string;
  username: string;
  challengeParameters: {
    USER_ID_FOR_SRP: string;
    requiredAttributes: string;
    userAttributes: string;
  };
};

export type AuthenticationResult = {
  AccessToken: string;
  ExpiresIn: number;
  IdToken: string;
  RefreshToken: string;
  TokenType: string;
};

export type LoginResponse = {
  ChallengeParameters: any;
  ChallengeName?: string;
  Session?: string;
  AuthenticationResult: AuthenticationResult;
};

export type RequestCodeResponse = {
  CodeDeliveryDetails: {
    AttributeName: string;
    DeliveryMedium: string;
    Destination: string;
  };
};

export type RespondChallengeResponse = {
  ChallengeParameters: any;
  AuthenticationResult: AuthenticationResult;
};

export class LoginAPI extends API {
  /**
   * Retrieves the access token by calling the auth endpoint
   * @param props
   */
  public async login(props: LoginProps) {
    try {
      const response = await this.post<LoginResponse>('/login', props);

      return response.message;
    } catch (error) {
      return new Error('Credenciales Inválidas');
    }
  }

  /**
   * Change the password when is user first login
   * @param props
   */
  public async respondChallenge(props: RespondChallengeProps) {
    try {
      const response = await this.post<RespondChallengeResponse>('/respond-challenge', props);

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  /**
   * Request a code to change the password.
   * If an error occurs it will not notify the user for sec purposes.
   * @param props
   */
  public async requestCode(email: string): Promise<RequestCodeResponse> {
    try {
      const response = await this.post<RequestCodeResponse>('/recover-password', { email });

      return response.message;
    } catch (error) {
      return {
        CodeDeliveryDetails: {
          AttributeName: 'email',
          DeliveryMedium: 'EMAIL',
          Destination: email
        }
      };
    }
  }

  /**
   * Using a code and new password, the user can recover its password.
   * @param props
   */
  public async recoverPassword(data: RecoverPasswordProps) {
    try {
      const response = await this.post<any>('/confirm-forgot', data);

      return response.message;
    } catch (error) {
      return new Error('El código usado es incorrecto.');
    }
  }

  /**
   * Change the current password.
   * @param props
   */
  public async changePassword(prevPassword: string, newPassword: string) {
    try {
      const accessToken = Cookies.get(ACCESS_TOKEN_NAME);
      const response = await this.post<any>('/change-password', {
        prevPassword,
        newPassword,
        accessToken
      });

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error, inténtalo de nuevo.');
    }
  }
}
