import { API } from '../../common/libs/api';
import { Machine } from '../../common/models/machine';
import { DeviceReport } from '../../common/models/machineReport';
import { Record, RecordTypes } from '../../common/models/record';
import { Report } from '../../common/models/report';
import { SmartWatch } from '../../common/models/smartWatch';
import { Environment } from '../environment/environment';

export class HealthAPI extends API {
  constructor(env: Environment) {
    super(env, 'Health');
  }

  /**
   * Get all the records
   * @param props
   */
  public async getRecords(workerId: string, startDate: string, endDate: string, type: RecordTypes) {
    try {
      const response = await this.get<Record[]>(
        `/records?workerId=${workerId}&&startDate=${startDate}&&endDate=${endDate}&&type=${type}`,
        {}
      );

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  /**
   * Get all the machines
   * @param props
   */
  public async getMachines() {
    try {
      const response = await this.get<Machine[]>(`/machines`, {});

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  /**
   * Get all the smart watches
   * @param props
   */
  public async getUnusedSmartwatches() {
    try {
      const response = await this.get<SmartWatch[]>(`/smartwatches `, {});

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  /**
   * Get all the machine by its id
   * @param props
   */
  public async getMachineById(id: string) {
    try {
      const response = await this.get<Machine>(`/machine?id=${id}`, {});

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  /**
   * Get reports using filters
   * @param props
   */
  public async getReportFiltered(type: string, isoDay: string) {
    try {
      const response = await this.get<Report[]>(`/reports?type=${type}&&isoDay=${isoDay}`, {});

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  /**
   * Get report by its id
   * @param props
   */
  public async getReportById(id: string) {
    try {
      const response = await this.get<Report>(`/report?id=${id}`, {});

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }

  /**
   * Get devices reports by its type
   * @param props
   */
  public async getDevicesReportByType(type: string) {
    try {
      const response = await this.get<DeviceReport[]>(`/devices?type=${type}`, {});

      return response.message;
    } catch (error) {
      return new Error('Ha ocurrido un error inténtalo de nuevo.');
    }
  }
}
