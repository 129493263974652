/**
 * Based on the worker situation will return a readable state
 * @param type the worker state
 * @returns
 */
export function useTypeSituation(type: string): string {
  if (type === 'NONE') return 'Ninguna';
  if (type === 'LOW_HEART_RATE') return 'Bajo Ritmo Cardíaco';
  if (type === 'MAN_DOWN') return 'Ha sufrido una caída';
  if (type === 'SOS') return 'NECESITA AYUDA';
  if (type === 'INSUFFICIENT_PERMISSIONS') return 'En zona restringida';
  if (type === 'ANORMAL_TEMPERATURE') return 'Temperatura anormal';
  if (type === 'ANORMAL_HEART_RATE') return 'Ritmo Cardíaco Anormal';

  return 'Situación Desconocida';
}
