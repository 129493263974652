import HomeIcon from '@mui/icons-material/Home';
import { FormControl, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import AdminSVG from '../../assets/admin_art.svg';
import FaqSVG from '../../assets/faq.svg';
import MonitorSVG from '../../assets/logistics-flatline.svg';
import ProfileSVG from '../../assets/profile_art.svg';
import StatisticsSVG from '../../assets/team-presentation-flatline-454c5.svg';
import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';
import NavigationMenu, { INavigationMenuItem } from '../../common/components/navigationMenu';
import ReportGrid from '../../common/components/reportGrid';
import { Report } from '../../common/models/report';
import { Zone } from '../../common/models/zone';
import { setLoading, setLoadingText } from '../../infra/store/reducers/loading';
import { ReportService } from './services/reportsService';

const menuItems: INavigationMenuItem[] = [
  {
    name: 'Monitoreo',
    to: '/monitoreo',
    types: ['COMMON_USER', 'ADMIN'],
    svg: <img src={MonitorSVG} alt="Monitoreo" width={250} />
  },
  {
    name: 'Estadísticas',
    to: '/estadisticas',
    types: ['COMMON_USER', 'ADMIN'],
    svg: <img src={StatisticsSVG} alt="Estadísticas" width={250} />
  },
  {
    name: 'Administrador',
    to: '/administrador',
    types: ['ADMIN'],
    svg: <img src={AdminSVG} alt="Administrador" width={250} />
  },
  {
    name: 'Ayuda',
    to: '/ayuda',
    types: ['COMMON_USER', 'ADMIN'],
    svg: <img src={FaqSVG} alt="FAQ" width={250} />
  },
  {
    name: 'Mi Usuario',
    to: '/perfil',
    types: ['COMMON_USER', 'ADMIN'],
    svg: <img src={ProfileSVG} alt="Perfil" width={250} />
  }
];
const Home = () => {
  const [report, setReport] = useState<Report>(undefined);
  const [zones, setZones] = useState<Zone[]>([]);
  const [selectedZone, setSelectedZone] = useState<string>('');

  const service = new ReportService();
  const dispatch = useDispatch();

  const breadcrumbItems: IBreadcrumbItem[] = [
    {
      Icon: <HomeIcon color="primary" />,
      text: 'Home',
      path: '/'
    }
  ];

  const handleApiResponse = (response: Report[] | Error) => {
    if (response instanceof Error) throw response;
    setReport(response[0]);
    setSelectedZone(response[0]?.zoneId ?? '13');
  };

  const handleApiChangeResponse = (response: Report[] | Error) => {
    if (response instanceof Error) throw response;
    setReport(response.filter((re) => re.zoneId === selectedZone)[0]);
  };

  const handleZonesApiResponse = (response: Zone[] | Error) => {
    if (response instanceof Error) throw response;
    setZones(response);
  };

  useEffect(() => {
    if (selectedZone === report?.id) return;

    service
      .getYesterdayReports()
      .then(handleApiChangeResponse)
      .finally(() => dispatch(setLoading(false)));
  }, [selectedZone]);

  useEffect(() => {
    if (!!report) return;

    dispatch(setLoading(true));
    dispatch(setLoadingText('Cargando Reportes'));
    service
      .getYesterdayReports()
      .then((res) => {
        handleApiResponse(res);

        dispatch(setLoadingText('Cargando Zonas'));
        service.getZones().then(handleZonesApiResponse);
      })
      .finally(() => dispatch(setLoading(false)));
  }, []);

  return (
    <div className="grid w-full h-full px-2">
      <Breadcrumb items={breadcrumbItems} title="Inicio" />
      <NavigationMenu items={menuItems} />
      <Paper sx={{ p: 1, boxSizing: 'border-box', mt: 2 }} elevation={0}>
        <Typography sx={{ mt: 1, px: 2 }} variant="h5">
          <strong>Reporte de Ayer</strong>
        </Typography>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="riskLevel">Zona</InputLabel>
          <Select
            labelId="zone"
            id="zone"
            fullWidth
            label="Zona"
            color="primary"
            value={selectedZone}
            onChange={(e) => setSelectedZone(e.target.value)}
          >
            {zones.map((zone, key) => (
              <MenuItem value={zone.id} key={key}>
                {zone.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ReportGrid report={report} />
      </Paper>
    </div>
  );
};

export default Home;
