import AddCircleIcon from '@mui/icons-material/AddCircle';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CampaignIcon from '@mui/icons-material/Campaign';
import HomeIcon from '@mui/icons-material/Home';
import React from 'react';

import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';
import UserForm from './components/noticeForm';

export const USER_PREVIOUS_SCREEN = '/administrador/usuarios';

const breadcrumbItems: IBreadcrumbItem[] = [
  {
    Icon: <HomeIcon color="secondary" />,
    text: 'Home',
    path: '/'
  },
  {
    Icon: <AdminPanelSettingsIcon color="secondary" />,
    text: 'Administrador',
    path: '/administrador'
  },
  {
    Icon: <CampaignIcon color="secondary" />,
    text: 'Avisos',
    path: '/administrador/avisos'
  },
  {
    Icon: <AddCircleIcon color="primary" />,
    text: 'Crear Aviso',
    path: '/administrador/usuario/crear'
  }
];

const CreateNotice = () => {
  return (
    <div>
      <Breadcrumb items={breadcrumbItems} title="Crear Aviso" />
      <UserForm />
    </div>
  );
};
export default CreateNotice;
