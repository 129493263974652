import { User } from '../../../common/models/user';
import { UserAPI } from '../../../infra/api/user-api';
import { environment } from '../../../infra/environment/environment';

export class UserService {
  private api: UserAPI;

  constructor() {
    this.api = new UserAPI(environment);
  }

  getUserById = (email: string) => {
    return this.api.getUser(email);
  };

  addUser = (user: User) => {
    return this.api.createUser(user);
  };
}
