import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { LoginProps, LoginResponse } from '../../../infra/api/login-api';
import { CURRENT_USER_LOCAL_STORAGE } from '../../../infra/api/user-api';
import { setLoading, setLoadingText } from '../../../infra/store/reducers/loading';
import { setChallengeData } from '../../../infra/store/reducers/login';
import { LoginPageWindows } from '../pages/loginPage';
import { LoginService } from '../services/service';

export interface ILoginProps {
  setWindow: React.Dispatch<React.SetStateAction<LoginPageWindows>>;
}

const Login = ({ setWindow }: ILoginProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginService = new LoginService();

  /**
   * Handle the login result
   * @param response
   */
  const handleLoginApiResponse = (response: LoginResponse, email: string) => {
    if (response.AuthenticationResult) {
      loginService.assignTokens(dispatch, response.AuthenticationResult);
      loginService
        .getUser(email)
        .then((user) => {
          if (user instanceof Error) throw user;
          localStorage.setItem(CURRENT_USER_LOCAL_STORAGE, JSON.stringify(user));
          navigate('/');
        })
        .catch((err) => alert(err.message));
    } else if (response.ChallengeParameters) {
      setWindow('RESPOND_CHALLENGE');
      dispatch(
        setChallengeData({
          challengeData: {
            challengeName: response.ChallengeName ?? '',
            challengeParameters: response.ChallengeParameters,
            session: response.Session ?? ''
          }
        })
      );
    }
  };

  /**
   * Handle the login button event
   * @param event
   */
  const handleLoginSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email')?.toString().trim() ?? '';

    const props: LoginProps = {
      email: email,
      password: data.get('password')?.toString().trim() ?? ''
    };

    try {
      dispatch(setLoading(true));
      dispatch(setLoadingText('Iniciando Sesión'));
      const response = await loginService.login(props);
      if (response instanceof Error) throw response;
      handleLoginApiResponse(response, email);
    } catch (err) {
      alert((err as Error).message);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
      }}
    >
      <Typography component="h1" variant="h4">
        <strong>Iniciar Sesión</strong>
      </Typography>
      <Box component="form" onSubmit={handleLoginSubmit} noValidate sx={{ mt: 2 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Correo"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Contraseña"
          type={showPassword ? 'text' : 'password'}
          id="password"
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePassword} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 2 }} disableElevation={true}>
          Iniciar Sesión
        </Button>
        <Button
          fullWidth
          variant="text"
          sx={{ mt: 2, mb: 2 }}
          disableElevation={true}
          onClick={() => setWindow('FORGOT_PASSWORD')}
        >
          Olvidé la Contraseña
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
