// Layout.js
import { CssBaseline } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import WarningModal from '../../common/components/warningModal';
import DesktopDashboard from '../dashboardDesktop';
import MobileDashboard from '../dashboardMobile';
import Home from '../home';
import { items } from './components/listItems';

const Dashboard = () => {
  // Initialize the state variable with the current window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { pathname = '' } = useLocation();
  const [GoTo, SetGoTo] = useState<JSX.Element>(<Home />);

  // Function to update the windowWidth state
  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  // Add an event listener to update the state when the window is resized
  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  useEffect(() => {
    let component = <Home />;

    for (let i = 0; i < items.length; i++) {
      const curItem = items[i];

      if (`/${curItem.path}` === pathname) {
        component = curItem.component;
        break;
      }

      for (let j = 0; j < curItem.elements.length; j++) {
        const subItem = curItem.elements[j];

        if (`/${subItem.path}` === pathname) {
          component = subItem.component;
          break;
        }
      }
    }

    SetGoTo(component);
  }, [pathname]);

  const dashboard = useMemo(() => {
    const averageMobileWidth = 1400;

    if (windowWidth <= averageMobileWidth) return <MobileDashboard>{GoTo}</MobileDashboard>;

    return <DesktopDashboard>{GoTo}</DesktopDashboard>;
  }, [windowWidth, GoTo]);

  return (
    <div className="qa-dashboard-container">
      <CssBaseline />
      <WarningModal />
      {dashboard}
    </div>
  );
};

export default Dashboard;
