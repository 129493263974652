import { Notice } from '../../../common/models/notice';
import { AdminAPI } from '../../../infra/api/admin-api';
import { environment } from '../../../infra/environment/environment';

export class NoticeService {
  private api: AdminAPI;

  constructor() {
    this.api = new AdminAPI(environment);
  }

  addNotice = (notice: Notice) => {
    return this.api.addNotice(notice);
  };
}
