import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Alert, AlertColor, AlertTitle, Chip, Grow, Typography } from '@mui/material';
import React, { useEffect } from 'react';

import AlarmSoundFile from '../../../../assets/alarm.mp3';
import { Notification, NotificationSeverity } from '../../../models/notification';
import { calculateTimeElapsed, formatElapsedTime } from '../../../utils/dates';

export interface INotificationItemProps {
  notification: Notification;
}

export type SeverityMapperData = {
  type: string;
  icon: React.ReactNode;
};

const alarmSound = new Audio(AlarmSoundFile);

const NotificationItem = ({ notification }: INotificationItemProps) => {
  const severityMapper: Map<NotificationSeverity, SeverityMapperData> = new Map<
    NotificationSeverity,
    SeverityMapperData
  >();

  useEffect(() => {
    if (!notification || notification.severity !== 'HIGH') return;

    alarmSound.play();
  }, []);

  severityMapper.set('LOW', {
    type: 'success',
    icon: <SentimentSatisfiedIcon />
  });
  severityMapper.set('MEDIUM', {
    type: 'warning',
    icon: <SentimentNeutralIcon />
  });
  severityMapper.set('HIGH', {
    type: 'error',
    icon: <SentimentVeryDissatisfiedIcon />
  });

  if (!notification) return <></>;

  const current = severityMapper.get(notification.severity);

  const elapsedTime = calculateTimeElapsed(new Date(Date.parse(notification.timestamp)), new Date());
  const humanReadableTime = formatElapsedTime(elapsedTime);

  return (
    <Grow in={!!notification} style={{ transformOrigin: '0 0 0' }} {...(!!notification ? { timeout: 1500 } : {})}>
      <Alert
        severity={current.type as AlertColor}
        sx={{ boxSizing: 'border-box', width: '100%', mt: 1 }}
        icon={current.icon}
      >
        <AlertTitle>{notification.alertTitle}</AlertTitle>
        <Typography component="p" variant="body1" sx={{ mt: 1 }}>
          <strong>{notification.workerName}</strong>
        </Typography>
        <Typography component="p" variant="body1" sx={{ mt: 1 }}>
          {notification.alertMessage}
        </Typography>
        <Typography component="p" variant="caption" sx={{ mt: 1 }}>
          {humanReadableTime}
        </Typography>
        <Chip label={notification.zone} variant="filled" sx={{ mt: 1, color: 'black' }} />
      </Alert>
    </Grow>
  );
};

export default NotificationItem;
