import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import DataTable from '../../../common/components/dataTable';
import { Worker } from '../../../common/models/worker';
import { Zone } from '../../../common/models/zone';
import { ZonePermissions } from '../../../common/models/zonePermission';
import { setLoading } from '../../../infra/store/reducers/loading';
import { ZONE_PREVIOUS_SCREEN } from '../index';
import { ZoneService } from '../services/zoneService';

export interface IZoneFormProps {
  zone: Zone;
}

const ZoneWorkers = ({ zone }: IZoneFormProps) => {
  const service = new ZoneService();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [workers, setWorkers] = useState<Worker[]>([]);
  const [zonePermissions, setZonePermissions] = useState<ZonePermissions[]>([]);
  const [filteredWorkers, setFilteredWorkers] = useState<Worker[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [selectedWorker, setSelectedWorker] = useState<string>('');
  const handleApiResponse = (response: Worker[] | Error) => {
    if (response instanceof Error) throw response;

    setWorkers(response);
    setFilteredWorkers(response);
    setSelectedWorker(response[0].id);
  };
  const handleApiZoneResponse = (response: ZonePermissions[] | Error) => {
    if (response instanceof Error) throw response;

    setZonePermissions(response);
  };
  const handleAddWorkerEvent = () => {
    dispatch(setLoading(true));
    service
      .addZonePermissions(selectedWorker, zone.id)
      .then(() => {
        service.getZonePermissions(zone.id).then(handleApiZoneResponse);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  useEffect(() => {
    if (workers.length > 0) return;

    dispatch(setLoading(true));
    service
      .getWorkers()
      .then((res) => {
        handleApiResponse(res);

        service.getZonePermissions(zone.id).then(handleApiZoneResponse);
      })
      .catch(() => navigate(ZONE_PREVIOUS_SCREEN))
      .finally(() => dispatch(setLoading(false)));
  }, []);

  useEffect(() => {
    if (filter === '') setFilteredWorkers(workers);
    else
      setFilteredWorkers(
        workers.filter((worker) =>
          `${worker.id} - ${worker.fullname.toLocaleLowerCase()}`.includes(filter.toLocaleLowerCase())
        )
      );
  }, [filter]);

  const columns: GridColDef[] = [
    {
      field: 'workerId',
      headerName: 'Codigo',
      flex: 2
    },
    {
      field: 'name',
      headerName: 'Nombre Completo',
      flex: 2,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return <Typography>{workers.filter((worker) => worker.id === params.row.workerId)[0].fullname}</Typography>;
      }
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const onClick = () => {
          dispatch(setLoading(true));
          service
            .archiveZonePermission(params.row.id)
            .then(() => {
              service.getZonePermissions(zone.id).then(handleApiZoneResponse);
            })
            .finally(() => dispatch(setLoading(false)));
          console.log(params.row);
        };

        return (
          <IconButton onClick={onClick}>
            <DeleteIcon color="primary" />
          </IconButton>
        );
      }
    }
  ];

  return (
    <Paper elevation={0} sx={{ mt: 2, p: 2, boxSizing: 'border-box' }}>
      <Typography variant="h6" component="h2">
        <strong>Administrar Colaboradores Permitidos</strong>
      </Typography>
      {!zone.hasRestrictedAccess && (
        <Typography variant="caption" component="p" sx={{ mt: 1 }}>
          Esta sección se encuentra deshabilitada. Para habilitarla habilita el checkbox de{' '}
          <strong>&quot;Tiene Acceso Restringido&quot;</strong>. Y luego dale a Guardar Cambios.
          <br />
        </Typography>
      )}
      <Box component="form" noValidate>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="riskLevel">Buscar Colaborador</InputLabel>
          <Select
            labelId="worker"
            id="worker"
            fullWidth
            label="Buscar Colaborador"
            color="primary"
            MenuProps={{ autoFocus: false }}
            value={selectedWorker}
            onChange={(e) => setSelectedWorker(e.target.value)}
          >
            <ListSubheader>
              <TextField
                size="small"
                // Autofocus on textfield
                autoFocus
                placeholder="Buscar colaborador..."
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
                onKeyDown={(e) => {
                  if (e.key !== 'Escape') {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                  }
                }}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
            </ListSubheader>
            {filteredWorkers.map((worker) => (
              <MenuItem value={worker.id} key={worker.id}>
                {worker.id} - {worker.fullname}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
          disableElevation={true}
          onClick={(e) => {
            e.preventDefault();
            handleAddWorkerEvent();
          }}
        >
          Agregar Colaborador
        </Button>
        <DataTable title="Lista de Colaboradores Permitidos" columns={columns} data={zonePermissions} />
      </Box>
    </Paper>
  );
};
export default ZoneWorkers;
