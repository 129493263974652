/**
 * Le agrega un cero a la izquierda si es menor que 10
 * @param {*} num
 * @returns
 */
export function getDateNumber(num: number): string | number {
  return num < 10 ? `0${num}` : num;
}

/**
 * Permite aplicar formato a las fechas de tipo ISO
 * @param {string} date
 * @param {string} format
 * @returns {string}
 */
export function formatDate(date: string, format: string): string {
  const meses = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ];
  const curDate = new Date(date);
  let currentMonth = '[NO EXISTE ESE MES]';

  if (curDate.getMonth() >= 0 && curDate.getMonth() <= 11) {
    currentMonth = meses[curDate.getMonth()];
  }

  let formats = [
    {
      template: 'YYYY',
      replace: curDate.getFullYear()
    },
    {
      template: 'YY',
      replace: parseInt((curDate.getFullYear() % 100).toString())
    },
    {
      template: 'MM',
      replace: getDateNumber(curDate.getUTCMonth() + 1)
    },
    {
      template: 'DD',
      replace: getDateNumber(curDate.getUTCDate())
    },
    {
      template: 'HH',
      replace: getDateNumber(curDate.getUTCHours())
    },
    {
      template: 'mm',
      replace: getDateNumber(curDate.getUTCMinutes())
    },
    {
      template: 'ss',
      replace: getDateNumber(curDate.getUTCSeconds())
    },
    {
      template: 'mil',
      replace: curDate.getUTCMilliseconds()
    },
    {
      template: '_month_',
      replace: currentMonth
    }
  ];

  let result = format;

  for (let i = 0; i < formats.length; i++) {
    let { template, replace } = formats[i];

    result = result.replace(template, replace.toString());
  }

  return result;
}

/**
 * Calculate the hours and minutes elapsed between 2 dates
 * @param startDate
 * @param endDate
 * @returns
 */
export function calculateTimeElapsed(startDate: Date, endDate: Date): number[] {
  const timeDifference = endDate.getTime() - startDate.getTime();

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  return [hours, minutes, seconds];
}

/**
 * Returns a human readbale elapsed time
 * @param elapsedTime
 * @returns
 */
export function formatElapsedTime(elapsedTime: number[]): string {
  const [hours, minutes, seconds] = elapsedTime;

  const formatUnit = (value: number, unit: string): string => `${value} ${unit}${value !== 1 ? 's' : ''}`;

  const formattedHours = hours > 0 ? formatUnit(hours, 'hora') + (minutes > 0 || seconds > 0 ? ' y ' : '') : '';
  const formattedMinutes = minutes > 0 ? formatUnit(minutes, 'minuto') + (seconds > 0 ? ' y ' : '') : '';
  const formattedSeconds = formatUnit(seconds, 'segundo');

  return `Hace ${formattedHours}${formattedMinutes}${formattedSeconds}`;
}
