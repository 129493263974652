import { LayersList } from '@deck.gl/core';
import DeckGL from '@deck.gl/react';
import { Box, Paper } from '@mui/material';
import { useEffect, useState } from 'react';

export interface IMapProps {
  layers: LayersList;
  overrideInitialViewState?: any;
}

export const defaultArcelorLocation = {
  latitude: 10.25238867447405,
  longitude: -83.74523415762124,
  zoom: 16.5,
  pitch: 40
};

const ArcelorMap = ({ layers, overrideInitialViewState }: IMapProps) => {
  const [initialViewState] = useState(defaultArcelorLocation);
  const [mapLayer, setMapLayers] = useState([]);

  useEffect(() => {
    setMapLayers([...layers]);
  }, [layers]);

  if (mapLayer.length === 0) return <></>;

  return (
    <Paper
      elevation={0}
      sx={{
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        display: 'grid',
        gridTemplateColumns: '100%',
        gridTemplateRows: '70vh',
        justifyContent: 'flex-start',
        minHeight: '60vh',
        overflowY: 'auto',
        position: 'relative',
        width: '100%',
        mt: 2
      }}
    >
      <Box>
        <DeckGL
          controller={true}
          layers={mapLayer}
          style={{
            borderRadius: '0.5rem',
            maxWidth: '100%',
            minHeight: '60vh'
          }}
          initialViewState={overrideInitialViewState ?? initialViewState}
          getTooltip={({ object }) => !!object?.tooltip && `${object.tooltip}`}
        />
      </Box>
    </Paper>
  );
};

export default ArcelorMap;
