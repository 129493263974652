import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import LockIcon from '@mui/icons-material/Lock';
import SosIcon from '@mui/icons-material/Sos';
import SportsMartialArtsIcon from '@mui/icons-material/SportsMartialArts';
import { Alert, AlertTitle, Box, Fade, Paper, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';

import TimeSVG from '../../../assets/time-flatline.svg';
import { Report } from '../../models/report';
import Show from '../show';
import {
  useAnormalHeartRateSeverity,
  useInsufficientPermissionsRateSeverity,
  useManDownRateSeverity,
  useSOSSeverity
} from './hooks/useSeverities';
export interface IReportFormProps {
  report: Report;
}

const ReportGrid = ({ report }: IReportFormProps) => {
  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        boxSizing: 'border-box',
        display: 'flex',
        justifyItems: 'start',
        justifyContent: 'start',
        width: '100%',
        flexDirection: 'column'
      }}
    >
      <Typography variant="h6" component="p">
        <strong>Información del Reporte</strong>
      </Typography>
      <Show when={!report}>
        <Fade in={!report}>
          <Box
            sx={{
              p: 2,
              boxSizing: 'border-box',
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              flexDirection: 'column'
            }}
          >
            <img src={TimeSVG} alt="Ilustración de trabajador" width="20%" style={{ alignSelf: 'center' }} />
            <Typography variant="h6" component="h2">
              Parece que este reporte aún no está listo
            </Typography>
          </Box>
        </Fade>
      </Show>
      <Show when={!!report}>
        <Fade in={!!report}>
          {!!report && (
            <Box
              component="form"
              noValidate
              sx={{
                boxSizing: 'border-box',
                display: 'flex',
                justifyItems: 'center',
                justifyContent: 'center',
                width: '100%',
                flexDirection: 'column'
              }}
            >
              <Box sx={{ my: 2 }}>
                {' '}
                <Typography variant="body1" component="p" sx={{ mt: 2 }}>
                  <strong>Estado de los Eventos Ocurridos en {report.zone}</strong>
                </Typography>
                <Grid2 container sx={{ mt: 1 }} columns={{ xs: 4, sm: 8, md: 12 }} spacing={{ xs: 1, md: 1 }}>
                  <Grid2 xs={4}>
                    <Alert
                      severity={useSOSSeverity(report.amountSOSWarnings)}
                      sx={{ boxSizing: 'border-box', p: 1 }}
                      variant="filled"
                      icon={<SosIcon />}
                    >
                      <AlertTitle>Cantidad de Solicitudes de Auxilio</AlertTitle>
                      <Typography component="p" variant="body1" sx={{ mt: 1 }}>
                        {report.amountSOSWarnings} solicitudes de auxilio
                      </Typography>
                    </Alert>
                  </Grid2>
                  <Grid2 xs={4}>
                    <Alert
                      severity={useAnormalHeartRateSeverity(report.amountLowHeartWarnings)}
                      sx={{ boxSizing: 'border-box', p: 1 }}
                      variant="filled"
                      icon={<HeartBrokenIcon />}
                    >
                      <AlertTitle>Cantidad de Ritmos Cardíacos Anormales</AlertTitle>
                      <Typography component="p" variant="body1" sx={{ mt: 1 }}>
                        {report.amountLowHeartWarnings} ritmos cardíacos anormales
                      </Typography>
                    </Alert>
                  </Grid2>
                  <Grid2 xs={4}>
                    <Alert
                      severity={useManDownRateSeverity(report.amountManDownWarnings)}
                      sx={{ boxSizing: 'border-box', p: 1 }}
                      variant="filled"
                      icon={<SportsMartialArtsIcon />}
                    >
                      <AlertTitle>Cantidad de Personas Caídas</AlertTitle>
                      <Typography component="p" variant="body1" sx={{ mt: 1 }}>
                        {report.amountManDownWarnings} personas caídas
                      </Typography>
                    </Alert>
                  </Grid2>
                  <Grid2 xs={4}>
                    <Alert
                      severity={useInsufficientPermissionsRateSeverity(report.amountInsufficientPermissions)}
                      sx={{ boxSizing: 'border-box', p: 1 }}
                      variant="filled"
                      icon={<LockIcon />}
                    >
                      <AlertTitle>Cantidad de Personas que Entraron sin Permiso</AlertTitle>
                      <Typography component="p" variant="body1" sx={{ mt: 1 }}>
                        {report.amountInsufficientPermissions} personas entraron sin permiso
                      </Typography>
                    </Alert>
                  </Grid2>
                </Grid2>
              </Box>

              <Box sx={{ my: 2 }}>
                {' '}
                <Typography variant="body1" component="p" sx={{ mt: 2 }}>
                  <strong>Estado de salud en {report.zone}</strong>
                </Typography>
                <Grid2 container sx={{ mt: 1 }} columns={{ xs: 4, sm: 8, md: 12 }} spacing={{ xs: 1, md: 1 }}>
                  <Grid2 xs={4}>
                    <Alert
                      color="info"
                      sx={{ boxSizing: 'border-box', p: 1 }}
                      variant="filled"
                      icon={<DeviceThermostatIcon />}
                    >
                      <AlertTitle>Rango de Temperatura Corporal</AlertTitle>
                      <Typography component="p" variant="body1" sx={{ mt: 1 }}>
                        {report.temperature.minRange}&deg;C a {report.temperature.maxRange}&deg;C
                      </Typography>
                      <Typography component="p" variant="caption" sx={{ mt: 1 }}>
                        Promedio de {report.temperature.average.toFixed(2)}&deg;C
                      </Typography>
                    </Alert>
                  </Grid2>
                  <Grid2 xs={4}>
                    <Alert
                      color="info"
                      sx={{ boxSizing: 'border-box', p: 1 }}
                      variant="filled"
                      icon={<FavoriteBorderIcon />}
                    >
                      <AlertTitle>Rango de Ritmo Cardíaco</AlertTitle>
                      <Typography component="p" variant="body1" sx={{ mt: 1 }}>
                        {report.heartRate.minRange}lpm a {report.heartRate.maxRange}lpm
                      </Typography>
                      <Typography component="p" variant="caption" sx={{ mt: 1 }}>
                        Promedio de {report.heartRate.average.toFixed(0)}lpm
                      </Typography>
                    </Alert>
                  </Grid2>
                </Grid2>
              </Box>
            </Box>
          )}
        </Fade>
      </Show>
    </Paper>
  );
};
export default ReportGrid;
