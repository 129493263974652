import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export type ChallengeData = {
  session: string;
  challengeName: string;
  challengeParameters: any;
};

export interface LoginState {
  idToken?: string;
  refreshToken?: string;
  accessToken?: string;
  challengeData?: ChallengeData;
  forgotUserEmail?: string;
}

export interface SetTokensProps {
  idToken: string;
  refreshToken: string;
  accessToken: string;
}

export interface SetChallengeParametersProps {
  challengeData: ChallengeData;
}

export const ACCESS_TOKEN_NAME = 'access-token';
export const REFRESH_TOKEN_NAME = 'refresh-token';
export const ID_TOKEN_NAME = 'id-token';

const initialAccessToken = Cookies.get(ACCESS_TOKEN_NAME); // Get the access token from a cookie
const initialRefreshToken = Cookies.get(REFRESH_TOKEN_NAME); // Get the refresh token from a cookie
const initialIdToken = Cookies.get(ID_TOKEN_NAME); // Get the id token from a cookie

const initialState: LoginState = {
  accessToken: initialAccessToken,
  idToken: initialIdToken,
  refreshToken: initialRefreshToken,
  challengeData: undefined,
  forgotUserEmail: undefined
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setTokens: (state, action: PayloadAction<SetTokensProps>) => {
      const newState = {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        idToken: action.payload.idToken
      };

      return newState;
    },
    setChallengeData: (state, action: PayloadAction<SetChallengeParametersProps>) => {
      const newState = {
        ...state,
        challengeData: action.payload.challengeData
      };

      return newState;
    },
    setForgotEmail: (state, action: PayloadAction<string>) => {
      const newState = {
        ...state,
        forgotUserEmail: action.payload
      };

      return newState;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setTokens, setChallengeData, setForgotEmail } = loginSlice.actions;

export default loginSlice.reducer;
