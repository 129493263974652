import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { AppState } from '../../../infra/store';

export interface IProtectedRoutes {
  Component: any;
}

const ProtectedRoutes = ({ Component }: IProtectedRoutes) => {
  const accessToken = useSelector((state: AppState) => state.loginReducer.accessToken);
  const isLoggedIn: boolean = !!accessToken;
  const { pathname = '' } = useLocation();

  const StorePathAndRedirect = () => {
    localStorage.setItem('redirect-path', pathname);
    return <Navigate to="/login" />;
  };

  return isLoggedIn ? <Component /> : <StorePathAndRedirect />;
};

export default ProtectedRoutes;
