import './styles/noticeStyles.css';

import { Alert, AlertTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { Notice } from '../../models/notice';
import { formatDate } from '../../utils/dates';
import { NoticesService } from './services/noticeService';

const NoticesAlert = () => {
  const [notices, setNotices] = useState<Notice[]>([]);

  const service = new NoticesService();

  useEffect(() => {
    if (notices.length > 0) return;

    service.getActiveNotices().then((res: Notice[] | Error) => {
      if (res instanceof Error) return;

      setNotices(res);
    });
  }, []);

  return (
    <div id="notice-container" style={{ margin: '0.5rem', boxSizing: 'border-box' }}>
      {notices.map((notice, index) => (
        <Alert severity={notice.severity} key={index} sx={{ width: '100%' }}>
          <AlertTitle>{notice.title}</AlertTitle>
          {notice.message} <br />
          <strong>{formatDate(notice.endDate, 'Este mensaje expirará el DD de _month_ del YYYY')}</strong>
        </Alert>
      ))}
    </div>
  );
};

export default NoticesAlert;
