/**
 * This palette was created following WCAG 2.1
 * Please note that this follows WCAG 2.1 Standards. Don't change this unless it follows WCAG 2.1 Standards.
 * This mode is meant for accesibility. So everyone can use this system.
 */
export function assignHighContrastVariables() {
  document.documentElement.style.setProperty('--bg-color', '#1c2524');
  document.documentElement.style.setProperty('--sub-bg-color', '#000000');
  document.documentElement.style.setProperty('--alert-bg', '#B60000');
  document.documentElement.style.setProperty('--alter-grad-bg-color', '#B60000');
  document.documentElement.style.setProperty('--alert-bg-green', '#EDF7ED');
  document.documentElement.style.setProperty('--alert-bg-yellow', '#FFF4E5');
  document.documentElement.style.setProperty('--alert-bg-red', '#FBECED');
  document.documentElement.style.setProperty('--alter-bg-color', '#383838');
  document.documentElement.style.setProperty('--text-color', 'white');
  document.documentElement.style.setProperty('--low-text-color', '#F2F2F2');
  document.documentElement.style.setProperty('--main-color', 'rgb(255, 55, 0)');
  document.documentElement.style.setProperty('--logo-color', 'rgb(255, 255, 255)');
  document.documentElement.style.setProperty('--login-bg', '#1d1d1d');
  document.documentElement.style.setProperty('--alt-color', '#696a6d');
  document.documentElement.style.setProperty('--rs-steps-state-process', 'rgb(255, 55, 0)');
  document.documentElement.style.setProperty('--rs-steps-state-finish', 'rgb(255, 55, 0)');
  document.documentElement.style.setProperty(
    '--icon-invert',
    'invert(100%) sepia(0%) saturate(2%) hue-rotate(4deg) brightness(111%) contrast(101%) !important'
  );
  document.documentElement.style.setProperty(
    '--icon-invert-active',
    'invert(100%) sepia(0%) saturate(2%) hue-rotate(4deg) brightness(111%) contrast(101%) !important'
  );
  document.documentElement.style.setProperty('--main-color-hover', 'rgb(211, 46, 0)');
  document.documentElement.style.setProperty('--navbar-width', '100px');
}
