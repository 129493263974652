import { UserAPI } from '../../../infra/api/user-api';
import { environment } from '../../../infra/environment/environment';

export class UserService {
  private api: UserAPI;

  constructor() {
    this.api = new UserAPI(environment);
  }

  getUsers = () => {
    return this.api.getAll();
  };
}
