import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HomeIcon from '@mui/icons-material/Home';
import React from 'react';

import PersonSVG from '../../assets/admin_personas.svg';
import UsuariosSVG from '../../assets/admin_users.svg';
import AlertsSVG from '../../assets/data-maintenance-flatline.svg';
import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';
import NavigationMenu, { INavigationMenuItem } from '../../common/components/navigationMenu';

const menuItems: INavigationMenuItem[] = [
  {
    name: 'Usuarios',
    to: '/administrador/usuarios',
    types: ['ADMIN'],
    svg: <img src={UsuariosSVG} alt="Usuarios" width={250} />
  },
  {
    name: 'Colaboradores',
    to: '/administrador/trabajadores',
    types: ['ADMIN'],
    svg: <img src={PersonSVG} alt="Colaboradores" width={250} />
  },
  {
    name: 'Avisos',
    to: '/administrador/avisos',
    types: ['ADMIN'],
    svg: <img src={AlertsSVG} alt="Mostrar Aviso" width={250} />
  }
];
const AdminMenu = () => {
  const breadcrumbItems: IBreadcrumbItem[] = [
    {
      Icon: <HomeIcon color="secondary" />,
      text: 'Home',
      path: '/'
    },
    {
      Icon: <AdminPanelSettingsIcon color="primary" />,
      text: 'Administrador',
      path: '/administrador'
    }
  ];

  return (
    <div className="grid w-full h-full px-2">
      <Breadcrumb items={breadcrumbItems} title="Menú de Administrador" />
      <NavigationMenu items={menuItems} />
    </div>
  );
};

export default AdminMenu;
