export function mapper(amount: number, higher: number, mid: number): 'error' | 'warning' | 'success' {
  if (amount > higher) return 'error';
  if (amount > mid) return 'warning';
  return 'success';
}

export function useSOSSeverity(amount: number): 'error' | 'warning' | 'success' {
  return mapper(amount, 1, 0);
}
export function useAnormalHeartRateSeverity(amount: number): 'error' | 'warning' | 'success' {
  return mapper(amount, 20, 10);
}

export function useManDownRateSeverity(amount: number): 'error' | 'warning' | 'success' {
  return mapper(amount, 1, 0);
}

export function useInsufficientPermissionsRateSeverity(amount: number): 'error' | 'warning' | 'success' {
  return mapper(amount, 10, 5);
}
