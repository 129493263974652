import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { AppBar, Toolbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Websocket from '../../../common/components/websocket';
import { AppState } from '../../../infra/store';

export interface IMobileHeader {
  onMenuClick: () => void;
  onNotificationsClick: () => void;
}

const MobileHeader = ({ onMenuClick, onNotificationsClick }: IMobileHeader) => {
  const navigate = useNavigate();
  const logo = useSelector((state: AppState) => state.themeReducer.logo);

  return (
    <AppBar elevation={0} color="default" style={{ maxHeight: 64, minHeight: 64, background: 'var(--bg-color)' }}>
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={onMenuClick}>
          <MenuIcon />
        </IconButton>
        <Websocket />
        <img
          height={30}
          src={logo}
          alt="Tecnologico Costa Rica"
          onClick={() => navigate('/')}
          style={{ flexGrow: 1 }}
        />
        <IconButton edge="end" color="primary" aria-label="menu" onClick={onNotificationsClick}>
          <NotificationsIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default MobileHeader;
